import { PasswordMapped } from '@interfaces/employer.interface';
import { Mockup } from '@interfaces/mockup.interface';

export const PASSWORD_AND_CERTIFICATES_RESPONSE: Mockup = {
    success: [{
        response: {
            data: {
                titulo_preguntas: 'Preguntas frecuentes de tu clave y certificados',
                descripcion_preguntas: 'Revisa las preguntas frecuentes y aclara tus dudas sobre la clave y certificados de AFP PlanVital',
                preguntas: [
                    {
                        faq_id: {
                            pregunta: '¿Qué requisitos necesito para obtener mi clave de empleador?',
                            respuesta: '<p>Debes ser empleador o representante legal de la empresa. Estos son los documentos requeridos:</p>\n<p>Si lo haces en el sitio web:</p>\n<ul>\n<li>Rut empresa (documento emitido por SII)</li>\n<li>C&eacute;dula de Identidad vigente del representante legal de la empresa</li>\n</ul>\n<p>Si lo haces en sucursales:</p>\n<ul>\n<li>Rut empresa (documento emitido por el SII)</li>\n<li>Copia de c&eacute;dula de identidad vigente del representante legal de la empresa. Si quien realiza el tr&aacute;mite no es el representante legal de la empresa debe presentar una carta que contenga los datos de la empresa con: nombre, RUT, direcci&oacute;n y tel&eacute;fono, firmada por el empleador</li>\n</ul>'
                        }
                    },
                    {
                        faq_id: {
                            pregunta: '¿Cómo puedo validar un certificado?',
                            respuesta: '<p>A trav&eacute;s de nuestra secci&oacute;n de <a title=\"Validaci&oacute;n de Certificados Online\" href=\"afiliado/asesoria/certificados/validar\" target=\"_blank\" rel=\"noopener\">Validaci&oacute;n Online de Certificados</a> puedes verificar la validez de los Certificados. Para ello, se debe ingresar el RUT del Afiliado o Empleador y el folio del certificado.</p>'
                        }
                    }
                ]
            }
        }
    }],
    failures: []
};

export const PASSWORD_AND_CERTIFICATES_MOCK: PasswordMapped = {
    banner: {
        id: 1,
        image: {
          alt: 'Cómo Solicitar Clave AFP',
          url: 'assets/images/01.banner-claves.webp',
        },
        image_mobile: {
          alt: 'Cómo Solicitar Clave AFP',
          url: 'assets/images/01.banner-claves.webp',
        },
        firstLine: `Como obtener tu clave de AFP`,
      },
    
    faqSection: {
        title: 'Preguntas frecuentes de tu clave y certificados',
        subTitle: 'Revisa las preguntas frecuentes y aclara tus dudas sobre la clave y certificados de AFP PlanVital',
        faq: [
            {
                title: '¿Qué requisitos necesito para obtener mi clave de empleador?',
                description: '<p>Debes ser empleador o representante legal de la empresa. Estos son los documentos requeridos:</p>\n<p>Si lo haces en el sitio web:</p>\n<ul>\n<li>Rut empresa (documento emitido por SII)</li>\n<li>C&eacute;dula de Identidad vigente del representante legal de la empresa</li>\n</ul>\n<p>Si lo haces en sucursales:</p>\n<ul>\n<li>Rut empresa (documento emitido por el SII)</li>\n<li>Copia de c&eacute;dula de identidad vigente del representante legal de la empresa. Si quien realiza el tr&aacute;mite no es el representante legal de la empresa debe presentar una carta que contenga los datos de la empresa con: nombre, RUT, direcci&oacute;n y tel&eacute;fono, firmada por el empleador</li>\n</ul>'
            },
            {
                title: '¿Cómo puedo validar un certificado?',
                description: '<p>A trav&eacute;s de nuestra secci&oacute;n de <a title=\"Validaci&oacute;n de Certificados Online\" href=\"afiliado/asesoria/certificados/validar\" target=\"_blank\" rel=\"noopener\">Validaci&oacute;n Online de Certificados</a> puedes verificar la validez de los Certificados. Para ello, se debe ingresar el RUT del Afiliado o Empleador y el folio del certificado.</p>'
            }
        ]

    }
    
};
