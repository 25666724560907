import { Mockup } from '@interfaces/mockup.interface';


export const GET_COMPLIANTS_CHANNEL_CONTENT_MOCKUP: Mockup = {
    success: [{
        response: {
            data: {
                titulo: 'Canal de Denuncias',
                preguntas: [
                    {
                        preguntas_canal_denuncias_id: {
                            pregunta: '¿Qué información debo incluir en la denuncia?',
                            respuesta: '<p>Usted deber&aacute; incluir toda la informaci&oacute;n que sea necesaria para llevar a cabo una investigaci&oacute;n sobre el caso planteado (fechas, nombres de personas o entidades involucradas, como usted tom&oacute; conocimiento de la falta o incumplimiento, etc.).</p>'
                        }
                    },
                    {
                        preguntas_canal_denuncias_id: {
                            pregunta: '¿Qué tipo de situaciones debería denunciar?',
                            respuesta: '<p>El sistema de denuncias de AFP Planvital S.A. est&aacute; dise&ntilde;ado para que trabajadores y proveedores (terceras partes en general) puedan denunciar de buena fe cualquier pr&aacute;ctica o conducta considerada como inapropiada o en infracci&oacute;n a la legislaci&oacute;n vigente, el C&oacute;digo de Conducta u otras pol&iacute;ticas internas relacionadas con asuntos: financieros; de auditoria; contabilidad; y en general actos de corrupci&oacute;n que pudiesen afectar a la compa&ntilde;&iacute;a. Solicitamos efectuar las denuncias con la mayor cantidad de antecedentes posibles, indicando fechas, nombres, situaciones y cualquier hecho que resulten relevante y/o clave para entender los hechos denunciados.</p>'
                        }
                    },
                    {
                        preguntas_canal_denuncias_id: {
                            pregunta: '¿Por qué debería efectuar la denuncia a la compañía?',
                            respuesta: '<p>Todos tenemos el derecho a trabajar en un entorno positivo y ello implica la responsabilidad de actuar de una forma correcta en el lugar de trabajo y en las relaciones con nuestros proveedores. Por lo tanto, todos y cada uno de nosotros tiene el deber de informar a la compa&ntilde;&iacute;a si una determinada persona o grupo de personas no est&aacute; actuando correctamente. La falta de &eacute;tica laboral puede amenazar la subsistencia de la compa&ntilde;&iacute;a, poniendo en peligro el negocio, nuestra reputaci&oacute;n y el patrimonio de nuestros afiliados. Hacer lo correcto es responsabilidad de todos.</p>'
                        }
                    },
                    {
                        preguntas_canal_denuncias_id: {
                            pregunta: '¿Que no debería ser denunciado a través de este canal?',
                            respuesta: '<p>Este canal de denuncias no debe ser utilizado para denunciar situaciones que presenten una amenaza inmediata para la vida o la propiedad de las personas. Si necesita de forma urgente ayuda para resolver situaciones de emergencia como las antes se&ntilde;aladas, por favor contacte a las autoridades locales. Si usted es un cliente/afiliado de AFP Planvital S.A., y sus consultas o reclamos est&aacute;n relacionadas con los productos o servicios que se les presta la organizaci&oacute;n, por favor utilice los canales y/o tel&eacute;fonos de servicio al cliente para canalizar sus consultas.</p>'
                        }
                    },
                    {
                        preguntas_canal_denuncias_id: {
                            pregunta: 'Como trabajador, ¿Debo preocuparme por las consecuencias de presentar una denuncia?',
                            respuesta: '<p>En PlanVital, nos preocupamos y ocupamos de que nuestro ambiente laboral sea de respeto y libre de malas conductas, por tanto, no toleramos ni toleraremos la toma de represalias contra los colaboradores que realicen una denuncia.</p>'
                        }
                    },
                    {
                        preguntas_canal_denuncias_id: {
                            pregunta: '¿Qué sucede si, en el seguimiento de la investigación, el motivo de la denuncia resulta ser cierto?',
                            respuesta: '<p>Si tu denuncia resulta ser cierta, el Oficial de Cumplimiento, identificar&aacute; si hay que implementar alguna medida correctiva o de mitigaci&oacute;n necesaria y evaluar&aacute; si resulta necesario recomendar alguna medida disciplinaria en contra de &eacute;l o los responsables (si fuese trabajador). Ser&aacute; el Comit&eacute; de Directores (&ldquo;Sostenibilidad y Riesgo&rdquo;) los responsables de tomar la decisi&oacute;n final sobre la base de los antecedentes investigados.</p>'
                        }
                    },
                    {
                        preguntas_canal_denuncias_id: {
                            pregunta: '¿Qué sucede si, en el seguimiento de la investigación, el motivo de la denuncia pasa a carecer de fundamento?',
                            respuesta: '<p>Si tu denuncia es injustificada o sin fundamento, el Oficial de Cumplimiento podr&aacute; desestimar seguir adelante con la investigaci&oacute;n de los hechos.</p>'
                        }
                    },
                    {
                        preguntas_canal_denuncias_id: {
                            pregunta: 'Si como trabajador me doy cuenta que existe un incumplimiento, ¿No debería solo denunciarlo a mi superior y dejarle a él o ella la obligación de gestionarlo?',
                            respuesta: '<p>Usted como trabajador puede denunciar cualquier asunto que le merezca duda o reparos en forma directa a su jefe o superior jer&aacute;rquico. Sin embargo, sabemos pueden existir situaciones en las que usted no se sienta c&oacute;modo denunciando el asunto ante tu superior o considere que es apropiado utilizar otro medio o canal para denunciarlo. Si usted considera apropiado utilizar el canal de denuncias de la compa&ntilde;&iacute;a, por favor h&aacute;galo siguiendo los lineamientos se&ntilde;alados en esta p&aacute;gina web.</p>'
                        }
                    }
                ]
            }
        }
    }],
    failures: []
};
