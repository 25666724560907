import { Environment } from '@interfaces/environment.interface';

const baseProjectUrl = 'https://apiweb-qa.planvital.cl'; // 'http://34.120.156.248';
const affiliateAmberUrl = `${baseProjectUrl}/amber`;

export const environment: Environment = {
  name: 'qa',
  production: false,
  baseUrl: baseProjectUrl,
  clientsUrl: `${baseProjectUrl}/clients/`,
  proxyApiUrl: `${baseProjectUrl}/public/`,
  affiliateApplicationsUrl: `${affiliateAmberUrl}/applications/`,
  certificatesUrl: 'http://34.120.156.248/certificates/',
  // cmsApiUrl: 'http://35.186.243.77/v2/cms/',
  cmsApiUrl: 'https://apiweb-qa.planvital.cl/v2/cms/',
  withdrawalsUrl: `${baseProjectUrl}/withdrawals/`,
  recaptchaSiteKey: '6LdMsLcZAAAAAILUOD0ObyYhJHeWDjUeMSaPWFbC',
  googleMapsKey: 'AIzaSyDK_MHYxjJmdNh3TV5o3hN-K-viZxZzOmM',
  googleAnalyticsId: 'UA-171402309-2',
  firebaseConfig: {
    apiKey: 'AIzaSyAGbRArJYj704Prbq8iEtcAx88qBQFH_wk',
    authDomain: 'plan-vital-dev-qa.firebaseapp.com',
    databaseURL: 'https://plan-vital-dev-qa.firebaseio.com',
    projectId: 'plan-vital-dev-qa',
    storageBucket: 'plan-vital-dev-qa.appspot.com',
    messagingSenderId: '381542100011',
    appId: '1:381542100011:web:fce3ae69769217818cce6e',
    measurementId: 'G-2TW99JTRCX'
  },
  mockHttpCalls: false,
  mockedResponseDelay: 0,
  mockedResponseSuccessRate: 1,
  loginUrl: 'https://shckltn2.planvital.cl:444/NewLogin',
  privateSiteUrl: 'http://34.98.90.59/',
  fundsWithdrawalUrl: 'http://34.120.221.155/',
  investorProfileUrl: `${baseProjectUrl}/clients/`,
  publicFilesUrl: 'https://shckltn.planvital.cl/archivos/',
  paetUrl: 'http://34.117.138.194',
  transferUrl: 'http://34.95.81.104',
  botmakerChatbotVideoCall: 'https://go.botmaker.com/rest/webchat/p/F6ONOJNNN2/init.js',
  botmakerChatbotInternationalCall: 'https://go.botmaker.com/rest/webchat/p/GAZ3AQU2EY/init.js',
  urlAlimony: 'https://leypensiondealimentos-qa.planvital.cl/',
  urlPageInMaintenance: 'https://maintenance-qa.planvital.cl/home',
  employersUrl: `${baseProjectUrl}/employers/`,
  urlLocalizatorBranchStores: 'https://staging-storeloc-planvital.partoo-store-locator.co/'
};
