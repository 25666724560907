import { HOME, INVESTORS } from '@constants/titles.constant';
import { ShareholdersMeetingContent } from '@interfaces/shareholders-meeting.interface';

export const SHAREHOLDERS_MEETING_BREADCUMB = [HOME, INVESTORS, 'Junta de Accionistas'];

export const SHAREHOLDERS_MEETING_CONTENT: ShareholdersMeetingContent = {
  header: {
    firstLine: `Junta de Accionistas`,
    icon: 'analysis',
    image: {
      alt: 'Junta de Accionistas',
      url: 'assets/images/banner-general1.5x.webp',
    },
    image_mobile: {
      alt: 'Junta de Accionistas',
      url: 'assets/images/banner-general1.5x.webp',
    },
  },
  content: {
    title: '',
    ordinary_title: '',
    extraordinary_title: '',
    ordinary_section: [],
    extraordinary_section: []
  }
};
