import { Component, OnInit } from '@angular/core';
import { CommissionsDisclaimerSection } from '@interfaces/commissions-disclaimer.interface';
import { FuturePensioner } from '@interfaces/future-pensioner.interface';
import { FontService } from '@providers/font/font.service';
import { LanguageProvider } from '@providers/language/language.service';
import { CmsService } from '@services/cms/cms.service';

@Component({
  selector: 'app-disclaimer-commision',
  templateUrl: './disclaimer-commision.component.html',
  styleUrls: ['./disclaimer-commision.component.scss'],
})
export class DisclaimerCommisionComponent implements OnInit {
  public commissionsDisclaimer: CommissionsDisclaimerSection;
  public loading = false;

  constructor(
    protected cmsService: CmsService,
    public font: FontService,
    public languageProvider: LanguageProvider,
  ) { }

  public ngOnInit(): void {
    this.getCommissionsDisclaimer();
    this.languageSubscription();
  }

  private getCommissionsDisclaimer(): void {
    this.loading = true;
    this.cmsService.loadCommissionsDisclaimer().toPromise()
      .then((response) => this.commissionsDisclaimer = response)
      .finally(() => this.loading = false);
  }

  private languageSubscription(): void {
    this.languageProvider.language.subscribe(() =>  this.getCommissionsDisclaimer());
  }
}
