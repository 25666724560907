import { environment } from '@environment';
import { Mockup } from '@interfaces/mockup.interface';
import { Calendar } from '@interfaces/pension-payment.interface';


export const GET_CALENDAR_CONTENT_MOCK: Mockup = {
    success: [{
        response: {
            data: {
                titulo: 'Fechas de Pagos de Pensionados',
                subtitulo: 'Revisa el calendario con las fechas de los próximos pagos de pensión durante este año',
                titulo_liquidacion_pago: 'Liquidación de pago',
                titulo_cuadro: 'Recibe tu pensión sin salir de casa',
                descripcion_cuadro: '<p>&iexcl;Atenci&oacute;n! Recuerda que&nbsp;<strong>puedes inscribir tu CuentaRUT</strong> para recibir tu pago de pensi&oacute;n por transferencia electr&oacute;nica y no tengas que salir de casa.</p>',
                titulo_calendarios: 'Calendario de Pensiones en BancoEstado y depósitos bancarios',
                titulo_centros_pago: 'Centros de pago',
                texto_boton_liquidacion_pago: 'Obtener liquidación de pensión',
                texto_boton_inscripcion: 'Inscríbete aquí',
            }
        }
    }],
    failures: []
};

export const CALENDAR_CONTENT: Calendar = {
    pensionSettlement: {
        buttonText: 'Obtener liquidación de pensión',
        buttonUrl: 'pensionSettlement',
        title: 'Liquidación de pago',
        description: '<p>Obt&eacute;n y revisa tu liquidaci&oacute;n de pago ac&aacute;:</p>'
    },
    registerAccountBox: {
        buttonText: 'Inscríbete aquí',
        buttonUrl: 'pago-pension/inscribir-cuenta',
        title: 'Recibe tu pensión sin salir de casa',
        description: '<p>&iexcl;Atenci&oacute;n! Recuerda que&nbsp;<strong>puedes inscribir tu CuentaRUT</strong> para recibir tu pago de pensi&oacute;n por transferencia electr&oacute;nica y no tengas que salir de casa.</p>'
    },
    servipagCalendar: undefined,
    paymentCenter: {
        cards: [
            {
                image: {
                    url: environment.cmsApiUrl + 'assets/8607f40c-5c87-4748-81df-d2d9d7673933.webp',
                    alt: 'Mujer pensionada usando un cajero automático'
                },
                title: 'Centros de Pago de Pensiones BancoEstado y depósitos bancarios',
                requestText: 'Ir a Banco Estado',
                requestUrl: 'https://www.bancoestado.cl/'
            }
        ],
        title: 'Centros de pago',
        description: ''
    },
    bankCalendar: {
        title: 'Calendario de Pensiones en BancoEstado y depósitos bancarios'
    },
    subTitle: 'Fechas de Pagos de Pensionados',
    title: '',
    description: 'Revisa el calendario con las fechas de los próximos pagos de pensión durante este año'
};
