import { Component } from '@angular/core';
import { PENSION_OFFERS_CONTENT } from '@constants/pages-content/pension-offers.constant';
import { PensionOffers } from '@interfaces/future-pensioner.interface';
import { FontService } from '@providers/font/font.service';
import { LanguageProvider } from '@providers/language/language.service';
import { CmsService } from '@services/cms/cms.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-pension-offers-component',
  templateUrl: './pension-offers.component.html',
})
export class PensionOffersComponent {
  public pensionOffers: PensionOffers = PENSION_OFFERS_CONTENT;
  public loading = false;

  constructor(
    protected cmsService: CmsService,
    public font: FontService,
    public languageProvider: LanguageProvider,
  ) { 
    this.loadScompContent();
  }

  private loadScompContent(): void {
    this.loading = true;
    firstValueFrom(this.cmsService.loadScompContent()).then(response => {
      this.pensionOffers = response;
    }).finally(() => this.loading = false);
  }
}
