import { HOME, INVESTORS, RELEVANT_INFO_TITLES } from '@constants/titles.constant';
import { FinancialStatementContent } from '@interfaces/corporation.interface';

const pageKey = 'CORPORATION.RELEVANT_INFO.FINANCIAL_STATEMENTS';

export const FINANCIAL_STATEMENTS_CONTENT: FinancialStatementContent = {
  header: {
    firstLine: `${pageKey}.HEADER_FIRST_LINE`,
    icon: 'analysis',
    image: {
      alt: 'Estados financieros',
      url: 'assets/images/banner-general1.5x.webp',
    },
    image_mobile: {
      alt: 'Estados financieros',
      url: 'assets/images/banner-general1.5x.webp',
    },
  },
  title: RELEVANT_INFO_TITLES.content.financialStatements,
  downloadContent: []
};

export const FINANCIAL_STATEMENTS_PATHS = {
  folder: 'Estados-Financieros/',
  administration: 'Administradora/',
  funds: 'Fondos/'
};

export const FINANCIAL_STATEMENTS_BREADCRUMBS = [ HOME, INVESTORS, RELEVANT_INFO_TITLES.content.financialStatements ];
