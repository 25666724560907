import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { ModalComponent } from '@components/modal/modal.component';
import {
  MODAL_GET_CERTIFICATE_TEXT_CONTENT, PERIOD_CODE_OPTIONS, PRODUCT_TYPE_OPTIONS
} from '@constants/pages-content/modal-get-certificate.constants';
import { FontService } from '@providers/font/font.service';
import { ValidateRut } from '@validators/rut.validator';

interface CertificateForm {
  rut: FormControl<string>;
  periodCode?: FormControl<string>;
  productType?: FormControl<string>;
  accountType?: FormControl<string>;
  startDate?: FormControl<Date>;
  endDate?: FormControl<Date>;
}

@Component({
  selector: 'app-modal-get-certificate',
  templateUrl: './modal-get-certificate.component.html',
  styleUrls: ['./modal-get-certificate.component.scss'],
})
export class ModalGetCertificateComponent extends ModalComponent implements OnInit {
  public certificateForm!: FormGroup;
  public fields: string[];
  public periodCodeOptions = PERIOD_CODE_OPTIONS;
  public productTypeOptions = PRODUCT_TYPE_OPTIONS;
  public textContent = MODAL_GET_CERTIFICATE_TEXT_CONTENT;
  public title: string;
  public todayCalendar = new Date(Date.now());
  public type: string;

  constructor(
    protected params: NavParams,
    protected modalCtrl: ModalController,
    public font: FontService,
    public translateService: TranslateService,
  ) {
    super(params, modalCtrl);
    this.title = this.params.get('title');
    this.type = this.params.get('type');
    this.fields = this.params.get('fields');
  }

  get customPeriodSelected(): boolean {
    return this.periodCodeControl?.value === 'H' && (this.type === 'quotations' || this.type === 'remunerations');
  }
  get minStartDate(): Date { return new Date(1981, 0, 1); }
  get maxStartDate(): Date { return this.endDateControl.value || new Date(); }
  get minEndDate(): Date { return new Date(this.startDateControl.value); }
  get periodCodeControl(): AbstractControl { return this.certificateForm.get('periodCode'); }
  get startDateControl(): AbstractControl { return this.certificateForm.get('startDate'); }
  get endDateControl(): AbstractControl { return this.certificateForm.get('endDate'); }
  get rutControl(): AbstractControl { return this.certificateForm.get('rut'); }

  public ngOnInit(): void {
    this.createForm();
    this.setTypeSpecificValidation(this.type);
  }

  public getCertificate(): void {
    if (this.certificateForm.invalid) { return; }
    this.closeModal(this.certificateForm.value);
  }

  public handleKeyDownForCertificate(event: KeyboardEvent): void {
    if ((event.key === 'Enter' || event.key === ' ') && this.certificateForm.valid) {
      event.preventDefault();
      this.getCertificate();
    }
  }

  public handleKeyDownForClose(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Enter') {
      event.preventDefault();
      this.closeModal();
    }
  }

  public includeControl(controlName: string): boolean {
    return this.fields.includes(controlName);
  }

  public selectedPeriod(value): void {
    this.periodCodeControl.setValue(value);
  }

  private createForm(): void {
    this.certificateForm = new FormGroup<CertificateForm>({
      rut: new FormControl('', { validators: [Validators.required, ValidateRut] }),
      periodCode: new FormControl(''),
      productType: new FormControl(''),
      startDate: new FormControl(null),
      endDate: new FormControl(null),
    });
  }

  private setTypeSpecificValidation(type: string): void {
    switch (type) {
      case 'quotations':
        this.makeControlRequired('periodCode');
        this.makeControlRequired('productType');
        this.setPeriodCodeWatcher();
        break;
      case 'remunerations':
        this.makeControlRequired('periodCode');
        this.certificateForm.removeControl('productType');
        this.setPeriodCodeWatcher();
        break;
      default:
        this.certificateForm.removeControl('periodCode');
        this.certificateForm.removeControl('productType');
        this.certificateForm.removeControl('startDate');
        this.certificateForm.removeControl('endDate');
        break;
    }
  }

  private setPeriodCodeWatcher(): void {
    const periodCodeControl = this.certificateForm.get('periodCode');
    periodCodeControl.valueChanges.subscribe((value: string) => {
      if (value === 'H') {
        this.makeControlRequired('startDate');
        this.makeControlRequired('endDate');
      } else {
        this.makeControlOptional('startDate');
        this.makeControlOptional('endDate');
      }
    });
  }

  private makeControlRequired(controlName: string): void {
    const control = this.certificateForm.get(controlName);
    if (control) {
      control.setValidators(Validators.required);
      control.updateValueAndValidity();
      control.enable();
    }
  }

  private makeControlOptional(controlName: string): void {
    const control = this.certificateForm.get(controlName);
    if (control) {
      control.clearValidators();
      control.updateValueAndValidity();
      control.disable();
    }
  }
}
