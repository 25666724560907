import { CommissionsDisclaimerSection } from '@interfaces/commissions-disclaimer.interface';
import { Mockup } from '@interfaces/mockup.interface';


export const GET_COMMISSIONS_DISCLAIMER_RESPONSE_MOCK: Mockup = {
    success: [{
        response: {
            data: {
                titulo: 'Comisiones de asesores previsionales',
                descripcion: '<p><strong>Es nuestra obligaci&oacute;n entregarte toda la informaci&oacute;n que necesites al momento de iniciar tu tr&aacute;mite de pensi&oacute;n, sin costos asociados.</strong></p>\n<p>No obstante, tu puedes obtener una asesor&iacute;a previsional externa con el fin de informar y orientar al afiliado o sus beneficiarios, respecto a su situaci&oacute;n particular, para que tomes decisiones informadas al momento de pensionarte.</p>\n<p>Esta asesor&iacute;a, al no ser parte de la AFP tiene una comisi&oacute;n asociada a la modalidad de pensi&oacute;n seleccionada:</p>\n<ul>\n<li>Renta vitalicia, tiene una comisi&oacute;n m&aacute;xima de 2% del saldo con un tope de 60 UF. Para Retiros Programados, la comisi&oacute;n es de 1,2%, con un tope de 36 UF.</li>\n<li>Cambio de modalidad de pensi&oacute;n (de Retiros Programados a Renta Vitalicia), la comisi&oacute;n es de 2%, con tope de 60 UF.</li>\n</ul>'
            }
        }
    }],
    failures: []
};

export const COMMISSIONS_DISCLAIMER: CommissionsDisclaimerSection = {
    title: 'Comisiones de asesores previsionales',
    description: '<p><strong>Es nuestra obligaci&oacute;n entregarte toda la informaci&oacute;n que necesites al momento de iniciar tu tr&aacute;mite de pensi&oacute;n, sin costos asociados.</strong></p>\n<p>No obstante, tu puedes obtener una asesor&iacute;a previsional externa con el fin de informar y orientar al afiliado o sus beneficiarios, respecto a su situaci&oacute;n particular, para que tomes decisiones informadas al momento de pensionarte.</p>\n<p>Esta asesor&iacute;a, al no ser parte de la AFP tiene una comisi&oacute;n asociada a la modalidad de pensi&oacute;n seleccionada:</p>\n<ul>\n<li>Renta vitalicia, tiene una comisi&oacute;n m&aacute;xima de 2% del saldo con un tope de 60 UF. Para Retiros Programados, la comisi&oacute;n es de 1,2%, con un tope de 36 UF.</li>\n<li>Cambio de modalidad de pensi&oacute;n (de Retiros Programados a Renta Vitalicia), la comisi&oacute;n es de 2%, con tope de 60 UF.</li>\n</ul>'
};
