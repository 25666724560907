import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FinancialStatementsPage } from './financial-statements/financial-statements.page';
import { AnnualMemoryPage } from './annual-memory/annual-memory.page';
import { EssentialFactsPage } from './essential-facts/essential-facts.page';
import { InformationOfInterestPage } from './information-of-interest/information-of-interest.page';
import { ShareholdersMeetingPage } from './shareholders-meeting/shareholders-meeting.page';
import { CorporateGovernancePage } from './corporate-governance/corporate-governance.page';

const routes: Routes = [
  { path: '', redirectTo: '/inicio', pathMatch: 'full' },
  { path: 'estados_financieros', component: FinancialStatementsPage },
  { path: 'gobierno_corporativo', component: CorporateGovernancePage },
  { path: 'memorial_anual', component: AnnualMemoryPage },
  { path: 'hechos_esenciales', component: EssentialFactsPage },
  { path: 'informacion_interes', component: InformationOfInterestPage },
  { path: 'junta_accionistas', component: ShareholdersMeetingPage }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class InvestorsRoutingModule { }
