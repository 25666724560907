import { RELEVANT_INFO_TITLES } from '@constants/titles.constant';
import { EssentialFactsContent } from '@interfaces/corporation.interface';

const pageKey = 'CORPORATION.RELEVANT_INFO.INFORMATION_OF_INTEREST';

export const INFORMATION_OF_INTEREST_CONTENT: EssentialFactsContent = {
  header: {
    firstLine: `${pageKey}.HEADER_TITLE`,
    icon: 'analysis',
    image: {
      alt: 'Información de interés',
      url: 'assets/images/banner-general1.5x.webp',
    },
    image_mobile: {
      alt: 'Información de interés',
      url: 'assets/images/banner-general1.5x.webp',
    },
  },
  title: RELEVANT_INFO_TITLES.content.informationOfInterest,
  content: []
};
