import { SecondaryAccount } from '@interfaces/savings.interface';

import { MORE_INFO } from './general.constant';

const pageKey = 'AFFILIATE.SAVE_WITH_US.SECONDARY_ACCOUNT';

export const SECONDARY_ACCOUNT_CONTENT: SecondaryAccount = {
  header: {
    image: null,
    icon: 'lock',
    firstLine: `${pageKey}.HEADER.TITLE`,
    secondLine: `${pageKey}.HEADER.SUBTITLE`,
  },
  button: `${pageKey}.BUTTON`,
  carousel: {
    title: `${pageKey}.CAROUSEL.TITLE`,
    subtitle: `${pageKey}.CAROUSEL.SUBTITLE`,
    benefits: []
  },
  showCardImage: true,
  savingCardsSection: {
    title: `${pageKey}.CARDS.TITLE`,
    subtitle: `${pageKey}.CARDS.SUBTITLE`,
    cards: [
      {
        image: {
          url: 'assets/images/AFP_Planvital_cuenta_2_cuenta_de_ahorro_producto.jpg',
          alt: 'Cuenta de ahorro, producto',
          title: 'Cuenta de ahorro, producto'
        },
        title: `${pageKey}.CARDS.CHARACTERISTICS.UNIVERSAL.TITLE`,
        description: `${pageKey}.CARDS.CHARACTERISTICS.UNIVERSAL.DESCRIPTION`,
        showMoreText: MORE_INFO,
        modal: null,
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: 'assets/images/AFP_Planvital_cuenta_2_cuenta_de_ahorro_proque_ahorrar.jpg',
          alt: 'Cuenta de ahorro, ahorrar',
          title: 'Cuenta de ahorro, ahorrar'
        },
        title: `${pageKey}.CARDS.CHARACTERISTICS.MINIMUM_AGE.TITLE`,
        description: `${pageKey}.CARDS.CHARACTERISTICS.MINIMUM_AGE.DESCRIPTION`,
        showMoreText: MORE_INFO,
        modal: null,
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: 'assets/images/AFP_Planvital_cuenta_2_cuenta_de_ahorro_tipos_depositos.jpg',
          alt: 'Cuenta de ahorro, tipos de depósitos',
          title: 'Cuenta de ahorro, tipos de depósitos'
        },
        title: `${pageKey}.CARDS.CHARACTERISTICS.DEPOSITS.TITLE`,
        description: `${pageKey}.CARDS.CHARACTERISTICS.DEPOSITS.DESCRIPTION`,
        showMoreText: MORE_INFO,
        modal: null,
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
    ]
  },
  depositsSection: {
    title: `${pageKey}.DEPOSITS.TITLE`,
    body: `${pageKey}.DEPOSITS.BODY`,
  },
  withdrawalsSection:  {
    title: `${pageKey}.WITHDRAWALS.TITLE`,
    subtitle: `${pageKey}.WITHDRAWALS.SUBTITLE`,
    body: null,
  },
  restrictionsSection: {
    title: `${pageKey}.RESTRICTIONS.TITLE`,
    body: null,
  },
  commissionDisclaimer: {
    title: `${pageKey}.COMMISSION_DISCLAIMER.TITLE`,
    description: `${pageKey}.COMMISSION_DISCLAIMER.DESCRIPTION`
  },
  faqSection: {
    title: 'Preguntas frecuentes de tu Cuenta 2',
    subTitle: 'Revisa las preguntas frecuentes y aclara tus dudas sobre tu Cuenta 2 de AFP PlanVital',
    faq: [
      {
        title: '¿Qué es la Cuenta 2?',
        description: '<p>La Cuenta 2 o Cuenta de Ahorro Voluntario es un complemento a tu cuenta de capitalizaci&oacute;n obligatoria en una administradora de fondos de pensiones (AFP)</p>\n<p>Fuente: <a title=\"SPensiones\" href=\"https://www.spensiones.cl\" target=\"_blank\" rel=\"noopener\">www.spensiones.cl</a></p>'
      },
      {
        title: '¿Puedo cerrar mi Cuenta 2',
        description: '<p>La Cuenta 2 no se cierra, aun cuando haya sido retirada la totalidad de los fondos. Esta cuenta se mantendr&aacute; vigente con saldo cero.</p>'
      },
      {
        title: '¿Pueden traspasarse fondos desde tu Cuenta 2 para cotizaciones o pensión?',
        description: '<p>Si eres independiente puedes facultar a tu respectiva administradora a traspasar fondos desde tu Cuenta 2 hacia su cuenta de capitalizaci&oacute;n individual, a fin de cubrir tus cotizaciones previsionales correspondientes.</p>\n<p>Adem&aacute;s, los afiliados, dependientes e independientes pueden traspasar la totalidad o parte de los fondos de esta cuenta a la cuenta de capitalizaci&oacute;n individual, con el objeto de incrementar el monto de su pensi&oacute;n o cumplir con los requisitos para pensionarse seg&uacute;n las disposiciones de la Ley. Estos traspasos no ser&aacute;n considerados giros.</p>\n<p>Fuente: <a title=\"SPensiones\" href=\"https://www.spensiones.cl\" target=\"_blank\" rel=\"noopener\">www.spensiones.cl</a></p>'
      }
    ]
  }
};
