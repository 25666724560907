import { format } from 'date-fns';

export class DateUtils {
  /**
   * Formats a given date to a specified format.
   * @param date - The date to format.
   * @param dateFormat - The format to apply to the date.
   * @returns The formatted date string.
   */
  static formatDate(date: Date, dateFormat: string): string {
    return format(date, dateFormat);
  }
}
