import { Mockup } from '@interfaces/mockup.interface';
import { OurCompanyContent } from '@interfaces/our-company.interface';


export const GET_OUR_COMPANY_MOCK: Mockup = {
    success: [{
        response: {
            data:{
               titulo:'Nuestra compañía',
               descripcion:'<h3><strong>Rese&ntilde;a Hist&oacute;rica</strong></h3>\n<p>La Administradora fue fundada en 1981, a&ntilde;o de puesta en marcha del Nuevo Sistema Previsional en Chile, por lo tanto pionera en materias de reformas de Seguridad Social. A lo largo de su historia, ha adquirido dos AFP, para posteriormente fusionarlas, conservando el nombre original: A.F.P. Invierta en el a&ntilde;o 1993. A.F.P. Concordia en el a&ntilde;o 1996.</p>\n<h3><strong>Constituci&oacute;n de la Sociedad</strong></h3>\n<p>Los Estatutos Sociales constan en escritura p&uacute;blica de fecha 17 de agosto de 1981, otorgada en la Notar&iacute;a de Santiago de don Miguel Garay Figueroa. Por su parte, la autorizaci&oacute;n de existencia y aprobaci&oacute;n a que se refiere el art&iacute;culo 131 de la Ley N&ordm; 18.046, fueron otorgadas por Resoluci&oacute;n N&ordm; E-015-81 de la ex Superintendencia de Administradoras de Fondos de Pensiones, hoy Superintendencia de Pensiones, de fecha 25 de Agosto de 1981. Asimismo, el certificado emitido por dicha Superintendencia fue inscrito a fojas 15.859 N&ordm; 8.770 del Registro de Comercio de Santiago de 1981 y se public&oacute; en el Diario Oficial de fecha 27 de agosto de ese mismo a&ntilde;o.</p>\n<h3><strong>Visi&oacute;n</strong></h3>\n<p>Trabajamos d&iacute;a a d&iacute;a con excelencia para el mejor futuro de nuestros afiliados.</p>\n<h3><strong>Misi&oacute;n</strong></h3>\n<p>Lograr un futuro mejor para todos nuestros afiliados, colaboradores y accionistas, ofreciendo una gesti&oacute;n previsional de alto nivel profesional, otorgando un servicio de excelencia y administrando eficientemente los fondos de pensiones.</p>'
            }
         }
    }],
    failures: []
};

export const OUR_COMPANY_CONTENT_MOCK: OurCompanyContent = {
  header: {
    firstLine: 'Nuestra compañía',
    icon: 'analysis',
    image: {
      alt: '¿Quiénes somos?',
      url: 'assets/images/banner-general1.5x.webp',
    },
    image_mobile: {
      alt: '¿Quiénes somos?',
      url: 'assets/images/banner-general1.5x.webp',
    },
  },
  title: 'Nuestra compañia',
  description: 'Descripcion',

};
