import { Mockup } from '@interfaces/mockup.interface';

// tslint:disable:max-line-length
export const GET_CORPORATE_GOVERNANCE_LINKS: Mockup = {
  success: [{
    response: {
      data:{
         secciones:[
            {
               gobierno_corporativo_secciones_id:{
                  titulo:'Políticas de la AFP',
                  archivos:[
                     {
                        gobierno_corporativo_archivos_id:{
                           titulo:'Política de Seguridad, privacidad y capacidad del Sitio Web',
                           archivo:{
                              filename_disk:'bf9daf05-6fec-4abd-af55-b6da61c76863.pdf'
                           }
                        }
                     },
                     {
                        gobierno_corporativo_archivos_id:{
                           titulo:'Política de Sostenibilidad y Gobierno Corporativo',
                           archivo:{
                              filename_disk:'a0ba49db-2a83-4eaf-b3f5-f7c1d5de1ee7.pdf'
                           }
                        }
                     },
                     {
                        gobierno_corporativo_archivos_id:{
                           titulo:'Política de Inversión y Solución de Conflictos de Interés',
                           archivo:{
                              filename_disk:'84e8cccb-4574-4d98-a548-999ef0f76c39.pdf'
                           }
                        }
                     },
                     {
                        gobierno_corporativo_archivos_id:{
                           titulo:'Políticas de Divulgación de la Información y Transparencia',
                           archivo:{
                              filename_disk:'ecf54e3c-7e56-4fe4-97e7-2ecfe2b90dcb.pdf'
                           }
                        }
                     },
                     {
                        gobierno_corporativo_archivos_id:{
                           titulo:'Manual Manejo de información de interés para el Mercado',
                           archivo:{
                              filename_disk:'efe9c7d6-963e-4db0-9803-de71bd7cce1c.pdf'
                           }
                        }
                     }
                  ]
               }
            },
            {
               gobierno_corporativo_secciones_id:{
                  titulo:'Inversión Responsable',
                  archivos:[
                     {
                        gobierno_corporativo_archivos_id:{
                           titulo:'Informe Inversión Responsable 2023',
                           archivo:{
                              filename_disk:'7d68aa09-7530-45d6-9e2b-daab1c2c17b4.pdf'
                           }
                        }
                     },
                     {
                        gobierno_corporativo_archivos_id:{
                           titulo:'Manual de Votación de Juntas de Accionistas, Juntas de Tenedores de Bonos y Asamblea de Aportantes',
                           archivo:{
                              filename_disk:'2a43095b-fe98-4535-bfe6-a7b0e9e92937.pdf'
                           }
                        }
                     },
                     {
                        gobierno_corporativo_archivos_id:{
                           titulo:'Reporte de Votación Responsable en Junta de Accionistas, Juntas de Tenederos de Bonos y Asamblea de Aportantes - 1er Semestre 2024',
                           archivo:{
                              filename_disk:'d691312f-28e2-4faf-b0c9-7de4a5c8b6f3.pdf'
                           }
                        }
                     }
                  ]
               }
            },
            {
               gobierno_corporativo_secciones_id:{
                  titulo:'Personas relacionadas | Gestión de Conflictos de Interés',
                  archivos:[
                     {
                        gobierno_corporativo_archivos_id:{
                           titulo:'Manual de Gestión de Conflictos de Interés',
                           archivo:{
                              filename_disk:'8ce249f9-c51e-4bee-b44d-245e9cf89678.pdf'
                           }
                        }
                     },
                     {
                        gobierno_corporativo_archivos_id:{
                           titulo:'Activos de Baja Liquidez',
                           archivo:{
                              filename_disk:'a2dc56f0-ec00-4bbe-8f90-033ed9307d86.pdf'
                           }
                        }
                     }
                  ]
               }
            },
            {
               gobierno_corporativo_secciones_id:{
                  titulo:'Gerencia de Cumplimiento',
                  archivos:[
                     {
                        gobierno_corporativo_archivos_id:{
                           titulo:'Código de Conducta',
                           archivo:{
                              filename_disk:'d8065841-a5d0-455d-bb22-6f267497867f.pdf'
                           }
                        }
                     },
                     {
                        gobierno_corporativo_archivos_id:{
                           titulo:'Modelo de Prevención de Delitos Ley Nº 20393',
                           archivo:{
                              filename_disk:'f51d7d62-03e3-49e2-967c-505ad90a245a.pdf'
                           }
                        }
                     },
                     {
                        gobierno_corporativo_archivos_id:{
                           titulo:'Sistema de Prevención de Lavado de Activos y Financiamiento del Terrorismo',
                           archivo:{
                              filename_disk:'6d8f1a1f-068a-4f41-b2ad-df450f4eb22d.pdf'
                           }
                        }
                     },
                     {
                        gobierno_corporativo_archivos_id:{
                           titulo:'Sistema de Prevención del Cohecho y otros delitos bases contemplados en la Ley 20393',
                           archivo:{
                              filename_disk:'7c473c22-bbe0-4b2f-bc69-3024e33373ac.pdf'
                           }
                        }
                     }
                  ]
               }
            }
         ]
      }
   }
  }],
  failures: [{
    response: {}
  }]
};
