export interface EconomicIndicators {
  ipcValue: number;
  ipcDate: string;
  ufValue: number;
  utmValue: number;
  utmDate: string;
  afpPercentage: number;
  healthPercentage: number;
}

export interface LastTaxYears {
  years: Array<number>;
}

export interface UfValue {
  ufDate: string;
  ufValue: number;
}

export interface UtmValue {
  utmDate: string;
  utmValue: number;
}
