<ion-content>
  <app-login *ngIf="false" [loginData]="home?.login" class="login-container"></app-login>
  <app-slides-banner [banners]="home?.banners" [bannerStyle]="bannerStyle" [loading]="loading"></app-slides-banner>
  <app-main-actions [mainActions]="home?.mainActions" [loading]="loading"></app-main-actions>

  <app-transfer-pension-option></app-transfer-pension-option>

  <div class="products-section general-container">
    <div class="title-container">
      <h1 *ngIf="!loading" class="title" [ngClass]="font?.size" [innerHTML]="home?.productsContainer.title"></h1>
      <ion-skeleton-text *ngIf="loading" class="skeleton-line title" animated></ion-skeleton-text>
      <p *ngIf="!loading" class="subtitle" [ngClass]="font?.size" [innerHTML]="home?.productsContainer.subtitle"></p>
      <ion-skeleton-text *ngIf="loading" class="skeleton-line" animated></ion-skeleton-text>
    </div>
    <div class="slides-products-container">
      <app-slides-products [products]="home?.productsContainer.products" [loading]="loading"></app-slides-products>
    </div>
  </div>

  <app-quota-values-banner></app-quota-values-banner>

  <div class="principal-articles-container general-container">
    <div class="title-container">
      <h3 *ngIf="!loading" class="title" [ngClass]="font?.size" [innerHTML]="home?.guiaVitalContainer.title"></h3>
      <h3 *ngIf="loading">
        <ion-skeleton-text class="skeleton-line title" animated></ion-skeleton-text>
      </h3>
      <p *ngIf="!loading" class="subtitle" [ngClass]="font?.size" [innerHTML]="home?.guiaVitalContainer.subtitle"></p>
      <p *ngIf="loading">
        <ion-skeleton-text class="skeleton-line" animated></ion-skeleton-text>
      </p>
    </div>
    <div class="items-container guia-vital">
      <div class="item-container primary no-footer">
        <app-card-article class="card"
          [article]="primaryArticle(guiaVitalArticles)"
          [type]="'primary'"
          [moreInfoText]="home?.articlesContainer.moreInfoText"
          [loading]="loading"
          [section]="'guiaVital'"
          >
        </app-card-article>
      </div>
      <div class="item-container secondary no-footer">
        <app-card-article class="card"
          [article]="article"
          [type]="'secondary'"
          [moreInfoText]="home?.articlesContainer.moreInfoText"
          *ngFor="let article of (secondaryArticles(guiaVitalArticles))"
          [loading]="loading"
          [section]="'guiaVital'">
        </app-card-article>
      </div>
    </div>

    <div class="title-container">
      <h3 *ngIf="!loading" class="title" [ngClass]="font?.size" [innerHTML]="home?.articlesContainer.title"></h3>
      <h3 *ngIf="loading">
        <ion-skeleton-text class="skeleton-line title" animated></ion-skeleton-text>
      </h3>
      <p *ngIf="!loading" class="subtitle" [ngClass]="font?.size" [innerHTML]="home?.articlesContainer.subtitle"></p>
      <p *ngIf="loading">
        <ion-skeleton-text class="skeleton-line" animated></ion-skeleton-text>
      </p>
    </div>
    <div class="items-container">
      <div class="item-container primary no-footer">
        <app-card-article class="card"
          [article]="primaryArticle(articles)"
          [type]="'primary'"
          [moreInfoText]="home?.articlesContainer.moreInfoText"
          [loading]="loading"
          [section]="'homeArticles'">
        </app-card-article>
      </div>
      <div class="item-container secondary no-footer">
        <app-card-article class="card"
          [article]="article"
          [type]="'secondary'"
          [moreInfoText]="home?.articlesContainer.moreInfoText"
          *ngFor="let article of (secondaryArticles(articles))"
          [loading]="loading"
          [section]="'homeArticles'">
        </app-card-article>
      </div>
    </div>
    <div class="items-container">
      <div class="item-container secondary no-footer">
        <app-card-article class="card"
          [article]="article"
          [type]="'secondary'"
          [moreInfoText]="home?.moreArticlesContainer.moreInfoText"
          *ngFor="let article of secondaryArticles(moreArticles)"
          [loading]="loading"
          [section]="'homeArticles'">
        </app-card-article>
      </div>
      <div class="item-container primary no-footer last-card">
        <app-card-article class="card"
          [article]="primaryArticle(moreArticles)"
          [type]="'primary'"
          [moreInfoText]="home?.moreArticlesContainer.moreInfoText"
          [loading]="loading"
          [section]="'homeArticles'">
        </app-card-article>
      </div>
    </div>

    <div class="faq-section general-container">
      <app-faq-section [faqData]="home?.faqContainer" [loading]="loading"></app-faq-section>
    </div>
  
    <app-more-information
      *ngIf="false"
      class="detail"
      [disclaimer]="home?.articlesContainer.disclaimer"
      [buttonText]="home?.articlesContainer.disclaimerButton"
      [loading]="loading">
    </app-more-information>
  </div>
  <app-footer></app-footer>
</ion-content>
