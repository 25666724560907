import { take } from 'rxjs/operators';

import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalGenericComponent } from '@components/modal-generic/modal-generic.component';
import {
  BASE_PATHS,
  FORM_TYPES, FORM_TYPES_ONLY_PRIVATE_SITE, PRIVATE_SITE_FORMS_URL
} from '@constants/forms.constant';
import {
  CHILD_HISTORY_REQUEST, CHILD_STATUS, STATUS_HISTORY_REQUEST, STATUS_REQUEST, STATUS_REQUEST_KEY
} from '@constants/omnichannel.constants';
import {
  PAPERWORK_FOLLOW_UP_PAGE_CONTENT
} from '@constants/pages-content/paperwork-follow-up.constant';
import {
  PARTIAL_FORMS_DETAIL_CONTENT, REQUEST_WITH_DECEASED
} from '@constants/pages-content/partial-form-detail.constants';
import { affiliatePaths, pensionerPaths } from '@constants/routes.constant';
import { Application } from '@interfaces/applications.interface';
import {
  FormRequestStatusDetail, PartialFormRequestDetailRequest, ResumeHistoricStates
} from '@interfaces/partial-form.interface';
import { FontService } from '@providers/font/font.service';
import { LoadingService } from '@providers/loading/loading.service';
import { ModalService } from '@providers/modal/modal.service';
import { FormsService } from '@services/forms/forms.service';
import { ErrorUtils } from '@utils/error.utils';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-form-status-detail',
  templateUrl: './form-status-detail.component.html',
  styleUrls: ['./form-status-detail.component.scss'],
})
export class FormStatusDetailComponent implements OnInit {
  @Input() public isPensioner: boolean;
  public pageContent = PARTIAL_FORMS_DETAIL_CONTENT;
  public state: 'loading' | 'loaded' | 'error' = 'loading';
  public breadcrumb: any;
  public formRequestStatusDetail: FormRequestStatusDetail;
  public contactPhone: string;
  public contactEmail: string;
  public attachedFiles = [];
  public historyStatusDefault;
  public historySubStatusDefault;
  public uuid: string;
  public rut: string;
  public type: string;
  public resumeHistoricStatus: ResumeHistoricStates;
  public noShowMessagePartial: boolean;
  public noCommentToShow: boolean;
  public noDocumentToShow: boolean;

  public get requestDetails(): Application { return this.formRequestStatusDetail || {} as Application; }
  public get showDeceasedInfo(): boolean { return REQUEST_WITH_DECEASED.includes(this.requestDetails.type); }
  public get isPartial(): boolean { return this.requestDetails.status === 'P'; }
  public get isMobile(): boolean { return this.utils.isTablet; }
  public get isPendingDocuments(): boolean { return this.requestDetails.status === 'D'; }
  public get channel(): string {
    const origin = this.requestDetails ? this.requestDetails.origin : null;
    return origin;
  }

  private get isPrivateForm(): boolean {
    return Object.keys(PRIVATE_SITE_FORMS_URL).some((privateFormKey) => privateFormKey === this.requestDetails.type);
  }

  constructor(
    public font: FontService,
    private modalService: ModalService,
    private utils: Utils,
    public router: Router,
    private route: ActivatedRoute,
    private partialFormService: FormsService,
    private errorUtils: ErrorUtils,
    private loadingService: LoadingService,
    private location: Location,
  ) {
    this.breadcrumb = this.pageContent.header.breadcrumb;
  }

  public ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      const { uuid, type, rut } = params;
      this.uuid = uuid;
      this.rut = rut;
      this.type = type;
      this.getStatusDetail();
    });
  }

  private getStatusDetail(): void {
    if (!this.uuid || !this.rut || !this.type) { return; }
    this.loadingService.showLoading();
    const request: PartialFormRequestDetailRequest = {
      uuid: this.uuid,
      type: this.type,
      rut: this.rut,
    };
    this.partialFormService.getStatusDetail(request).pipe(take(1)).toPromise()
      .then((response) => {
        this.formRequestStatusDetail = response;
        this.resumeHistoricStatus = this.filterPartialStatus(response);
        this.contactEmail = response.contactEmail;
        this.contactPhone = response.contactCellphone;
        this.noDocumentToShow = FORM_TYPES_ONLY_PRIVATE_SITE.includes(response.type);
        this.noCommentToShow = FORM_TYPES_ONLY_PRIVATE_SITE.includes(response.type);
        this.noShowMessagePartial = FORM_TYPES_ONLY_PRIVATE_SITE.includes(response.type);

        const statusToShow = this.isPendingDocuments ? response.lastState : response.status;
        this.setHistoryStatusDetail(statusToShow);
      })
      .catch((error) => this.handleError(error))
      .finally(() => this.loadingService.hideLoading());
  }

  private filterPartialStatus(statusDetail: FormRequestStatusDetail): ResumeHistoricStates {
    const { status, resumeHistoricStates } = statusDetail;
    if (status === STATUS_REQUEST_KEY.partial) { return resumeHistoricStates; }
    const filteredResumeHistoricStates = {};
    Object.keys(resumeHistoricStates)
      .filter((key) => key !== STATUS_REQUEST_KEY.partial)
      .forEach((filteredKey) => filteredResumeHistoricStates[filteredKey] = resumeHistoricStates[filteredKey]);
    return filteredResumeHistoricStates;
  }

  private setHistoryStatusDetail(currentStatus: string): void {
    const arrayResumeHistoricKeys = Object.keys(this.resumeHistoricStatus).reverse();
    const statusIndex = arrayResumeHistoricKeys.findIndex((statusKey) => statusKey === currentStatus);

    this.historyStatusDefault = arrayResumeHistoricKeys.map((statusKey, index) => {
      const statusHistory = this.resumeHistoricStatus[statusKey];
      const isCurrentStatus = statusKey === currentStatus;
      const hasChildStatus = statusHistory.childStatus.length > 0;
      const displayChildStatus = hasChildStatus && isCurrentStatus;
      let childStatus = null;
      if (displayChildStatus) {
        childStatus = statusHistory.childStatus.map((child) => ({
          status: CHILD_HISTORY_REQUEST[child.code].status,
          definitions: CHILD_HISTORY_REQUEST[child.code].definitions,
          history: child.status === CHILD_STATUS.FINISHED ? 'active' : '',
          displayDefinition: child.status === CHILD_STATUS.PENDING
        }));
      }
      return {
        ...statusHistory,
        visible: 'show',
        displayChildStatus,
        childStatus,
        displayDefinition: !hasChildStatus && isCurrentStatus,
        definitions: STATUS_HISTORY_REQUEST[statusKey].definitions,
        history: index <= statusIndex ? 'active' : 'disabled',
      };
    });
  }

  public getStatusLabel(code: string): string {
    return STATUS_REQUEST[code];
  }

  public hasChildStatus(parentStatus: string): boolean {
    return this.resumeHistoricStatus[parentStatus].childStatus.includes(this.historySubStatusDefault.key);
  }

  public goToForm(): void {
    const type = this.requestDetails.type;
    if (this.isPrivateForm) {
      this.goToPrivateSite(PRIVATE_SITE_FORMS_URL[type]);
      return;
    }
    const rut = this.rut;
    const phone = this.contactPhone;
    const redirect = Object.keys(FORM_TYPES).find((key) => FORM_TYPES[key] === type);
    if (redirect) {
      this.router.navigate([BASE_PATHS[type] + redirect], { queryParams: { rut, phone, type } });
      return;
    }
    this.router.navigate(this.isPensioner ? [pensionerPaths[type]] : [affiliatePaths[type]]);
  }

  public goBack(): void {
    this.location.back();
  }

  public goToPrivateSite(redirectType: string): void {
    window.open(this.utils.getPrivateSiteRedirectUrl(redirectType), '_blank');
  }

  private handleError(error): void {
    const { title, defaultDescription, firstBtnText } = PAPERWORK_FOLLOW_UP_PAGE_CONTENT.modalError;
    const description = this.errorUtils.handleServiceError(error) || defaultDescription;
    const primaryCallback = () => { };
    const data = { title, description, firstBtnText, primaryCallback };
    this.loadingService.hideLoading();
    this.modalService.openModal(ModalGenericComponent, { data });
  }
}
