<div class="content-container general-container">
  <div class="title-container">
    <h1 *ngIf="!loading" class="title" [ngClass]="font?.size">{{ pensionMode?.title | translate }}</h1>
    <ion-skeleton-text *ngIf="loading" class="skeleton-line title" animated></ion-skeleton-text>
    <p *ngIf="!loading" class="subtitle dynamic-text secondary" [ngClass]="font?.size" [innerHTML]="pensionMode?.description | translate"></p>
    <ion-skeleton-text *ngIf="loading" class="skeleton-line large" animated></ion-skeleton-text>
    <ion-skeleton-text *ngIf="loading" class="skeleton-line large" animated></ion-skeleton-text>
    <ion-skeleton-text *ngIf="loading" class="skeleton-line large" animated></ion-skeleton-text>
    <ion-skeleton-text *ngIf="loading" class="skeleton-line large" animated></ion-skeleton-text>
  </div>
</div>

<div class="pension-mode-container general-container">
  <app-icon-card [loading]="loading" [isButtonsPrimary]="false" class="pension-mode" [card]="card" *ngFor="let card of pensionMode?.modeCards"></app-icon-card>
</div>

<app-disclaimer-commision></app-disclaimer-commision>
