import { Component, OnInit } from '@angular/core';
import { SHAREHOLDERS_MEETING_BREADCUMB, SHAREHOLDERS_MEETING_CONTENT } from '@constants/pages-content/shareholders-meeting.constant';
import { FontService } from '@providers/font/font.service';
import { LanguageProvider } from '@providers/language/language.service';
import { CmsService } from '@services/cms/cms.service';

@Component({
  selector: 'app-shareholders-meeting',
  templateUrl: './shareholders-meeting.page.html',
  styleUrls: ['./shareholders-meeting.page.scss'],
})
export class ShareholdersMeetingPage implements OnInit {
  public pageContent = SHAREHOLDERS_MEETING_CONTENT;
  public breadcrumb = [...SHAREHOLDERS_MEETING_BREADCUMB];
  public loading = false;

  constructor(
    public font: FontService,
    private cmsService: CmsService,
    private languageProvider: LanguageProvider,
  ) { }

  public ngOnInit() {
    this.loadData();
    this.languageSubscription();
  }

  private loadData() {
    this.loading = true;
    this.cmsService.loadShareholdersMeetingContent().toPromise()
      .then((response) => {
        this.pageContent.content  = response.content;
        this.pageContent.header = response.header;
      })
      .finally(() => this.loading = false);
  }

  private languageSubscription() {
    this.languageProvider.language.subscribe(() => this.loadData());
  }
}
