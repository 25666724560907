import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEsCl from '@angular/common/locales/es-CL';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ComponentsModule } from '@components/components.module';
import { LoadingComponent } from '@components/loading/loading.component';
import {
  HttpClientInterceptor
} from '@interceptors/http-client-interceptor/http-client-interceptor';
import { RecaptchaInterceptor } from '@interceptors/http-interceptor/recaptcha-interceptor';
import { IpInterceptor } from '@interceptors/http-ip-interceptor/ip-interceptor';
import {
  NoEmailNotificationInterceptorProvider
} from '@interceptors/noemail-notification-interceptor/noemail-notification-interceptor';
import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { AffiliateModule } from '@modules/affiliate/affiliate.module';
import { CorporationModule } from '@modules/corporation/corporation.module';
import { PensionerModule } from '@modules/pensioner/pensioner.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LanguageProvider } from '@providers/language/language.service';
import { LoadingService } from '@providers/loading/loading.service';
import { CustomTranslateLoader } from '@providers/translate-loader/translate-loader';
import { ChartistUtils } from '@utils/chartist.utils';
import { CmsUtils } from '@utils/cms.utils';
import { ErrorUtils } from '@utils/error.utils';

import { FirebaseService } from '@services/firebase/firebase.abstract';
import { FirebaseServiceImplemented } from '@services/firebase/firebase.service';
import { Utils } from '@utils/utils';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InvestorsModule } from '@modules/corporation/investors/investors.module';

registerLocaleData(localeEsCl);

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
  ],
  imports: [
    AffiliateModule,
    PensionerModule,
    InvestorsModule,
    AppRoutingModule,
    BrowserModule,
    ComponentsModule,
    MatDialogModule,
    HttpClientModule,
    IonicModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader
      }
    }),
  ],
  providers: [
    ChartistUtils,
    CmsUtils,
    ErrorUtils,
    HttpClientInterceptor,
    LoadingService,
    LanguageProvider,
    Utils,
    { provide: NavParams, useValue: '' },
    { provide: LOCALE_ID, useValue: 'es-CL' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: RecaptchaInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: IpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoEmailNotificationInterceptorProvider, multi: true },
    { provide: FirebaseService, useClass: FirebaseServiceImplemented },
    {
      provide: APP_INITIALIZER,
      useFactory: (firebaseService: FirebaseServiceImplemented) => async () => {
        firebaseService.initializeFirebase();
        await firebaseService.initializeRemoteConfig();
      },
      deps: [FirebaseService],
      multi: true
    },
  ],
  exports: [
    ReactiveFormsModule,
    TranslateModule
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }

