import { Mockup } from '@interfaces/mockup.interface';


export const GET_CERTIFICATES_RESPONSE: Mockup = {
    success: [
        {
            response: {
                data: {
                    titulo_preguntas: 'Preguntas frecuentes sobre la obtención de certificados',
                    descripcion_preguntas: 'Resuelve tus dudas sobre la obtención de certificados de AFP PlanVital',
                    preguntas: [
                        {
                            faq_id: {
                                pregunta: '¿Qué necesito para obtener un certificado de la AFP?',
                                respuesta: '<p>Solo debes ingresar tu RUT y enviaremos el certificado al correo registrado en tus datos personales. Para certificados como Cotizaciones, Periodos no cotizados y otros certificados tributarios, ingresar a nuestra <a title=\"Sucursal En L&iacute;nea\" href=\"https://sucursalenlinea.planvital.cl/ingreso\" target=\"_blank\" rel=\"noopener\">Sucursal en L&iacute;nea</a>.</p>'
                            }
                        },
                        {
                            faq_id: {
                                pregunta: '¿Cuánto dura un certificado de AFP?',
                                respuesta: '<p>Un certificado de AFP tiene una vigencia de 35 d&iacute;as contados desde la fecha de emisi&oacute;n.</p>\n<p>Fuente: <a title=\"CMF Chile\" href=\"https://www.cmfchile.cl\" target=\"_blank\" rel=\"noopener\">www.cmfchile.cl</a></p>'
                            }
                        }
                    ]
                }
            }
        }
    ],
    failures: []
};

export const GET_CERTIFICATES_MOCK = {
    banner: {
        id: 1,
        image: {
            alt: 'Validación de certificados',
            url: 'assets/images/banner-certificados-online.webp',
        },
        image_mobile: {
            alt: 'Validación de certificados',
            url: 'assets/images/banner-certificados-online.webp',
        },
        icon: 'accept',
        firstLine: `Obtener certificados`,
    },
    faqSection: {
        title: 'Preguntas frecuentes sobre la obtención de certificados',
        subTitle: 'Resuelve tus dudas sobre la obtención de certificados de AFP PlanVital',
        faq: [
            {
                title: '¿Qué necesito para obtener un certificado de la AFP?',
                description: '<p>Solo debes ingresar tu RUT y enviaremos el certificado al correo registrado en tus datos personales. Para certificados como Cotizaciones, Periodos no cotizados y otros certificados tributarios, ingresar a nuestra <a title=\"Sucursal En L&iacute;nea\" href=\"https://sucursalenlinea.planvital.cl/ingreso\" target=\"_blank\" rel=\"noopener\">Sucursal en L&iacute;nea</a>.</p>'
            },
            {
                title: '¿Cuánto dura un certificado de AFP?',
                description: '<p>Un certificado de AFP tiene una vigencia de 35 d&iacute;as contados desde la fecha de emisi&oacute;n.</p>\n<p>Fuente: <a title=\"CMF Chile\" href=\"https://www.cmfchile.cl\" target=\"_blank\" rel=\"noopener\">www.cmfchile.cl</a></p>'
            }
        ]
    }
     
};
