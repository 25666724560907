import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@environment';
import { HttpClientInterceptor } from '@interceptors/http-client-interceptor/http-client-interceptor';
import { ValidateCertificate, ValidateCertificateParams } from '@interfaces/validate-certificate.interface';
import { GET_CERTIFICATE_MOCKUP, VALIDATE_CERTIFICATE_MOCKUP } from '@services/certificates/mocks/certificates.mock';
import { Utils } from '@utils/utils';
import { DateUtils } from '@utils/date.utils';
import { ErrorUtils } from '@utils/error.utils';

@Injectable({
  providedIn: 'root'
})
export class CertificatesService {
  private baseUrl = `${environment.proxyApiUrl}certificates`;
  constructor(
    private errorUtils: ErrorUtils,
    private http: HttpClientInterceptor,
    private utils: Utils,
  ) { }

  public validateCertificate(data: ValidateCertificateParams): Observable<ValidateCertificate> {
    const url = `${this.baseUrl}/validate-certificate`;
    return this.http.get(url, VALIDATE_CERTIFICATE_MOCKUP, data)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getAffiliateCertificate(queryParams: CertificateQueryParams): Observable<EmailResponse> {
    return this.http.get(`${environment.certificatesUrl}v2/certificates/public/affiliate?rut=${this.utils.rutClean(queryParams.rut)}`, GET_CERTIFICATE_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getContingencyRecordsCertificate(queryParams: CertificateQueryParams): Observable<EmailResponse> {
    return this.http.get(`${environment.certificatesUrl}v2/certificates/public/contingency-records?rut=${this.utils.rutClean(queryParams.rut)}`, GET_CERTIFICATE_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getContributionsCertificate(queryParams: CertificateQueryParams): Observable<EmailResponse> {
    const { productType, periodCode, startDate, endDate } = queryParams;
    const dates = Number(periodCode) > 0
      ? { periodCode }
      : {
        startDate: DateUtils.formatDate(startDate, 'yyyy-MM-dd'),
        endDate: DateUtils.formatDate(endDate, 'yyyy-MM-dd'),
      };
    const queryParamsFormatted = { rut: this.utils.rutClean(queryParams.rut), productType, ...dates };
    const queryString = this.buildQueryString(queryParamsFormatted);
    return this.http.get(`${environment.certificatesUrl}v2/certificates/public/contributions?${queryString}`, GET_CERTIFICATE_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getRemunerationsCertificate(queryParams: CertificateQueryParams): Observable<EmailResponse> {
    const { periodCode, startDate, endDate } = queryParams;
    const dates = Number(periodCode) > 0
      ? { periodCode }
      : {
        startDate: DateUtils.formatDate(startDate, 'yyyy-MM-dd'),
        endDate: DateUtils.formatDate(endDate, 'yyyy-MM-dd'),
      };
    const queryParamsFormatted = { rut: this.utils.rutClean(queryParams.rut), ...dates };
    const queryString = this.buildQueryString(queryParamsFormatted);

    return this.http.get(`${environment.certificatesUrl}v2/certificates/public/remunerations?${queryString}`, GET_CERTIFICATE_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getProgressiveHolidaysCertificate(queryParams: CertificateQueryParams): Observable<EmailResponse> {
    return this.http.get(`${environment.certificatesUrl}v2/certificates/public/progressive-holidays?rut=${this.utils.rutClean(queryParams.rut)}`, GET_CERTIFICATE_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getResidenceCertificate(queryParams: CertificateQueryParams): Observable<EmailResponse> {
    return this.http.get(`${environment.certificatesUrl}v2/certificates/public/residence?rut=${this.utils.rutClean(queryParams.rut)}`, GET_CERTIFICATE_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  private buildQueryString(params: Record<string, any>): string {
    return Object.entries(params)
      .filter(([_, value]) => value !== undefined && value !== null)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
  }
}

export const ALLOWED_PRODUCT_TYPES = ['CCO', 'CAV', 'CCV'] as const;
export type ProductType = typeof ALLOWED_PRODUCT_TYPES[number];

export interface CertificateQueryParams {
  rut: string;
  productType?: ProductType;
  periodCode?: string;
  startDate?: Date;
  endDate?: Date;
}

export interface EmailResponse {
  success: boolean;
  response: {
    code: number;
    message: string;
  };
  error: any;
}
