<ion-content class="content">
  <app-banner [banner]="pageContent.header"></app-banner>
  <app-breadcrumb class="breadcrumb" [pathNames]="breadcrumb"></app-breadcrumb>
  <div class="information-container general-container">
    <h2 class="title" [ngClass]="font?.size">{{ pageContent.title | translate }}</h2>
    <div *ngFor="let topic of pageContent.topics">
      <h3 *ngIf="!loading" class="title secondary secondary-title" [ngClass]="font?.size">{{ topic.title | translate }}</h3>
      <ion-skeleton-text *ngIf="loading" class="skeleton-line title" animated></ion-skeleton-text>
      <app-download-section [list]="topic.urlSectionList" [loading]="loading"></app-download-section>
    </div>
  </div>
  <app-footer></app-footer>
</ion-content>
