import { Mockup } from '@interfaces/mockup.interface';


export const GET_MODALS_MOCKUP: Mockup = {
    success: [
        {
            response: {
                data: [
                    {
                        id_modal: 5,
                        card_pos: 5,
                        traducciones: [
                            {
                                titulo: "Retiro de fondos <strong>Técnicos Extranjerossss</strong>",
                                cuerpo: "<p>Los requisitos para solicitar la devoluci&oacute;n de fondos son:</p>\n<ul>\n<li>Contar con un t&iacute;tulo t&eacute;cnico o profesional otorgado en el extranjero.</li>\n<li>Estar afiliado en alg&uacute;n sistema de previsi&oacute;n o de seguridad social en el extranjero con anterioridad a la firma del Contrato de Trabajo en Chile.</li>\n<li>En el Contrato de Trabajo o anexo debes manifestar tu voluntad de mantener la afiliaci&oacute;n en el sistema previsional fuera de Chile, que otorgue prestaciones en casos de enfermedad, invalidez, vejez y muerte.</li>\n<li>Cumples con el primer y segundo requisito presentando certificados emitidos por las instituciones correspondientes, los que deben estar traducidos, legalizados o apostillados, seg&uacute;n sea el caso.</li>\n</ul>",
                                languages_code: {
                                    code: "es"
                                }
                            },
                            {
                                titulo: "Retiro de fondos <strong>Técnicos Extranjeros</strong>",
                                cuerpo: "<p>Los requisitos para solicitar la devoluci&oacute;n de fondos son:</p>\n<ul>\n<li>Contar con un t&iacute;tulo t&eacute;cnico o profesional otorgado en el extranjero.</li>\n<li>Estar afiliado en alg&uacute;n sistema de previsi&oacute;n o de seguridad social en el extranjero con anterioridad a la firma del Contrato de Trabajo en Chile.</li>\n<li>En el Contrato de Trabajo o anexo debes manifestar tu voluntad de mantener la afiliaci&oacute;n en el sistema previsional fuera de Chile, que otorgue prestaciones en casos de enfermedad, invalidez, vejez y muerte.</li>\n<li>Cumples con el primer y segundo requisito presentando certificados emitidos por las instituciones correspondientes, los que deben estar traducidos, legalizados o apostillados, seg&uacute;n sea el caso.</li>\n</ul>",
                                languages_code: {
                                    code: "ht"
                                }
                            }
                        ]
                    },
                    {
                        id_modal: 6,
                        card_pos: 2,
                        traducciones: [
                            {
                                titulo: "Documentación necesaria para la <strong>Solicitud de devolución de pago en exceso</strong>",
                                cuerpo: "<p>Junto al formulario para la solicitud de devoluci&oacute;n de pago en exceso debes agregar la siguiente documentaci&oacute;n seg&uacute;n sea tu caso:</p>\n<ul>\n<li>Si eres pensionado o cumpliste la edad legal<br><br>\n<ul>\n<li>Resoluci&oacute;n de pensi&oacute;n o Certificado de pensi&oacute;n</li>\n<li>Liquidaciones de sueldo</li>\n</ul>\n</li>\n<li>Si eres afiliado y realizaste un pago que no corresponde<br><br>\n<ul>\n<li>Planillas de pago</li>\n<li>Liquidaciones de sueldo</li>\n</ul>\n</li>\n<li>Si eres afiliado y tu cotizaci&oacute;n super&oacute; el tope imponible<br><br>\n<ul>\n<li>Liquidaciones de sueldo (incluidos bonos, gratificaciones si corresponde)</li>\n<li>Certificado de rentas de la entidad subsidiadora en caso de licencias m&eacute;dicas</li>\n</ul>\n</li>\n<li>Si eres afiliado y te descontaron una comisi&oacute;n mayor por administraci&oacute;n<br><br>\n<ul>\n<li>Liquidaciones de sueldo</li>\n</ul>\n</li>\n</ul>",
                                languages_code: {
                                    code: "es"
                                }
                            }
                        ]
                    }
                ]
            
            }
        }
    ],
    failures: []
};
