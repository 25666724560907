import { MandatorySavings } from '@interfaces/savings.interface';

import { MORE_INFO } from './general.constant';

const pageKey = 'AFFILIATE.SAVE_WITH_US.MANDATORY_SAVINGS';

export const MANDATORY_SAVINGS_CONTENT: MandatorySavings = {
  header: {
    image: null,
    icon: 'lock',
    firstLine: `${pageKey}.HEADER.TITLE`,
    secondLine: `${pageKey}.HEADER.SUBTITLE`,
  },
  carousel: {
    title: `${pageKey}.CAROUSEL.TITLE`,
    subtitle: `${pageKey}.CAROUSEL.SUBTITLE`,
    button: `${pageKey}.CAROUSEL.BUTTON`,
    benefits: [],
  },
  savingCardsSection: {
    title: `${pageKey}.CARDS.TITLE`,
    subtitle: `${pageKey}.CARDS.SUBTITLE`,
    cards: [
      {
        image: {
          url: 'assets/images/01card-af-dependiente.webp',
          alt: 'Afiliado dependiente',
          title: 'Afiliado dependiente'
        },
        title: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.TITLE`,
        description: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.DESCRIPTION`,
        showMoreText: MORE_INFO,
        modal: {
          title: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.TITLE`,
          description: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.MODAL_DESCRIPTION`,
          image: {
            url: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.IMAGE`,
            alt: 'Dependiente'
          },
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: 'assets/images/02card-af-independiente.webp',
          alt: 'Afiliado independiente',
          title: 'Afiliado independiente'
        },
        title: `${pageKey}.CARDS.AFFILIATES.INDEPENDENT.TITLE`,
        description: `${pageKey}.CARDS.AFFILIATES.INDEPENDENT.DESCRIPTION`,
        showMoreText: MORE_INFO,
        modal: {
          title: `${pageKey}.CARDS.AFFILIATES.INDEPENDENT.TITLE`,
          description: `${pageKey}.CARDS.AFFILIATES.INDEPENDENT.MODAL_DESCRIPTION`,
          image: null,
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: 'assets/images/03card-af-voluntario.webp',
          alt: 'Voluntario',
          title: 'Voluntario'
        },
        title: `${pageKey}.CARDS.AFFILIATES.VOLUNTARY.TITLE`,
        description: `${pageKey}.CARDS.AFFILIATES.VOLUNTARY.DESCRIPTION`,
        showMoreText: MORE_INFO,
        modal: {
          title: `${pageKey}.CARDS.AFFILIATES.VOLUNTARY.TITLE`,
          description: `${pageKey}.CARDS.AFFILIATES.VOLUNTARY.MODAL_DESCRIPTION`,
          image: null,
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
    ]
  },
  adviceSection: {
    title: `${pageKey}.ADVICE.TITLE`,
    disclaimer: `${pageKey}.ADVICE.DISCLAIMER`,
    disclaimerButton: `${pageKey}.ADVICE.BUTTON`,
    disclaimerUrl: 'download_app',
  },
  faqSection: {
    title: '',
    subTitle: '',
    faq: []
  }
};
