import { AssignationTable, MultiFunds, ProfitabilityTable, RestrictionsTable } from '@interfaces/multi-funds.interface';
import { MORE_INFO } from './general.constant';

// tslint:disable:max-line-length
const pageKey = 'AFFILIATE.ASSESSMENT.MULTI_FUNDS';

export const CARD_TITLE_TO_DISCARD = 'Rentabilidad';

export const MULTI_FUNDS_CONTENT: MultiFunds = {
  header: null,
  title: `${pageKey}.TITLE`,
  description: `${pageKey}.DESCRIPTION`,
  showImgCards: true,
  funds: [
    {
      name: 'A',
      risk: `${pageKey}.FUNDS.A.RISK`,
      description: `${pageKey}.FUNDS.A.DESCRIPTION`,
      maxLimit: '80%',
      minLimit: '40%',
    },
    {
      name: 'B',
      risk: `${pageKey}.FUNDS.B.RISK`,
      description: `${pageKey}.FUNDS.B.DESCRIPTION`,
      maxLimit: '60%',
      minLimit: '25%',
    },
    {
      name: 'C',
      risk: `${pageKey}.FUNDS.C.RISK`,
      description: `${pageKey}.FUNDS.C.DESCRIPTION`,
      maxLimit: '40%',
      minLimit: '15%',
    },
    {
      name: 'D',
      risk: `${pageKey}.FUNDS.D.RISK`,
      description: `${pageKey}.FUNDS.D.DESCRIPTION`,
      maxLimit: '20%',
      minLimit: '5%',
    },
    {
      name: 'E',
      risk: `${pageKey}.FUNDS.E.RISK`,
      description: `${pageKey}.FUNDS.E.DESCRIPTION`,
      maxLimit: '5%',
      minLimit: '0%',
    },
  ],
  helpSection: {
    risky: {
      title: `${pageKey}.HELP.RISKY.TITLE`,
      description: `${pageKey}.HELP.RISKY.DESCRIPTION`,
    },
    safe: {
      title: `${pageKey}.HELP.SAFE.TITLE`,
      description: `${pageKey}.HELP.SAFE.DESCRIPTION`,
    }
  },
  fundChangeDisclaimer: `${pageKey}.FUND_CHANGE_DISCLAIMER`,
  moreInfoCards: [
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_restricciones.jpg',
        alt: 'Restricciones'
      },
      title: `${pageKey}.CARDS.RESTRICTIONS.TITLE`,
      showMoreText: MORE_INFO,
      modal: {
        type: 'restrictions',
        title: `${pageKey}.CARDS.RESTRICTIONS.TITLE`,
        description: `${pageKey}.CARDS.RESTRICTIONS.MODAL_DESCRIPTION`,
        data: {
          header: {
            type: 'Tipo de Afiliado',
            age: 'Edad',
            funds: 'Fondos a optar',
          },
          items: [
            {
              type: 'No pensionado',
              age: '<p>Hombre hasta 55 años.</p>\n<p>Mujer hasta 50 años.</p>',
              funds: 'A, B, C, D, E',
            },
            {
              type: 'No pensionado',
              age: '<p>Hombres desde 56 años.</p>\n<p>Mujer desde los 51 años.</p>',
              funds: 'B, C, D, E',
            },
            {
              type: 'Pensionado',
              age: 'Cualquier edad',
              funds: 'C, D, E',
            },
          ]
        } as RestrictionsTable
      },
    },
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_cambios.jpg',
        alt: 'Cambio multifondos'
      },
      title: `${pageKey}.CARDS.REGULAR_CHANGE.TITLE`,
      showMoreText: MORE_INFO,
      modal: {
        title: `${pageKey}.CARDS.REGULAR_CHANGE.TITLE`,
        description: `${pageKey}.CARDS.REGULAR_CHANGE.MODAL_DESCRIPTION`,
      },
    },
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_eleccion.jpg',
        alt: 'Elección multifondos'
      },
      title: `${pageKey}.CARDS.ASSIGNATION.TITLE`,
      showMoreText: MORE_INFO,
      modal: {
        type: 'assignation',
        title: `${pageKey}.CARDS.ASSIGNATION.TITLE`,
        description: `${pageKey}.CARDS.ASSIGNATION.MODAL_DESCRIPTION`,
        data: {
          header: {
            age: 'Edad',
            fund: 'Fondos a optar',
          },
          items: [
            {
              age: 'Hombres y mujeres hasta 35 años.',
              fund: 'B',
            },
            {
              age: '<p>Hombres desde 36 a 55 años.</p>\n<p>Mujeres desde 36 hasta los 50 años.</p>',
              fund: 'C',
            },
            {
              age: '<p>Hombres desde 56 años.</p>\n<p>Mujeres desde 51 años.</p>\n<p>Pensionados.</p>',
              fund: 'D',
            },
          ]
        } as AssignationTable
      },
    },
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_rentabilidad.jpg',
        alt: 'Rentabilidad multifondos'
      },
      title: `${pageKey}.CARDS.PROFITABILITY.TITLE`,
      showMoreText: MORE_INFO,
      modal: {
        type: 'profitability',
        title: `${pageKey}.CARDS.PROFITABILITY.TITLE`,
        description: `${pageKey}.CARDS.PROFITABILITY.MODAL_DESCRIPTION`,
        data: {
          header: {
            fund: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.FUND`,
            month: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.MONTH`,
            semester: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.SEMESTER`,
            lastYear: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.LAST_YEAR`,
            lastYears: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.LAST_YEARS`,
            historic: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.HISTORIC`,
          },
          items: [
            {
              fund: 'A',
              month: '-0,35%',
              semester: '-5,98%',
              lastYear: '0,42%',
              lastYears: '5,58%',
              historic: '',
            },
            {
              fund: 'B',
              month: '-0,14%',
              semester: '-2,72%',
              lastYear: '1,09%',
              lastYears: '5,06%',
              historic: '',
            },
            {
              fund: 'C',
              month: '-0,41%',
              semester: '-0,57%',
              lastYear: '1,32%',
              lastYears: '4,89%',
              historic: '8,04%',
            },
            {
              fund: 'D',
              month: '-0,42%',
              semester: '1,04%',
              lastYear: '0,14%',
              lastYears: '4,30%',
              historic: '',
            },
            {
              fund: 'E',
              month: '-0,58%',
              semester: '2,41%',
              lastYear: '0,00%',
              lastYears: '3,43%',
              historic: '4,18%',
            },
          ]
        } as ProfitabilityTable,
      }
    },
  ]
};
