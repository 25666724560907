import * as fileSaver from 'file-saver';
import { clean, format as rutFormat } from 'rut.js';

import { formatDate } from '@angular/common';
import { Injectable, SimpleChange } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  EXISTING_INSERTED_REQUEST,
  EXISTING_PARTIAL_REQUEST,
} from '@constants/datastore-errors.constant';
import {
  FILE_EXTENSIONS,
  VIRTUAL_ACCOUNTS_BANKS,
} from '@constants/forms.constant';
import {
  PRIVATE_SITE_LOGIN_PATH,
  SCREEN_BREAKPOINTS,
} from '@constants/general.constant';
import {
  MONTHS_EN,
  MONTHS_ES,
  MONTHS_EXTENDED,
  MONTHS_HT,
} from '@constants/months.constant';
import { DEFAULT_MODAL_DATA } from '@constants/pages-content/partial-form.constant';
import {
  ALPHANUMERIC_PATTERN,
  FILE_REG_RENAME,
} from '@constants/regex.constant';
import { LANGUAGE_KEY } from '@constants/translate.constant';
import { BRANCH_OFFICE_CLOSED } from '@constants/utils.constants';
import { environment } from '@environment';
import { Article } from '@interfaces/components.interface';
import { Error } from '@interfaces/error.class';
import { FormFiles } from '@interfaces/form-files';
import { PartialFormsFilesResponse } from '@interfaces/forms.interface';
import { GenericModalData } from '@interfaces/modal.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class Utils {
  constructor(private translateService: TranslateService) { }

  public static getTranslatedDate(language = 'es', date = new Date()): string {
    let format = 'MM-dd-yyyy';
    let locale = 'es-CL';
    switch (language) {
      case 'es': {
        format = '\'al\' d \'de\' MMMM \'de\' yyyy';
        break;
      }
      case 'en': {
        format = '\'up to\' MMMM d, yyyy';
        locale = 'en-US';
        break;
      }
    }
    return formatDate(date, format, locale, 'GMT-0300');
  }

  public static getMonthTranslate(
    monthNumber: string,
    language?: string
  ): string {
    if (!monthNumber) {
      return '';
    }
    switch (language) {
      case 'ht':
        return `${MONTHS_HT[monthNumber]}.`;
      case 'en':
        return `${MONTHS_EN[monthNumber]}.`;
      default:
        return `${MONTHS_ES[monthNumber]}.`;
    }
  }

  public get isTablet(): boolean {
    return window.innerWidth <= SCREEN_BREAKPOINTS.TABLET;
  }

  public get isMobile(): boolean {
    return window.innerWidth <= SCREEN_BREAKPOINTS.MEDIUM_MOBILE;
  }

  public static getDate(date = new Date(), differenceDays = 0): Date {
    date.setDate(date.getDate() + differenceDays);
    return date;
  }

  public static isVirtualAccount(
    accountNumber: string,
    bankCode: string
  ): boolean {
    const bankCodeWithoutZero = bankCode.replace(/^0+/, '');
    const prefix: string = VIRTUAL_ACCOUNTS_BANKS[bankCodeWithoutZero];
    if (!prefix) {
      return false;
    }
    const accountNumberWithoutZero = accountNumber.replace(/^0+/, '');
    return accountNumberWithoutZero.startsWith(prefix);
  }

  public async getTranslate(key: string) {
    return await this.translateService.get(key).toPromise();
  }

  public async getTranslatedMonthName(monthIndex: number) {
    return await this.translateService
      .get(MONTHS_EXTENDED[monthIndex])
      .toPromise();
  }

  public scrollToTop(elementToScroll: string): void {
    const element = document.getElementById(elementToScroll);
    if (element) {
      element.scrollIntoView();
    }
  }

  public compareStringUsingWildcard(
    template: string,
    stringToCompare: string
  ): boolean {
    return new RegExp('^' + template.replace(/\*/g, '.*') + '$').test(
      stringToCompare
    );
  }

  public getFormattedDate(date: Date, format = 'yyyy-MM-dd'): string {
    return formatDate(date, format, 'es-CL');
  }

  public firstDayOfMonth(month: number) {
    return new Date(`${month + 1}-01-${new Date().getFullYear()}`);
  }

  public getLastDayOfMonth(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }

  public isLastDayOfMonth(date: Date): boolean {
    return date.getDate() === this.getLastDayOfMonth(date);
  }

  public isHalfDayOfMonth(date: Date): boolean {
    return date.getDate() === 15;
  }

  public isDayNumberOfWeek(date: Date, dayNumber: number): boolean {
    return date.getDay() === dayNumber;
  }

  public getDate(date: string): Date {
    if (date) {
      const dateSplited = date.split('-');
      dateSplited[2] = dateSplited[2].replace('01', '02');
      const finalDate = dateSplited.join('-');
      return new Date(finalDate);
    }
    return new Date(date);
  }

  public downloadFile(url: string, title?: string) {
    if (title) {
      return this.translateService
        .get(title)
        .subscribe((translatedTitle) => fileSaver.saveAs(url, translatedTitle));
    }
    return fileSaver.saveAs(url);
  }

  public cleanBranchOfficeName(name: string): string {
    if (!name) {
      return '';
    }
    const formattedName = this.addSpaceAfterComma(name);
    return this.capitalizeText(
      formattedName.toLowerCase().split(BRANCH_OFFICE_CLOSED)[0]
    );
  }

  public addSpaceAfterComma(name: string): string {
    if (name.includes(',') && !name.includes(' ')) {
      return name.split(',').join(', ');
    }
    return name;
  }

  public capitalizeText(text: string): string {
    if (!text) {
      return '';
    }
    return text
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(' ');
  }

  public rutFormat(unformatteRut: string) {
    unformatteRut = clean(unformatteRut);
    return rutFormat(unformatteRut);
  }

  public rutClean(rut: string) {
    return clean(rut);
  }

  public getPrimaryArticle(articlesArray: Array<Article>): Article {
    return articlesArray[0] || null;
  }

  public getSecondaryArticles(articlesArray: Array<Article>): Array<Article> {
    return articlesArray.length >= 2 ? articlesArray.slice(1, 5) : null;
  }

  public getFullName(
    name: string,
    lastName: string,
    secondSurname?: string
  ): string {
    return this.joinNames(name, this.getFullSurname(lastName, secondSurname));
  }

  public getFullSurname(lastName: string, secondSurname?: string): string {
    return secondSurname
      ? this.joinNames(lastName, secondSurname)
      : lastName.trim();
  }

  public joinNames(...names): string {
    return names
      .map((name) => name.trim())
      .join(' ')
      .trim();
  }

  public downloadPdf(base64: string, name: string) {
    const blob = this.base64toBlob(base64);
    const url = URL.createObjectURL(blob);
    const safeName = name.replace(/[/\\?%*:|'<>]/g, '-');
    const fileName = `pv_${safeName}_${+new Date()}.pdf`;
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public downloadBase64File(
    base64Data: string,
    name: string,
    type = 'application/pdf'
  ): void {
    const blob = this.base64toBlob(base64Data, type);
    const blobUrl = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    anchor.style.display = 'none';
    anchor.href = blobUrl;
    anchor.download = name;
    anchor.click();
  }

  public getPrivateSiteRedirectUrl(redirect: string): string {
    return `${environment.privateSiteUrl}${PRIVATE_SITE_LOGIN_PATH}?redirect=${redirect}`;
  }

  public getAlimonySiteRedirectUrl() {
    return environment.urlAlimony;
  }

  public calculateAge(birthDate: Date): number {
    if (!birthDate) {
      return -1;
    }
    const now = Date.now();
    const ageMs = new Date(now - birthDate.getTime());
    return Math.abs(ageMs.getUTCFullYear() - 1970);
  }

  public base64toBlob(
    base64Data: string,
    contentType: string = 'application/pdf',
    sliceSize: number = 512
  ): Blob {
    const byteCharacters = atob(base64Data);
    const byteArrays = this.range(byteCharacters.length / sliceSize + 1)
      .map((digit: number) => digit * sliceSize)
      .map((offset: number) => byteCharacters.slice(offset, offset + sliceSize))
      .map((selectedBytes: string) =>
        Array.from(selectedBytes).map((char) => char.charCodeAt(0))
      )
      .map((byteNumbers: Array<number>) => new Uint8Array(byteNumbers));
    return new Blob(byteArrays, { type: contentType });
  }

  private range(length: number): Array<number> {
    return Array.from({ length }, (value, key) => key);
  }

  public createFormData(
    object: any,
    form?: FormData,
    namespace?: string
  ): FormData {
    const formData = form || new FormData();

    for (const property in object) {
      if (
        !object.hasOwnProperty(property) ||
        this.isNullOrUndefined(object[property])
      ) {
        continue;
      }

      const attribute = object[property];

      const formKey = namespace ? `${namespace}[${property}]` : property;

      if (this.isFormFiles(attribute)) {
        attribute.data.forEach((file: string) =>
          formData.append(formKey, file)
        );
      } else if (this.isObjectType(attribute) && !this.isFile(attribute)) {
        this.createFormData(attribute, formData, formKey);
      } else {
        formData.append(formKey, attribute);
      }
    }
    return formData;
  }

  public onlyLettersAndNumbers(name: string): boolean {
    return new RegExp('^[a-zA-Z0-9]+$').test(name);
  }

  public inputHasChanged(value: SimpleChange): boolean {
    if (!value) {
      return false;
    }
    return (
      JSON.stringify(value.previousValue) !== JSON.stringify(value.currentValue)
    );
  }

  public generateAlphanumericValidator(maxLength: number): Array<Validators> {
    return [
      Validators.required,
      Validators.maxLength(maxLength),
      Validators.pattern(ALPHANUMERIC_PATTERN),
    ];
  }

  public base64ToFile(attached: PartialFormsFilesResponse): File {
    if (!attached) {
      return null;
    }
    const blobFile = this.base64toBlob(
      attached.base64File,
      attached.contentType
    );
    return new File([blobFile], attached.fileName, {
      type: attached.contentType,
    });
  }

  public get language() {
    if (localStorage.getItem(LANGUAGE_KEY) !== null) {
      return localStorage.getItem(LANGUAGE_KEY);
    }
    return 'es';
  }

  public mapDatastoreMessageError(
    error,
    primaryCallback: Function
  ): GenericModalData {
    const { code, title, description } = new Error(error);
    if (code === EXISTING_INSERTED_REQUEST) {
      return {
        ...DEFAULT_MODAL_DATA.existingInsertError,
        primaryCallback,
        title,
        description,
      } as GenericModalData;
    }
    if (code === EXISTING_PARTIAL_REQUEST) {
      return {
        ...DEFAULT_MODAL_DATA.contactInformationError,
        title,
        description,
      } as GenericModalData;
    }
    return null;
  }

  public stringToHslPastelColor(str: string): string {
    let num = 0;
    for (let i = 0; i < str.length; i++) {
      num += str.charCodeAt(i);
    }
    const hue = Math.floor(num % 360);
    return 'hsl(' + hue + ', 50%, 50%)';
  }

  public isTextBackground(backgroundId: string): boolean {
    return backgroundId && backgroundId.includes('Text');
  }

  public cleanLeftZeros(text: string): string {
    return text ? text.replace(/^0+/, '') : null;
  }

  public addTimezoneOffset(date: string): string {
    const utcDate = new Date(date);
    const localeDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
    );
    return localeDate.toISOString();
  }

  private isFormFiles(object: any): boolean {
    return object instanceof FormFiles;
  }

  private isFile(object: any): boolean {
    return object instanceof File;
  }

  private isObjectType(object: any): boolean {
    return typeof object === 'object';
  }

  private isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
  }

  public slugifyFileName(str: string): string {
    let extensionFile = '';
    const newStringWithoutDots = str
      .trim()
      .toLowerCase()
      .split('.')
      .reduce(
        (acc, item) => {
          if (!FILE_EXTENSIONS.includes(item)) {
            acc.push(item);
          } else {
            extensionFile = item;
          }
          return acc;
        },
        ['']
      )
      .join('');

    const newFileName = newStringWithoutDots
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '')
      .replace(/\d+/g, '')
      .split('')
      .reduce(
        (acc, item) => {
          if (acc[acc.length - 1] !== item) {
            acc.push(item);
          }
          return acc;
        },
        ['']
      )
      .join('')
      .replace(/_/g, '-');

    return `${newFileName}.${extensionFile}`;
  }

  public renameFile(name: string) {
    name = name.replace(FILE_REG_RENAME, '');
    name = name.replace('_', '');
    return name;
  }

  public get isMediumMobile(): boolean {
    return window.innerWidth <= SCREEN_BREAKPOINTS.MEDIUM_MOBILE;
  }

  public get isSmallMobile(): boolean {
    return window.innerWidth <= SCREEN_BREAKPOINTS.SMALL_MOBILE;
  }

  public findState(array, states, formType): string {
    const result = array.find(item => item.formType === formType && states.includes(item.state));
    return result ? result.state : null;
  }
}
