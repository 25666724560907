import { MetaTag } from '@interfaces/meta-tag.interface';

export const METATAGS: MetaTag[] = [
  {
    url: 'afiliado/inicio',
    title: 'Afiliación AFP: ¡Ahorros y Valores Cuotas! | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Consigue la mejor rentabilidad y paga menos comisión con Plan Vital.' +
        'Tu AFP ideal para fondos de inversión eficientes. ¡Descubre más!'
    }, {
      name: 'abstract',
      content: 'Consigue la mejor rentabilidad y paga menos comisión con Plan Vital.' +
        'Tu AFP ideal para fondos de inversión eficientes. ¡Descubre más!'
    }, {
      name: 'keywords',
      content: 'afiliacion afp, ahorro afp'
    }, {
      property: 'og:title',
      content: 'Afiliación AFP: ¡Ahorros y Valores Cuotas! | AFP PlanVital'
    }, {
      property: 'og:description',
      content: 'Consigue la mejor rentabilidad y paga menos comisión con Plan Vital.' +
        'Tu AFP ideal para fondos de inversión eficientes. ¡Descubre más!'
    }, {
      property: 'og:url',
      content: 'https://www.planvital.cl/afiliado/inicio'
    }, {
      name: 'twitter:card',
      content: 'summary'
    }, {
      name: 'twitter:description',
      content: 'Consigue la mejor rentabilidad y paga menos comisión con Plan Vital.' +
        'Tu AFP ideal para fondos de inversión eficientes. ¡Descubre más!'
    }, {
      name: 'twitter:title',
      content: 'Afiliación AFP: ¡Ahorros y Valores Cuotas! | AFP PlanVital'
    }]
  },
  {
    url: 'afiliado/ahorros/previsional-voluntario',
    title: 'Cuenta APV: Ahorro Previsional Voluntario | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Mejora tu pensión con el Ahorro Previsional Voluntario (APV) de Plan Vital. ' +
        'Más beneficios y seguridad para tu futuro. ¡Ahorra ahora!'
    }, {
      name: 'abstract',
      content: 'Mejora tu pensión con el Ahorro Previsional Voluntario (APV) de Plan Vital. ' +
        'Más beneficios y seguridad para tu futuro. ¡Ahorra ahora!'
    }, {
      name: 'keywords',
      content: 'apv, ahorro previsional voluntario, ahorro apv, ahorro voluntario, ahorro voluntario afp, ahorro previsional, ' +
        'cuenta apv'
    }, {
      property: 'og:title',
      content: 'Cuenta APV: Ahorro Previsional Voluntario | AFP PlanVital'
    }, {
      property: 'og:description',
      content: 'Mejora tu pensión con el Ahorro Previsional Voluntario (APV) de Plan Vital. ' +
        'Más beneficios y seguridad para tu futuro. ¡Ahorra ahora!'
    }, {
      property: 'og:url',
      content: 'https://www.planvital.cl/afiliado/ahorros/previsional-voluntario'
    }, {
      name: 'twitter:card',
      content: 'summary'
    }, {
      name: 'twitter:description',
      content: 'Mejora tu pensión con el Ahorro Previsional Voluntario (APV) de Plan Vital. ' +
        'Más beneficios y seguridad para tu futuro. ¡Ahorra ahora!'
    }, {
      name: 'twitter:title',
      content: 'Cuenta APV: Ahorro Previsional Voluntario | AFP PlanVital'
    }]
  },
  {
    url: 'afiliado/ahorros/cuenta-2',
    title: 'Cuenta de Ahorro AFP ¡Obtén tu Cuenta 2! | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Abre tu Cuenta 2 de ahorro AFP con Plan Vital. Maximiza tu inversión y asegura tu futuro financiero. ' +
        '¡Empieza hoy mismo!'
    }, {
      name: 'abstract',
      content: 'Abre tu Cuenta 2 de ahorro AFP con Plan Vital. Maximiza tu inversión y asegura tu futuro financiero. ' +
        '¡Empieza hoy mismo!'
    }, {
      name: 'keywords',
      content: 'cuenta de ahorro, ahorro, cuenta 2 afp, cuenta 2, cuenta 2 afp planvital'
    }, {
      property: 'og:title',
      content: 'Cuenta de Ahorro AFP ¡Obtén tu Cuenta 2! | AFP PlanVital'
    }, {
      property: 'og:description',
      content: 'Abre tu Cuenta 2 de ahorro AFP con Plan Vital. Maximiza tu inversión y asegura tu futuro financiero. ' +
        '¡Empieza hoy mismo!'
    }, {
      property: 'og:url',
      content: 'https://www.planvital.cl/afiliado/ahorros/cuenta-2'
    }, {
      name: 'twitter:card',
      content: 'summary'
    }, {
      name: 'twitter:description',
      content: 'Abre tu Cuenta 2 de ahorro AFP con Plan Vital. Maximiza tu inversión y asegura tu futuro financiero. ' +
        '¡Empieza hoy mismo!'
    }, {
      name: 'twitter:title',
      content: 'Cuenta de Ahorro AFP ¡Obtén tu Cuenta 2! | AFP PlanVital'
    }]
  },
  {
    url: 'afiliado/ahorros/obligatorio',
    title: 'Ahorro Previsional Obligatorio: Cotización Pensión | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Asegura tu futuro con el ahorro obligatorio para pensión en Plan Vital. Gestión eficiente de tu AFP ' +
        'para una jubilación tranquila.'
    }, {
      name: 'abstract',
      content: 'Asegura tu futuro con el ahorro obligatorio para pensión en Plan Vital. Gestión eficiente de tu AFP ' +
        'para una jubilación tranquila.'
    }, {
      name: 'keywords',
      content: 'ahorro previsional obligatorio, cotizacion obligatoria, ahorro obligatorio ahorro para pension'
    }, {
      property: 'og:title',
      content: 'Ahorro Previsional Obligatorio: Cotización Pensión | AFP PlanVital'
    }, {
      property: 'og:description',
      content: 'Asegura tu futuro con el ahorro obligatorio para pensión en Plan Vital. Gestión eficiente de tu AFP ' +
        'para una jubilación tranquila.'
    }, {
      property: 'og:url',
      content: 'https://www.planvital.cl/afiliado/ahorros/obligatorio'
    }, {
      name: 'twitter:card',
      content: 'summary'
    }, {
      name: 'twitter:description',
      content: 'Asegura tu futuro con el ahorro obligatorio para pensión en Plan Vital. Gestión eficiente de tu AFP ' +
        'para una jubilación tranquila.'
    }, {
      name: 'twitter:title',
      content: 'Ahorro Previsional Obligatorio: Cotización Pensión | AFP PlanVital'
    }]
  },
  {
    url: 'afiliado/asesoria/informacion-financiera/valor-cuota',
    title: 'Valor Cuota AFP | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Consulta el valor cuota AFP con Plan Vital. Mantente informado sobre tus inversiones y asegura una mejor ' +
        'gestión de tus fondos. ¡Revisa ahora!'
    }, {
      name: 'abstract',
      content: 'Consulta el valor cuota AFP con Plan Vital. Mantente informado sobre tus inversiones y asegura una mejor ' +
        'gestión de tus fondos. ¡Revisa ahora!'
    }, {
      name: 'keywords',
      content: 'valor cuota afp'
    }, {
      property: 'og:title',
      content: 'Valor Cuota AFP | AFP PlanVital'
    }, {
      property: 'og:description',
      content: 'Consulta el valor cuota AFP con Plan Vital. Mantente informado sobre tus inversiones y asegura una mejor ' +
        'gestión de tus fondos. ¡Revisa ahora!'
    }, {
      property: 'og:url',
      content: 'https://www.planvital.cl/afiliado/ahorros/obligatorio'
    }, {
      name: 'twitter:card',
      content: 'summary'
    }, {
      name: 'twitter:description',
      content: 'Consulta el valor cuota AFP con Plan Vital. Mantente informado sobre tus inversiones y asegura una mejor ' +
        'gestión de tus fondos. ¡Revisa ahora!'
    }, {
      name: 'twitter:title',
      content: 'Valor Cuota AFP | AFP PlanVital'
    }]
  },
  {
    url: 'afiliado/traspaso/por-que-cambiarme',
    title: 'Cambio de AFP Rápido y Fácil ¡Cámbiate! | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Cámbiate de AFP rápido y fácil con Plan Vital. Disfruta de beneficios únicos y una transición ' +
        'sin complicaciones. ¡Haz el cambio hoy!'
    }, {
      name: 'keywords',
      content: 'cambio de afp'
    }]
  },
  {
    url: 'afiliado/traspaso/estado',
    title: 'Estatus de Cambio y Traspaso de AFP | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Revisa el estatus de tu solicitud de cambio de AFP con Plan Vital. Información actualizada y fácil acceso ' +
        'para tu tranquilidad. ¡Consulta ahora!'
    }, {
      name: 'keywords',
      content: 'estatus de cambio de afp, traspaso plan vital, traspaso afp'
    }]
  },
  {
    url: 'afiliado/asesoria/centro-tramites',
    title: 'Solicitud AFP: Pensión de alimentos y más | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Gestiona todos tus trámites AFP de manera fácil y rápida en el Centro de Trámites de Plan Vital. ' +
        '¡Simplifica tu proceso hoy!'
    }, {
      name: 'keywords',
      content: 'solicitudd afp, solicitud pension de alimentos'
    }]
  },
  {
    url: 'afiliado/asesoria/centro-tramites/cai/formulario/0',
    title: 'Solicitud Retiro Cuenta de Ahorro de Indemnización (CAI) | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Solicita el retiro de tu Cuenta de Ahorro de Indemnización (CAI) con AFP Plan Vital. ' +
        'Proceso rápido y seguro. ¡Hazlo ahora!'
    }, {
      name: 'keywords',
      content: 'retiro cai, solicitud cai, solicitud retiro cuenta de ahorro de indemnizacion'
    }]
  },
  {
    url: 'afiliado/asesoria/centro-tramites/enfermos-terminales/inicio',
    title: 'Anticipar Jubilación: Pensión por Enfermedad Terminal | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Para acceder a este beneficio debes tener 10 años de afiliación en cualquier sistema previsional y ' +
        'recibir una pensión del 70% o más. ¡Conoce los requisitos!'
    }, {
      name: 'keywords',
      content: 'anticipar jubilacion, pension anticipada, jubilacion anticipada por enfermedad, jubilacion anticipada requisitos, ' +
        'pension anticipada por enfermedad terminal'
    }]
  },
  {
    url: 'afiliado/asesoria/certificados/obtener',
    title: 'Certificados AFP: Afiliación, Cotizaciones y más | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Obten tu certificado histórico de cotizaciones y afiliación AFP con Plan Vital. Accede facilmente a tu historial. ' +
        '¡Solicitalo ahora!'
    }, {
      name: 'keywords',
      content: 'certificado de afiliacion afp, certificado afilicion afp, certificado de cotizaciones af, certificado afp, ' +
        'certificado de afp, certificado historico de cotizaciones afp, certificado de cotizaciones afp, certificados afp'
    }]
  },
  {
    url: 'asesoria/certificados/validar',
    title: 'Validación Certificados ¡Simples Pasos! | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Valida tus certificados AFP PlanVital en simples pasos. Proceso rápido y seguro para asegurar la autenticidad ' +
        'de tus documentos. ¡Hazlo ya!'
    }, {
      name: 'keywords',
      content: 'validacion afp plan vital, validar cerficados, validacion certificados'
    }]
  },
  {
    url: 'asesoria/consulta-solicitudes',
    title: 'Estado de Solicitudes: Detalle de Trámites | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Consulta el estado de tus solicitudes y el detalle de trámites en AFP PlanVital. ' +
        'Mantente informado y gestiona tus procesos fácilmente. ¡Accede ahora!'
    }, {
      name: 'keywords',
      content: 'solicitudes afp, estado solicitud afp'
    }]
  },
  {
    url: 'afiliado/asesoria/futuro-pensionado/modalidad-pension',
    title: 'Modalidades de Pensiones: Retiro Programado y más | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Descubre las modalidades de pensiones en AFP PlanVital: Retiro programado y más opciones para asegurar ' +
        'tu futuro. Eliger la mejor para ti. ¡Infórmate!'
    }, {
      name: 'keywords',
      content: 'modalidades de pensiones, retiro programado, retiro programado afp'
    }]
  },
  {
    url: 'afiliado/asesoria/futuro-pensionado/ofertas-pension',
    title: '¿SCOMP Qué es? | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Descubre qué es SCOMP con AFP PlanVital. Sistema que compara opciones de pensión para ayudarte a tomar ' +
        'la mejor decisión. ¡Infórmate ahora!'
    }, {
      name: 'keywords',
      content: 'scomp, scom afp, scomp que es'
    }]
  },
  {
    url: 'afiliado/asesoria/futuro-pensionado/tipos-pension',
    title: 'Tipos de Pensiones y Trámites de Pensión  | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Conoce los tipos de pensión en Chile con Plan Vital. Encuentra la opción ideal para tu jubilación y asegura ' +
        'tu futuro. ¿Infórmate aquí!'
    }, {
      name: 'keywords',
      content: 'tipos de pension, tipos de pensiones, tramites de pension'
    }]
  },
  {
    url: 'afiliado/asesoria/informacion-financiera/cartera-inversiones',
    title: 'Cartera de Fondos de Inversiones | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Explora la cartera de fondos de inversiones de AFP PlanVital. Diversifica y optimiza tus rendimientos para un ' +
        'futuro seguro. ¡Conoce más aquí!'
    }, {
      name: 'keywords',
      content: 'cartera de inversiones, cartera de fondos de inversion'
    }]
  },
  {
    url: 'afiliado/asesoria/informacion-financiera/indicadores-economicos',
    title: 'Indicadores Económicos: UF - UTM - IPC | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Consulta indicadores económicos como UF, UTM e IPC en AFP PlanVital. Mantente al día con información ' +
        'clave para tus finanzas. ¡Infórmate aquí!'
    }, {
      name: 'keywords',
      content: 'indicadores economicos afp'
    }]
  },
  {
    url: 'afiliado/asesoria/informacion-financiera/variacion-rentabilidad',
    title: 'Rentabilidad Fondos AFP 2024 | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Descubre la rentabilidad de los fondos AFP 2024 con PlanVital. Maximiza tus inversiones y asegura ' +
        'un futuro financiero sólido. ¡Infórmate ahora!'
    }, {
      name: 'keywords',
      content: 'rentabilidad fondos, rentabilidad fondos afp, rentabilidad fondos afp 2024'
    }]
  },
  {
    url: 'afiliado/asesoria/multifondos',
    title: '¿Qué son los Multifondos? - Conoce el Sistema | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Descubre los multifondos AFP de Plan Vital. Diversifica tus inversión y optimiza tus rendimientos. ' +
        '¡Conoce nuestro sistema ahora!'
    }, {
      name: 'keywords',
      content: 'multifondos afp, multifondos, sistema multifondo afp, que son multifondos'
    }]
  },
  {
    url: 'afiliado/asesoria/pension-sobrevivencia',
    title: 'Solicitud de Pensión de Sobrevivencia | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Solicita tu pensión de sobrevivencia con AFP PlanVital. Proceso sencillo y seguro para proteger a tus seres queridos. ' +
        '¡Inicia tu pensión ahora!'
    }, {
      name: 'keywords',
      content: 'solicitud pension de sobrevivencia, pension de sobrevivencia, pension sobrevivencia, pension de sobrevivencia ' +
        'afp planvital'
    }]
  },
  {
    url: 'afiliado/asesoria/simuladores',
    title: 'Simulador: Calcular Pensión, Cuenta 2 y más | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Utiliza el simulador AFP de Plan Vital. Proyecta tu pensión y optimiza tus ahorros para un mejor futuro. ' +
        '¡Simula tu pensión ahora!'
    }, {
      name: 'keywords',
      content: 'simulador de pension, simulador cuenta 2, simulador de pensiones afp, calcular pension afp'
    }]
  },
  {
    url: 'afiliado/asesoria/simuladores/fondo-adecuado',
    title: 'Perfil de Inversionista en Fondos de Inversión | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Conoce tu perfil de inversionista en fondos de inversión con AFP PlanVital. Personaliza tus inversiones ' +
        'y optimiza tus rendimientos. ¡Infórmate aquí!'
    }, {
      name: 'keywords',
      content: 'perfil de inversionista, conoce perfil de inversionista, fondos de inversion, invertir en fondos de inversion'
    }]
  },
  {
    url: 'afiliado/informacion/retiro-fondos',
    title: 'Retiro 10% AFP: Respondemos Todas tus Preguntas | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Resuelve todas tus dudas sobre el retiro del 10% AFP con PlanVital. Obtén información clara y precisa ' +
        'para tomar decisiones informadas. ¡Infórmate ya!'
    }, {
      name: 'keywords',
      content: 'retiro 10, retiro 10 planvital, retiro afp planvital, retiro 10 afp'
    }]
  },
  {
    url: 'asesoria/garantia-estatal',
    title: 'Solicitud Garantía Estatal Pensiones | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Solicita la garantía estatal de pensiones con AFP PlanVital. Asegura tu futuro con respaldo del estado. ' +
        'Proceso fácil y rápido. ¡Solicítalo ya!'
    }, {
      name: 'keywords',
      content: 'garantia estatal, garantia estatal pensiones'
    }]
  },
  {
    url: 'asesoria/solicitud-asignacion-familiar',
    title: 'Formulario de Solicitud de Asignación Familiar | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Solicita la asignación familiar con AFP PlanVital. Rellena el formulario fácilmente y accede a tus beneficios. ' +
        '¡Simplifica tu trámite hoy!'
    }, {
      name: 'keywords',
      content: 'asignacion familiar, pago asignacion familiar, solicitud asignacion familiar, formulario de solicitud de ' +
        'asignacion familiar'
    }]
  },
  {
    url: 'asesoria/solicitud-cuota-mortuoria',
    title: 'Solicitud Cuota Mortuoria: Asignación por Muerte | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Solicita la cuota mortuoria con AFP PlanVital. Accede a la asignación por muerte fácilmente y asegura ' +
        'el apoyo financiero necesario. ¡Solicítala ya!'
    }, {
      name: 'keywords',
      content: 'cuota mortuoria, cuota mortuoria afp, asignacion por muerte, solicitud cuota mortuoria'
    }]
  },
  {
    url: 'asesoria/solicitud-herencia',
    title: 'Solicitud Herencia: Posesión Efectiva | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Solicita la herencia y posesión efectiva con AFP PlanVital. Proceso rápido y seguro para asegurar los derechos ' +
        'de tus herederos. ¡Solicítalo ahora!'
    }, {
      name: 'keywords',
      content: 'herencia, posesion efectiva de herencia, herencia afp'
    }]
  },
  {
    url: 'corporacion/informacion-relevante/estados-financieros',
    title: 'Estados Financieros: Fechas de Publicación | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Estados Financieros de AFP PlanVital S.A. al 31 de marzo 2024 y sus notas. Estados Financieros Trimestrales de ' +
        'la Administradora. 31 de marzo 2024.'
    }, {
      name: 'keywords',
      content: 'estados financieros afp plan vital'
    }]
  },
  {
    url: 'corporacion/informacion-relevante/hechos-esenciales',
    title: 'Hechos Esenciales de Nuestra Empresa | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Descubre los hechos esenciales de AFP PlanVital. Transparencia y confianza en la gestión de tus fondos de pensión. ' +
        '¡Conoce más detalles aquí!'
    }, {
      name: 'keywords',
      content: 'hechos esenciales afp plan vital'
    }]
  },
  {
    url: 'corporacion/informacion-relevante/mapa-del-sitio',
    title: 'Mapa del Sitio Web | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Encuentra todo lo que necesitas fácilmente con el mapa del sitio web de AFP PlanVital. Navegación simple y rápida. ' +
        '¡Accede y explora ahora!'
    }, {
      name: 'keywords',
      content: 'mapa del sitio plan vital'
    }]
  },
  {
    url: 'corporacion/informacion-relevante/memoria-anual',
    title: 'Memoria Anual de Nuestra Empresa | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Descubre el impacto y los logros de AFP PlanVital en nuestra Memoria Anual. Innovación, crecimiento y resultados 2023. ' +
        '¡Infórmate ahora!'
    }, {
      name: 'keywords',
      content: 'memoria anual plan vital'
    }]
  },
  {
    url: 'corporacion/politicas-y-estatutos/gobierno-corporativo',
    title: 'Gobierno Corporativo de Nuestra Empresa | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Conoce el Gobierno Corporativo de AFP PlanVital: estructura, ética y liderazgo que impulsan nuestra gestión y ' +
        'transparencia. Más detalles aquí.'
    }, {
      name: 'keywords',
      content: 'gobierno corporativo plan vital'
    }]
  },
  {
    url: 'corporacion/politicas-y-estatutos/politicas-afp',
    title: 'Nuestras Políticas y Estatutos | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Descubre las políticas de AFP PlanVital. Transparencia y compromiso en nuestra gestión. Lee más sobre nuestras ' +
        'normas y directrices.'
    }, {
      name: 'keywords',
      content: 'politicas de la afp plan vital, estatus de la afp plan vital'
    }]
  },
  {
    url: 'corporacion/quienes_somos/administracion_y_directorio',
    title: 'Conoce Nuestra Administración | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Explora la administración de AFP PlanVital: equipo, estrategias y liderazgo que guían nuestro éxito y servicio. ' +
        'Conoce más sobre nuestra gestión.'
    }, {
      name: 'keywords',
      content: 'administracion de la afp plan vital'
    }]
  },
  {
    url: 'corporacion/quienes-somos/nuestra-compania',
    title: '¿Qué es Plan Vital? ¡Conócenos! | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Descubre qué es Plan Vital y como te beneficia. Conoce nuestros servicios y valores en AFP PlanVital. ' +
        '¡Infórmate y elige tu mejor opción!'
    }, {
      name: 'keywords',
      content: 'que es plan vital, afp plan vital que es'
    }]
  },
  {
    url: 'empleador/informacion/asignacion-familiar',
    title: 'Fechas de Pago y Como Saber Si Tengo Asignación Familiar| AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Consulta las fechas de pago y verifica si tienes asignación familiar con AFP PlanVital. ' +
        'Información clara y precisa para tus beneficios. ¡Infórmate ya!'
    }, {
      name: 'keywords',
      content: 'fecha de pago asignacion familiar, cómo saber si tengo asignacion familiar'
    }]
  },
  {
    url: 'empleador/informacion/renta-tope',
    title: 'Fechas de Pago y Como Saber Si Tengo Asignación Familiar| AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Consulta las fechas de pago y verifica si tienes asignación familiar con AFP PlanVital. ' +
        'Información clara y precisa para tus beneficios. ¡Infórmate ya!'
    }, {
      name: 'keywords',
      content: 'fecha de pago asignacion familiar, como saber si tengo asignacion familiar'
    }]
  },
  {
    url: 'empleador/informacion/subsidio-trabajador-joven',
    title: 'Bono Empleo Joven | Subsidio a la Cotización | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Descubre el Bono Empleo Joven de AFP PlanVital: subsidio a la cotización para potenciar tu empleo. ' +
        'Infórmate sobre los beneficio y requisitos aquí.'
    }, {
      name: 'keywords',
      content: 'bono empleo joven, subsidio al empleo joven, subsidio a la cotizacion de trabajadores jovenes'
    }]
  },
  {
    url: 'empleador/informacion/tipos-trabajadores',
    title: 'Tipos de Afiliación y Trabajadores | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Conoce los tipos de afiliación y categorías de trabajadores en AFP PlanVital. Descubre cuá se adapta a tus ' +
        'necesidades y cómo afiliarte.'
    }, {
      name: 'keywords',
      content: 'tipo de trabajador, tipo de afiliacion'
    }]
  },
  {
    url: 'empleador/pago-cotizaciones/comisiones',
    title: 'Comisiones AFP Vigentes | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Consulta las comisiones vigentes de AFP PlanVital. Conoces las tarifas actuales y cómo impactan en tu ahorro y ' +
        'planificación financiera.'
    }, {
      name: 'keywords',
      content: 'comisiones afp, comisiones de afp, comisiones vigentes afp, comision planvital'
    }]
  },
  {
    url: 'empleador/inicio',
    title: 'Empleadores | Pagos y Certificados de Cotizaciones | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Empleadores, facilitamos la gestión para obtener los certificados de pagos de cotizaciones AFP con Plan Vital. ' +
        '¡Solicítalos ya!'
    }, {
      name: 'keywords',
      content: 'afp empleador, plan vital empleador'
    }]
  },
  {
    url: 'empleador/pago-cotizaciones/planillas',
    title: 'Planilla de Pago de Cotizaciones Previsionales | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Gestiona tus cotizaciones previsionales con la planilla de pago de AFP PlanVital. ' +
      'Simplifica tus trámites y mantente al día. ¡Descarga la planilla ya!'
    }, {
      name: 'keywords',
      content: 'planilla de pago de cotizaciones previsionales, planilla de cotizaciones previsionales'
    }]
  },
  {
    url: 'empleador/servicios/clave',
    title: '¿Cómo Solicitar Clave AFP? | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Accede a la planilla de pago de cotizaciones previsionales en AFP PlanVital. Facilita tu gestión ' +
        'con nuestra guía completa y actualizada.'
    }, {
      name: 'keywords',
      content: 'solicitar clve afp planvital, como solicitar clave afp'
    }]
  },
  {
    url: 'empleador/servicios/depositos-convenidos',
    title: 'Depósitos Convenidos ¿Qué es? | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Descubre qué son los depósitos convenidos en AFP PlanVital y cómo pueden optimizar tus aportes ' +
        'previsionales. ¡Infórmate y aprovecha esta opción!'
    }, {
      name: 'keywords',
      content: 'depositos convenidos, que es un deposito convenido'
    }]
  },
  {
    url: 'empleador/servicios/modalidades-pago',
    title: 'Pago de Cotizaciones Previsionales Previred | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Facilita el pago de tus cotizaciones previsionales con Previred a través de AFP PlanVital. Aprende cómo ' +
        'gestionar tus aportes de manera eficiente.'
    }, {
      name: 'keywords',
      content: 'cotizaciones previsionales, pago de cotizaciones, pago de cotizaciones previred'
    }]
  },
  {
    url: 'empleador/servicios/pago-exceso',
    title: 'Devolución de Pago en Exceso AFP | AFP PlanVital',
    metas: [{
      name: 'description',
      content: '¿Pagaste de más en AFP PlanVital? Descubre cómo solicitar la devolución de pagos en exceso y optimiza tus aportes ' +
        'previsionales fácilmente.'
    }, {
      name: 'keywords',
      content: 'devolucion de pago en exceso, devolucion de excesos afp, formulario de solicitud de devolucion de pagos en exceso'
    }]
  },
  {
    url: 'pensionado/asesoria/centro-tramites',
    title: 'Trámites para Pensionados | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Descubre los trámites para pensionados en AFP PlanVital. Guía completa para gestionar tu pensión con facilidad ' +
        'y eficiencia. Infórmate aquí.'
    }, {
      name: 'keywords',
      content: 'tramites para pensionados'
    }]
  },
  {
    url: 'pensionado/beneficios/otros',
    title: 'Beneficios para Pensionados | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Descubre los beneficios exclusivos para pensionados de AFP PlanVital. Accede a servicios y ventajas diseñados ' +
        'para tu bienestar y tranquilidad.'
    }, {
      name: 'keywords',
      content: 'beneficios pensionados afp, beneficios para pensionados, beneficios pensionados'
    }]
  },
  {
    url: 'pensionado/beneficios/pension-solidaria',
    title: 'Pensión Solidaria | Aporte Previsional Solidario | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Conoce la Pensión Solidaria y el Aporte Previsional Solidario en AFP PlanVital. Beneficios que fortalecen ' +
        'tu seguridad social y apoyo financiero.'
    }, {
      name: 'keywords',
      content: 'pension solidaria, aporte previsional solidario'
    }]
  },
  {
    url: 'pensionado/pago-pension/calendario',
    title: 'Fechas de Pagos de Pensionados | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Pensionados, consulten fechas de pago y liquidación con Plan Vital. Manténganse informados y gestiones sus beneficios ' +
        'fácilmente. ¡Infórmense aquí!'
    }, {
      name: 'keywords',
      content: 'fechas de pagos de pensionados, pago de pensiones, fecha pago pension'
    }]
  },
  {
    url: 'pensionado/pago-pension/modalidad-pension',
    title: 'Modalidades de Pensiones: Retiro Programado y más | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Descubre las modalidades de pensiones en AFP PlanVital: Retiro programado y más opciones para asegurar ' +
        'tu futuro. Eliger la mejor para ti. ¡Infórmate!'
    }, {
      name: 'keywords',
      content: 'modalidades de pensiones, retiro programado, retiro programado afp'
    }]
  },
  {
    url: 'pensionado/pago-pension/ofertas-pension',
    title: '¿SCOMP Qué es? | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Descubre qué es SCOMP con AFP PlanVital. Sistema que compara opciones de pensión para ayudarte a tomar ' +
        'la mejor decisión. ¡Infórmate ahora!'
    }, {
      name: 'keywords',
      content: 'scomp, scom afp, scomp que es'
    }]
  },
  {
    url: 'pensionado/pago-pension/tipos-pension',
    title: 'Tipos de Pensión en Chile | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Explora los tipos de pensión en Chile con AFP PlanVital. Conoce las opciones disponibles y elige la mejor para tu ' +
        'futuro financiero.'
    }, {
      name: 'keywords',
      content: 'tipos de pension en chile, tipos de pension'
    }]
  },
  {
    url: 'preguntas_frecuentes',
    title: 'Preguntas Frecuentes para Afiliados, Pensionados y Empleadores | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Resuelve tus dudas con las preguntas frecuentes para afiliados, pensionados y empleadores de AFP PlanVital. ' +
      'Encuentra respuestas rápidas y precisas aquí.'
    }, {
      name: 'keywords',
      content: 'preguntas frecuentas para afiliados, preguntas frecuentes afiliados'
    }]
  },
  {
    url: 'preguntas-frecuentes/pensionado',
    title: 'Preguntas Frecuentes sobre Pensiones | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Encuentra respuestas a tus dudas sobre pensiones con las preguntas frecuentes de AFP PlanVital. ' +
        'Información clara y precisa al alcance de tu mano.'
    }, {
      name: 'keywords',
      content: 'preguntas frecuentas para pensiones, preguntas frecuentes sobre pensiones'
    }]
  },
  {
    url: 'preguntas-frecuentes/empleador',
    title: 'Preguntas Frecuentes para Empleadores | AFP PlanVital',
    metas: [{
      name: 'description',
      content: 'Consulta las preguntas frecuentes para empleadores en AFP PlanVital. Resuelve tus dudas sobre aportes, ' +
        'afiliaciones, y más de manera rápida y fácil.'
    }, {
      name: 'keywords',
      content: 'preguntas frecuentas para empleadores, preguntas frecuentes empleador'
    }]
  },
];

export const DEFAULT_METATAG: MetaTag = {
  url: '.',
  title: 'Cámbiate a AFP PlanVital, una compañía Generali',
  metas: [{
    name: 'description',
    content: 'Somos líderes en rentabilidad, tenemos la mejor comisión APV y Cuenta 2 para hacer ' +
      'crecer tus ahorros, no cobramos comisión a nuestros pensionados.'
  }, {
    name: 'abstract',
    content: 'Somos líderes en rentabilidad, tenemos la mejor comisión APV y Cuenta 2 para hacer ' +
      'crecer tus ahorros, no cobramos comisión a nuestros pensionados.'
  }, {
    name: 'keywords',
    content: 'afp planvital, planvital, afp, rentabilidad, ahorro, certificados afp, administradora de fondos de pensiones'
  }, {
    property: 'og:title',
    content: `Cámbiate a AFP PlanVital, una compañía Generali`
  }, {
    property: 'og:description',
    content: 'Somos líderes en rentabilidad, tenemos la mejor comisión APV y Cuenta 2 para hacer ' +
      'crecer tus ahorros, no cobramos comisión a nuestros pensionados.'
  }, {
    property: 'og:url',
    content: 'https://www.planvital.cl/'
  }, {
    name: 'twitter:card',
    content: ''
  }, {
    name: 'twitter:description',
    content: 'Somos líderes en rentabilidad, tenemos la mejor comisión APV y Cuenta 2 para hacer ' +
      'crecer tus ahorros, no cobramos comisión a nuestros pensionados.'
  }, {
    name: 'twitter:title',
    content: `Cámbiate a AFP PlanVital, una compañía Generali`
  }]
};
