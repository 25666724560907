import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PENSION_TYPES_CONTENT } from '@constants/pages-content/pension-types.constant';
import { PensionTypes } from '@interfaces/future-pensioner.interface';
import { FontService } from '@providers/font/font.service';
import { LanguageProvider } from '@providers/language/language.service';
import { CmsService } from '@services/cms/cms.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-pension-types-component',
  templateUrl: './pension-types.component.html',
  styleUrls: ['./pension-types.component.scss'],
})
export class PensionTypesComponent {
  @Input() cardPage;
  @Input() navigate;
  public pensionTypes: PensionTypes = PENSION_TYPES_CONTENT;
  public loading = false;

  constructor(
    protected cmsService: CmsService,
    public font: FontService,
    public languageProvider: LanguageProvider,
    public router: Router,
  ) {
    this.loadContent();
   }

  public goPaperworkFollowUp(): void {
    this.router.navigate([this.navigate]);
  }

  private loadContent(): void {
    this.loading = true;
    firstValueFrom(this.cmsService.loadPensionTypesContent()).then(response => {
      this.pensionTypes = response;
    }).finally(() => this.loading = false);
  }

}
