import { Mockup } from '@interfaces/mockup.interface';

// tslint:disable:max-line-length
export const GET_ADMINISTRATION_WORKERS: Mockup = {
  success: [{
    response: {
      data:{
         titulo: 'Administración',
         descripcion: 'Conoce el Directorio de AFP PlanVital',
         titulo_directorio: 'Directorio',
         titulo_ejecutivos: 'Ejecutivos',
         directorio:[
            {
               administracion_imagenes_id:{
                  nombre: 'Mario García Arias',
                  cargo: 'Presidente Directorio',
                  imagen:{
                     filename_disk: 'a465e89e-e778-41ae-8703-1ac558c84e7b.webp'
                  }
               }
            },
            {
               administracion_imagenes_id:{
                  nombre: 'Oscar Spoerer Varela',
                  cargo: 'Vicepresidente Directorio',
                  imagen:{
                     filename_disk: '72410d8a-a3d2-4ce2-b414-d073c2ad2516.webp'
                  }
               }
            },
            {
               administracion_imagenes_id:{
                  nombre: 'Cristián Pizarro Goicoechea',
                  cargo: 'Director',
                  imagen:{
                     filename_disk: 'b357d899-e71a-49ed-bbe5-cdb6f1c232e8.webp'
                  }
               }
            },
            {
               administracion_imagenes_id:{
                  nombre: 'Javier Marin Estevez',
                  cargo: 'Director',
                  imagen:{
                     filename_disk: '790ad804-def7-4f9e-9d87-5069a3d8aabb.webp'
                  }
               }
            },
            {
               administracion_imagenes_id:{
                  nombre: 'Simone Anconitano',
                  cargo: 'Director',
                  imagen:{
                     filename_disk: 'e8a48e59-521d-450d-b342-7464e723bc9f.webp'
                  }
               }
            },
            {
               administracion_imagenes_id:{
                  nombre: 'Antonio Da Silva',
                  cargo: 'Director',
                  imagen:{
                     filename_disk: 'fb3bcb95-b05b-411f-b40f-39d5583a14e8.webp'
                  }
               }
            },
            {
               administracion_imagenes_id:{
                  nombre: 'Claudia Miraglia',
                  cargo: 'Director',
                  imagen:{
                     filename_disk: '4ec30de5-e15e-469a-badb-7495daecf85c.webp'
                  }
               }
            },
            {
               administracion_imagenes_id:{
                  nombre: 'Jeffrey Gómez',
                  cargo: 'Director',
                  imagen:{
                     filename_disk: 'b0e5f887-76b5-42ba-81d9-849d824d282c.webp'
                  }
               }
            },
            {
               administracion_imagenes_id:{
                  nombre: 'Jorge Covarrubias Serrano',
                  cargo: 'Director suplente',
                  imagen:{
                     filename_disk: 'e15471d2-45c8-4155-aa24-9a1b833a3d57.webp'
                  }
               }
            },
            {
               administracion_imagenes_id:{
                  nombre: 'Estefanía Planas González',
                  cargo: 'Director suplente',
                  imagen:{
                     filename_disk: 'ead93f9a-717a-4575-9e39-665b31e5fdc5.webp'
                  }
               }
            }
         ],
         ejecutivos:[
            {
               administracion_imagenes_id:{
                  nombre: 'José Joaquín Prat',
                  cargo: 'Chief Executive Officer',
                  imagen:{
                     filename_disk: '2ce80d41-92c4-4b9e-ae57-92cd21ff2134.webp'
                  }
               }
            },
            {
               administracion_imagenes_id:{
                  nombre: 'María Isabel Jaramillo',
                  cargo: 'Chief Customer and Sales Officer',
                  imagen:{
                     filename_disk: '06dbb953-c989-429c-a315-0a67a1922801.webp'
                  }
               }
            },
            {
               administracion_imagenes_id:{
                  nombre: 'Juan Antonio García',
                  cargo: 'Chief People and Organization Officer',
                  imagen:{
                     filename_disk: 'c29cbd82-7e53-4fdb-8930-c11605cf1b4b.webp'
                  }
               }
            },
            {
               administracion_imagenes_id:{
                  nombre: 'Guillermo Matte',
                  cargo: 'General Counsel',
                  imagen:{
                     filename_disk: 'e247b75d-74b2-470b-8668-43b56e14ccdd.webp'
                  }
               }
            },
            {
               administracion_imagenes_id:{
                  nombre: 'Juan Esteban Pérez',
                  cargo: 'Chief of Internal Audit',
                  imagen:{
                     filename_disk: '768f7123-4223-4ce8-8f74-0e721ee50d88.webp'
                  }
               }
            },
            {
               administracion_imagenes_id:{
                  nombre: 'Diego Dziekonski',
                  cargo: 'Chief Investments Officer',
                  imagen:{
                     filename_disk: '819b0add-3602-481a-bcb6-b01deca0ec9c.webp'
                  }
               }
            },
            {
               administracion_imagenes_id:{
                  nombre: 'Miguel Silva',
                  cargo: 'Chief Financial Officer',
                  imagen:{
                     filename_disk: '40163c79-f641-4f0d-8e97-7e35c24d76ad.webp'
                  }
               }
            },
            {
               administracion_imagenes_id:{
                  nombre: 'Marcela Aguirre',
                  cargo: 'Chief Risk Officer',
                  imagen:{
                     filename_disk: '4abc69fb-8017-48d6-a7bb-12a70bb44bc5.webp'
                  }
               }
            },
            {
               administracion_imagenes_id:{
                  nombre: 'Nerea Ollo',
                  cargo: 'Chief Operating Officer',
                  imagen:{
                     filename_disk: 'ccb43e19-416e-4efc-9078-1cc63f979722.webp'
                  }
               }
            },
            {
               administracion_imagenes_id:{
                  nombre: 'María José Muñoz',
                  cargo: 'Head of Compliance',
                  imagen:{
                     filename_disk: '2db847c7-d136-4fd6-8a44-b8d01a3dbd05.webp'
                  }
               }
            },
            {
               administracion_imagenes_id:{
                  nombre: 'Daniela Astoreca',
                  cargo: 'Head of Communications and Sustainability',
                  imagen:{
                     filename_disk: '1eeecaeb-1914-4405-a1d1-804cb33f993a.webp'
                  }
               }
            }
         ]
      }
   }
  }],
  failures: [{
    response: {}
  }]
};
