import { Component, OnInit } from '@angular/core';
import { INVESTORS_BREADCRUMBS } from '@constants/pages-content/corporation.constant';
import {
  INFORMATION_OF_INTEREST_CONTENT
} from '@constants/pages-content/information-of-interest.constant';
import { RELEVANT_INFO_TITLES } from '@constants/titles.constant';
import { DownloadContent } from '@interfaces/general.interface';
import { FontService } from '@providers/font/font.service';
import { LanguageProvider } from '@providers/language/language.service';
import { CmsService } from '@services/cms/cms.service';

@Component({
  selector: 'app-information-of-interest',
  templateUrl: './information-of-interest.page.html',
  styleUrls: ['./information-of-interest.page.scss'],
})
export class InformationOfInterestPage implements OnInit {
  public pageContent = INFORMATION_OF_INTEREST_CONTENT;
  public listContent: Array<DownloadContent>;
  public breadcrumb = [...INVESTORS_BREADCRUMBS, RELEVANT_INFO_TITLES.content.informationOfInterest];
  public loading = false;
  constructor(
    public font: FontService,
    private cmsService: CmsService,
    private languageProvider: LanguageProvider,
  ) { }

  public ngOnInit(): void {
    this.loadData();
    this.languageSubscription();
  }

  private loadData(): void {
    this.loading = true;
    this.cmsService.loadInformationOfInterestValues().toPromise()
      .then((response) => {
        this.pageContent.content = response.downloadContent;
        this.pageContent.header = response.header;
      })
      .finally(() => this.loading = false);
  }

  private languageSubscription(): void {
    this.languageProvider.language.subscribe(() => this.loadData());
  }
}
