<div class="title-container">
  <div>
    <h1 *ngIf="!loading" class="title" [ngClass]="font?.size">{{ pensionTypes?.title | translate }}</h1>
    <ion-skeleton-text animated class="skeleton-line title large" *ngIf="loading"></ion-skeleton-text>
    <p *ngIf="!loading" class="subtitle dynamic-text secondary" [ngClass]="font?.size" [innerHTML]="pensionTypes?.description | translate"></p>
    <ion-skeleton-text animated class="skeleton-line large" *ngIf="loading"></ion-skeleton-text>
    <ion-skeleton-text animated class="skeleton-line large" *ngIf="loading"></ion-skeleton-text>
  </div>
  <ion-button *ngIf="!loading" (click)="goPaperworkFollowUp()" class="button primary main">{{ pensionTypes?.goTo | translate }}</ion-button>
</div>
<div class="info-card-container">
  <app-info-card
    class="info-card primary"
    alignment="center"
    [card]="card"
    [page]="cardPage"
    [loading]="loading"
    *ngFor="let card of pensionTypes?.moreInfoCards">
  </app-info-card>
</div>
<app-disclaimer-commision></app-disclaimer-commision>
