<div class="title-container">
  <h1 *ngIf="!loading" class="title" [ngClass]="font?.size">{{ pensionOffers?.title | translate }}</h1>
  <ion-skeleton-text *ngIf="loading" animated class="skeleton-line title large"></ion-skeleton-text>
  <p *ngIf="!loading" class="subtitle dynamic-text secondary" [ngClass]="font?.size" [innerHTML]="pensionOffers?.description | translate"></p>
  <ion-skeleton-text *ngIf="loading" animated class="skeleton-line small"></ion-skeleton-text>
  <ion-skeleton-text *ngIf="loading" animated class="skeleton-line small"></ion-skeleton-text>
  <ion-skeleton-text *ngIf="loading" animated class="skeleton-line small"></ion-skeleton-text>
  <ion-skeleton-text *ngIf="loading" animated class="skeleton-line small"></ion-skeleton-text>
</div>

<div class="process-timeline-container">
  <h2 *ngIf="!loading" class="title title-offers" [ngClass]="font?.size">{{ pensionOffers?.processTimeline.title | translate }}</h2>
  <ion-skeleton-text *ngIf="loading" animated class="skeleton-line title large"></ion-skeleton-text>
  <div *ngIf="!loading" class="timeline-container">
    <app-steps [steps]="pensionOffers?.processTimeline.steps"></app-steps>
  </div>
</div>

<div class="offers-disclaimers-container">
  <h2 *ngIf="!loading" class="title title-offers" [ngClass]="font?.size">{{ pensionOffers?.disclaimers.title | translate }}</h2>
  <ion-skeleton-text *ngIf="loading" animated class="skeleton-line title large"></ion-skeleton-text>
  <div *ngIf="!loading" class="disclaimers-container">
    <div class="disclaimer" *ngFor="let section of pensionOffers?.disclaimers.sections">
      <h2 class="title tertiary" [ngClass]="font?.size">{{ section?.title | translate }}</h2>
      <p class="dynamic-text tertiary" [ngClass]="font?.size" [innerHTML]="section?.description | translate"></p>
    </div>
  </div>
  <ion-skeleton-text *ngIf="loading" animated class="skeleton-line small"></ion-skeleton-text>
  <ion-skeleton-text *ngIf="loading" animated class="skeleton-line small"></ion-skeleton-text>
  <ion-skeleton-text *ngIf="loading" animated class="skeleton-line small"></ion-skeleton-text>
  <ion-skeleton-text *ngIf="loading" animated class="skeleton-line small"></ion-skeleton-text>  
</div>

<app-disclaimer-commision></app-disclaimer-commision>
