import { Mockup } from '@interfaces/mockup.interface';


export const GET_SCOMP_RESPONSE_MOCK: Mockup = {
    success: [{
        response: {
            data: {
                id: 1,
                titulo: '¿SCOMP Qué es?',
                descripcion: '<p>SCOMP es un Sistema de Consultas y Ofertas de Montos de Pensi&oacute;n, al que podr&aacute;s optar cuando desees pensionarte, con el fin de recibir en forma clara y comparable, distintas alternativas de pensi&oacute;n tanto en la AFP como en Compa&ntilde;&iacute;as de Seguro.</p>',
                cronograma: [
                    {
                        titulo: 'Recepción de Certificado de Saldo',
                        link_info: 'Más información',
                        descripcion: '<p>Documento emitido por la AFP que informa el Saldo total disponible para pensionarse.</p>',
                        tiempo_proceso: '<p>35 d&iacute;as corridos</p>'
                    },
                    {
                        titulo: 'Ingreso SCOMP',
                        link_info: 'Más información',
                        descripcion: '<p>Obligatorio para afiliados y beneficiarios cuya pensi&oacute;n es mayor a la Pensi&oacute;n B&aacute;sica con el fin de seleccionar una Modalidad de Pensi&oacute;n.</p>',
                        tiempo_proceso: '<p>8 d&iacute;as h&aacute;biles</p>'
                    },
                    {
                        titulo: 'Certificado de Ofertas',
                        link_info: 'Más información',
                        descripcion: '<p>Se indican las ofertas por Retiro Programado y Rentas Vitalicias, con distintas condiciones de cobertura y montos de pensi&oacute;n expresados en unidades de fomento (UF).</p>',
                        tiempo_proceso: '<p>Oferta interna 12 d&iacute;as h&aacute;biles<br>Oferta externa 15 d&iacute;as h&aacute;biles</p>'
                    },
                    {
                        titulo: 'Aceptación o Rechazo de la Oferta',
                        link_info: 'Más información',
                        descripcion: '<p>Con el Certificado de Saldo se Puede:<br>Aceptar alguna de las ofertas, Solicitar una oferta externa, realizar un remate o rechazar la oferta.</p>',
                        tiempo_proceso: null
                    },
                    {
                        titulo: 'Término del proceso',
                        link_info: 'Más información',
                        descripcion: '<p>Si elige una Renta Vitalicia o retiro programado en otra AFP, se traspasa la prima a la Compa&ntilde;&iacute;a de Seguros o traspaso de Saldo de su cuenta individual seg&uacute;n corresponda corresponda.<br>Si no elige una alternativa en el plazo de vigencia del Certificado de Saldo, el tr&aacute;mite de pensi&oacute;n queda sin efecto.</p>'
                    }
                ],
                titulo_cronograma: 'Cronograma del proceso',
                titulo_disclaimers: 'Aceptación o rechazo de la Solicitud de SCOMP',
                disclaimers: [
                    {
                        titulo: 'Aceptación de oferta',
                        descripcion: '<p>Elige una de las ofertas de pensi&oacute;n firmando la Selecci&oacute;n de Modalidad de Pensi&oacute;n adem&aacute;s de Aceptaci&oacute;n de la Oferta.</p>\n<p>Para pensiones de sobrevivencia, el formulario debe ser firmado por todos los beneficiarios de pensi&oacute;n.</p>'
                    },
                    {
                        titulo: 'Solicitud de Remate',
                        descripcion: '<p>No se elige ninguna de las ofertas recibidas, tanto interna y externas.<br>Se suscribe el formulario de Solicitud de remate, donde se invita a participar a la compa&ntilde;&iacute;as de seguros, donde al menos 3 de ellas hayan realizado una oferta previa.</p>\n<p>El remate se lo adjudicar&aacute; la compa&ntilde;&iacute;a que ofrezca el mayor monto de pensi&oacute;n.</p>\n<p>La aceptaci&oacute;n de la oferta es obligatoria si concurren al remate al menos 2 compa&ntilde;&iacute;as de seguro.</p>'
                    },
                    {
                        titulo: 'Oferta externa',
                        descripcion: '<p>Son aquellas ofertas de montos de pensi&oacute;n que se realizan por fuera del sistema, donde el monto de la pensi&oacute;n debe ser superior a la ofrecida en el Certificado de Oferta,</p>\n<p>Se debe respetar el mismo tipo de Renta Vitalicia y con las mismas coberturas.</p>'
                    },
                    {
                        titulo: 'Rechazo Oferta',
                        descripcion: '<p>No se elige ninguna de las ofertas recibidas y no es ingresa una solicitud de remate.<br>Tiene derecho a realizar 2 solicitudes ofertas m&aacute;s siempre y cuando el Certificado de Saldo se encuentre vigente.<br>Si el Certificado de Saldo vence, iniciar un nuevo tr&aacute;mite de pensi&oacute;n.</p>\n<p>Si el Certificado de Saldo vence, iniciar un nuevo tr&aacute;mite de pensi&oacute;n (Vejez Anticipada y Vejez Normal). En el caso de pensiones de Invalidez y Sobrevivencia de debe acoger a la modalidad de retiro programado.</p>'
                    }
                ]
            }
        }
    }],
    failures: []
};
