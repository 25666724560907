import { Mockup } from '@interfaces/mockup.interface';

// tslint:disable:max-line-length
export const GET_INFORMATION_OF_INTEREST_LINKS: Mockup = {
  success: [{
    response: {
      data: {
          secciones: [
              {
                  informacion_interes_secciones_id: {
                      titulo: 'Estatutos Sociales',
                      archivos: [
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Estatutos AFP PlanVital',
                                  archivo: {
                                      filename_disk: 'd25a002d-18d5-42c1-99c3-2f979cc84e5e.pdf'
                                  }
                              }
                          }
                      ]
                  }
              },
              {
                  informacion_interes_secciones_id: {
                      titulo: 'Repartos de Capital y/o Utilidades',
                      archivos: [
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo provisorio 2024',
                                  archivo: {
                                      filename_disk: '10a53d17-10da-4f48-bd1e-cd67eb3725d9.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo definitivo 2024',
                                  archivo: {
                                      filename_disk: '730407e2-6840-4efb-9844-043b84e7a894.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo provisorio 2023 (rectificación)',
                                  archivo: {
                                      filename_disk: '0c77676a-929f-48a1-aba8-3223c3c2671b.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo provisorio 2023',
                                  archivo: {
                                      filename_disk: '0fb96a40-1efe-411b-b0e7-3b61f8e76615.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo definitivo 2023',
                                  archivo: {
                                      filename_disk: '49422b6a-7436-451d-8a36-9658f0ceee83.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo provisorio 2022',
                                  archivo: {
                                      filename_disk: '3f4dfb25-ab53-48c2-ac72-073fbde09f53.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo definitivo adicional 2022',
                                  archivo: {
                                      filename_disk: '833816bf-6b4d-42c5-8cdb-410585174e0b.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo definitivo 2022',
                                  archivo: {
                                      filename_disk: '2ffd8462-0297-48ae-9f70-1021f0f06435.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo provisorio 2021',
                                  archivo: {
                                      filename_disk: '7032f7ba-97ec-40fd-881c-3d5eaf278059.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo definitivo adicional 2021',
                                  archivo: {
                                      filename_disk: '29448834-a5d0-463f-9fe0-560a2953d99c.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo definitivo 2021',
                                  archivo: {
                                      filename_disk: '663fcbbc-8bdd-40f9-813d-a6bb1e10845e.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo provisorio y dividendo definitivo adicional 2020',
                                  archivo: {
                                      filename_disk: '7905d133-cb81-4b08-8444-1ab47f9e497a.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo definitivo 2020',
                                  archivo: {
                                      filename_disk: '0474aed7-85aa-4420-baa4-a34ac1233c01.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo definitivo 2019',
                                  archivo: {
                                      filename_disk: 'af45d84d-971c-43b6-a762-8cdeaf7aac81.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo definitivo 2018',
                                  archivo: {
                                      filename_disk: 'f45b3742-ced0-48da-a9d4-6c23e1c1b539.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo definitivo 2017',
                                  archivo: {
                                      filename_disk: 'be613133-bbf9-41b0-ba7d-d57516cf519f.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo definitivo 2016',
                                  archivo: {
                                      filename_disk: '8901a93e-e678-4990-ac71-f0cfd9aa137b.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo definitivo 2014',
                                  archivo: {
                                      filename_disk: 'd7f03318-9601-4201-b74a-da50eaefa74a.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo definitivo 2013',
                                  archivo: {
                                      filename_disk: '5d026ae2-b144-4ce5-93ad-45373ba5f3f0.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo definitivo eventual 2012',
                                  archivo: {
                                      filename_disk: '90bdff78-2df4-4d73-910c-bdbd437519f8.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo definitivo adicional 2012',
                                  archivo: {
                                      filename_disk: '0b9b1da8-c79c-4adc-879a-2f5a6a4e4376.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo provisorio 2012',
                                  archivo: {
                                      filename_disk: 'aabe9473-53f3-49f6-ae51-cf3f16f5f427.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo definitivo adicional 2011',
                                  archivo: {
                                      filename_disk: '4a96422f-05b0-4c79-addd-51a490e8c536.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo provisorio 2011',
                                  archivo: {
                                      filename_disk: '56344225-06d1-4b57-a971-d98aa49b3d0b.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Dividendo definitivo 2010',
                                  archivo: {
                                      filename_disk: '7a4e87d5-dfb2-4d93-84a6-74033b468dd9.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Devolución de capital 2010',
                                  archivo: {
                                      filename_disk: '77be44da-acc0-4974-b256-0beffd74d462.pdf'
                                  }
                              }
                          }
                      ]
                  }
              },
              {
                  informacion_interes_secciones_id: {
                      titulo: 'Otros',
                      archivos: [
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Información de Enersis y Endesa',
                                  archivo: {
                                      filename_disk: '6f163364-d6f8-4355-af76-a587bea4768c.pdf'
                                  }
                              }
                          },
                          {
                              informacion_interes_archivos_id: {
                                  titulo: 'Caso sociedad Cascada SQM',
                                  archivo: {
                                      filename_disk: 'a73cb2c0-a0ef-4094-abe0-a9a04a3cbbae.pdf'
                                  }
                              }
                          }
                      ]
                  }
              }
          ]
      }
    }  
  }],
  failures: [{
    response: {}
  }]
};
