import { environment } from '@environment';
import { Home } from '@interfaces/home.interface';
import { Mockup } from '@interfaces/mockup.interface';

// tslint:disable:max-line-length
export const GET_HOME_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        id: 1,
        url_boton_articulos: 'educacion_financiera',
        url_boton_valores_cuota: 'valores_cuota',
        icono_accion_izquierda: 'notas',
        icono_accion_derecha: 'calendario',
        acciones_principales: [{
          id: 1,
          languages_code: 'es',
          home: 1,
          mensaje_izquierda: '<p>Recibe tu pensi&oacute;n sin salir de casa.</p>',
          icono_izquierda: 'notas',
          boton_izquierda: 'Inscribe tu cuenta',
          icono_derecha: 'calendario',
          mensaje_derecha: '<p>Revisa la fecha de tu pago de Pensi&oacute;n 2020</p>',
          boton_derecha: 'Revisar calendario'
        }],
        articulos: [
          {
            articulos_id: 1
          },
          {
            articulos_id: 2
          },
          {
            articulos_id: 3
          }
        ],
        mas_articulos: [
          {
            articulos_id: 1
          },
          {
            articulos_id: 2
          },
          {
            articulos_id: 3
          }
        ],
        banners: [
          {
            banners_id: 5
          }
        ],
        contenedor_articulos: [
          {
            id: 1,
            languages_code: 'es',
            home: 1,
            titulo: '¿Qué más necesitas?',
            subtitulo: 'Descubre toda nuestros productos, asesoría y herramientas financieras.',
            texto_inferior: '<p>Visita nuestra secci&oacute;n de <strong>educaci&oacute;n financiera</strong> para descubrir tu lado inversionista</p>',
            boton: 'Ir a educación financiera',
            mas_informacion: 'Más información'
          }
        ],
        login: [{
          id: 1,
          titulo: 'Ingresa a tu cuenta',
          rut: 'RUT',
          clave: 'Clave',
          boton_ingresar: 'Ingresar',
          recuperar_clave: '¿Olvidaste o no tienes tu contraseña?',
          languages_code: 'es',
          home: 1
        }],
        contenedor_tuguiavital: [{
          id: 1,
          languages_code: 'es',
          home: 1,
          titulo: '¿Qué más necesitas?',
          subtitulo: 'Descubre toda nuestros productos, asesoría y herramientas financieras.',
          texto_inferior: '<p>Visita nuestra secci&oacute;n de <strong>educaci&oacute;n financiera</strong> para descubrir tu lado inversionista</p>',
          boton: 'Ir a educación financiera',
          mas_informacion: 'Más información'
        }],
        tuguiavital: [{
          tu_guia_vital_id: 1
        },
        {
          tu_guia_vital_id: 2
        },
        {
          tu_guia_vital_id: 3
        }
        ],
        contenedor_productos: [
          {
            id: 1,
            languages_code: 'es',
            home: 1,
            titulo: 'Ahorra con AFP PlanVital',
            subtitulo: 'Conoce todas las posibilidades de ahorrar con AFP PlanVital'
          }
        ],
        productos: [
          {
            id: 1,
            home_id: 1,
            productos_id: 1
          },
          {
            id: 2,
            home_id: 1,
            productos_id: 2
          },
          {
            id: 3,
            home_id: 1,
            productos_id: 3
          }
        ],
        valores_cuota: [
          {
            id: 1,
            titulo: '<p>&iquest;Conoces nuestros <strong>valores cuota</strong>?</p>',
            texto_fecha: 'Estos son los valores cuotas',
            texto_inferior: '<p>Si necesitas m&aacute;s informaci&oacute;n <strong>revisa nuestro buscador de rentabilidad</strong></p>',
            boton: 'Ir al buscador',
            languages_code: 'es',
            home: 1,
            fondo: 'Fondo',
            titulo_grafico: 'Valores cuota últimos 12 meses',
          }
        ],
        titulo_preguntas: 'Preguntas frecuentes sobre las AFP',
        descripcion_preguntas: 'Resuelve tus dudas con nuestras preguntas frecuentes de AFP PlanVital',
        preguntas: [
          {
            faq_id: {
              pregunta: '¿Qué es una AFP?',
              respuesta: '<p>Las Administradoras de Fondos de Pensiones (AFP), son instituciones encargadas de administrar e invertir tus ahorros con el fin de financiar tu futura pensi&oacute;n.</p>'
            }
          },
          { faq_id: 
            {
              pregunta: '¿Qué significa afiliarse a una AFP?',
              respuesta: '<p>Cuando alguien empieza a trabajar, ya sea como trabajador dependiente o independiente que emiten boletas a honorarios, debe ingresar a una administradora de fondos de pensiones (AFP). A eso se le llama afiliaci&oacute;n.</p>'
            }
          },
          {
            faq_id: {
              pregunta: '¿Es obligatorio afiliarme a una AFP?',
              respuesta: '<p>Es obligatorio para todos los trabajadores dependientes o independientes que emiten boletas a honorarios. Deben afiliarse a una AFP y as&iacute; cotizar para su futura pensi&oacute;n.</p>'
            }
          }
        ]
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const HOME_MOCKUP: Home = {
  id: 1,
  banners: [{
    id: 5,
    image: {
      url: `${environment.cmsApiUrl}assets/1850f3b1-1b06-4db4-938b-93abf66b9937.jpg`,
      alt: 'alt',
      title: ''
    },
    image_mobile: {
      url: `${environment.cmsApiUrl}assets/1850f3b1-1b06-4db4-938b-93abf66b9937.jpg`,
      alt: 'alt',
      title: ''
    },
    icon: 'save',
    url: 'url',
    firstLine: '<p>Si tu plan es <strong>ahorrar</strong></p>',
    secondLine: '<p><strong>Te podemos ayudar</strong></p>',
    highlightedLine: 'highlighted-first',
    button: 'Descubre cómo'
  }],
  productsContainer: {
    title: 'Ahorra con AFP PlanVital',
    subtitle: 'Conoce todas las posibilidades de ahorrar con AFP PlanVital',
    products: [{
      id: 1,
      identifier: 'Ahorro obligatorio',
      icon: 'icono',
      productUrl: null,
      title: 'Ahorro obligatorio',
      description: '<ul>\n<li>Ahorras para tu <strong>futura pensi&oacute;n</strong>.</li>\n<li>\n<p>Seguros de <strong>Cesant&iacute;a, Invalidez y Sobrevivencia.</strong></p>\n</li>\n<li>\n<p><strong>Tus ahorros est&aacute;n seguro</strong>s, son inembargables e independientes.</p>\n</li>\n</ul>',
      textButton: 'Más beneficios'
    },
    {
      id: 2,
      identifier: 'APV',
      icon: 'apv',
      productUrl: null,
      title: 'Ahorro Previsional Voluntario APV',
      description: '<ul>\n<li>\n<p>Permite alcanzar tu <strong>pensi&oacute;n deseada.</strong></p>\n</li>\n<li>\n<p>Puedes <strong>retirar el ahorro</strong> si lo necesitas.</p>\n</li>\n<li>\n<p>Puedes diversificar tu ahorro en <strong>hasta 2 multifondos.</strong></p>\n</li>\n</ul>',
      textButton: 'Más beneficios'
    },
    {
      id: 3,
      identifier: 'Cuenta 2',
      icon: 'cuenta2',
      productUrl: null,
      title: 'Cuenta de Ahorro Voluntario Cuenta 2',
      description: '<ul>\n<li>\n<p>Costo de administraci&oacute;n de<strong> 0,95% anual</strong> de tu ahorro.</p>\n</li>\n<li>\n<p>Puedes realizar <strong>24 giros al a&ntilde;o</strong>, sin costo.</p>\n</li>\n<li>\n<p>No existe <strong>monto m&iacute;nimo</strong> de ahorro.</p>\n</li>\n</ul>',
      textButton: 'Más beneficios'
    }],
  },
  login: {
    title: 'Ingresa a tu cuenta',
    rut: 'RUT',
    password: 'Clave',
    loginButton: 'Ingresar',
    forgotPasswordMessage: '¿Olvidaste o no tienes tu contraseña?'
  },
  mainActions: {
    leftIcon: 'notas',
    leftAction: '<p>Recibe tu pensi&oacute;n sin salir de casa.</p>',
    leftActionButton: 'Inscribe tu cuenta',
    rightIcon: 'calendario',
    rightAction: '<p>Revisa la fecha de tu pago de Pensi&oacute;n 2020</p>',
    rightActionButton: 'Revisar calendario'
  },
  articlesContainer: {
    title: '¿Qué más necesitas?',
    subtitle: 'Descubre toda nuestros productos, asesoría y herramientas financieras.',
    articles: [{
      id: 1,
      identifier: 'APV',
      image: {
        url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
        alt: 'alt',
        title: ''
      },
      title: 'Ahorro Previsional Voluntario APV',
      url: 'afiliado/ahorros/previsional-voluntario',
    },
    {
      id: 2,
      identifier: 'APS',
      image: {
        url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
        alt: 'alt',
        title: ''
      },
      title: 'Beneficios Aporte Solidario APS',
      url: 'pensionado/beneficios/pension-solidaria',
    },
    {
      id: 3,
      identifier: 'Cuenta 2',
      image: {
        url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
        alt: 'alt',
        title: ''
      },
      title: 'Ahorra con tu Cuenta 2',
      url: 'afiliado/ahorros/cuenta-2',
    }],
    moreInfoText: 'Más información',
    disclaimer: '<p>Visita nuestra secci&oacute;n de <strong>educaci&oacute;n financiera</strong> para descubrir tu lado inversionista</p>',
    disclaimerButton: 'Ir a educación financiera',
    disclaimerUrl: 'educacion_financiera'
  },
  moreArticlesContainer: {
    title: null,
    subtitle: null,
    articles: [{
      id: 1,
      identifier: 'APV',
      image: {
        url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
        alt: 'alt',
        title: ''
      },
      title: 'Ahorro Previsional Voluntario APV',
      url: 'afiliado/ahorros/previsional-voluntario',
    },
    {
      id: 2,
      identifier: 'APS',
      image: {
        url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
        alt: 'alt',
        title: ''
      },
      title: 'Beneficios Aporte Solidario APS',
      url: 'pensionado/beneficios/pension-solidaria',
    },
    {
      id: 3,
      identifier: 'Cuenta 2',
      image: {
        url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
        alt: 'alt',
        title: ''
      },
      title: 'Ahorra con tu Cuenta 2',
      url: 'afiliado/ahorros/cuenta-2',
    }],
    moreInfoText: 'Más información',
  },
  fundsContainer: {
    title: '<p>&iquest;Conoces nuestros <strong>valores cuota</strong>?</p>',
    subtitle: 'Estos son los valores cuotas',
    fundName: 'Fondo',
    disclaimer: '<p>Si necesitas m&aacute;s informaci&oacute;n <strong>revisa nuestro buscador de rentabilidad</strong></p>',
    disclaimerButton: 'Ir al buscador',
    disclaimerUrl: 'valores_cuota',
    chartTitle: 'Valores cuota últimos 12 meses',
  },
  guiaVitalContainer: {
    title: '¿Qué más necesitas?',
    subtitle: 'Descubre toda nuestros productos, asesoría y herramientas financieras.',
    articles: [{
      id: 1,
      identifier: 'APV',
      image: {
        url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
        alt: 'alt',
        title: '',
      },
      title: 'Ahorro Previsional Voluntario APV',
      url: 'afiliado/ahorros/previsional-voluntario',
    },
    {
      id: 2,
      identifier: 'APS',
      image: {
        url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
        alt: 'alt',
        title: '',
      },
      title: 'Beneficios Aporte Solidario APS',
      url: 'pensionado/beneficios/pension-solidaria',
    },
    {
      id: 3,
      identifier: 'Cuenta 2',
      image: {
        url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
        alt: 'alt',
        title: '',
      },
      title: 'Ahorra con tu Cuenta 2',
      url: 'afiliado/ahorros/cuenta-2',
    }],
    moreInfoText: 'Más información',
  },
  faqContainer: {
    title: 'Preguntas frecuentes sobre las AFP',
    subTitle: 'Resuelve tus dudas con nuestras preguntas frecuentes de AFP PlanVital',
    faq: [
      { title: '¿Qué es una AFP?', description: '<p>Las Administradoras de Fondos de Pensiones (AFP), son instituciones encargadas de administrar e invertir tus ahorros con el fin de financiar tu futura pensi&oacute;n.</p>' },
      { title: '¿Qué significa afiliarse a una AFP?', description: '<p>Cuando alguien empieza a trabajar, ya sea como trabajador dependiente o independiente que emiten boletas a honorarios, debe ingresar a una administradora de fondos de pensiones (AFP). A eso se le llama afiliaci&oacute;n.</p>' },
      { title: '¿Es obligatorio afiliarme a una AFP?', description: '<p>Es obligatorio para todos los trabajadores dependientes o independientes que emiten boletas a honorarios. Deben afiliarse a una AFP y as&iacute; cotizar para su futura pensi&oacute;n.</p>' }
    ]
  }
};

export const GET_EMPLOYER_HOME_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        id: 1,
        articulos: [
          {
            articulos_id: 7
          },
          {
            articulos_id: 8
          },
          {
            articulos_id: 9
          },
          {
            articulos_id: 10
          },
          {
            articulos_id: 11
          }
        ],
        banners: [
          {
            banners_id: 19
          },
          {
            banners_id: 20
          }
        ],
        contenedor_articulos: [
          {
            id: 1,
            idioma: 'es',
            titulo: 'Debes tener en cuenta',
            subtitulo: 'Información clave para tu negocio y tus trabajadores',
            mas_informacion: 'Más información',
            home_empleador: 1
          }
        ]
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const GET_HOME_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        id: 1,
        url_boton_articulos: 'educacion_financiera',
        url_boton_valores_cuota: 'valores_cuota',
        icono_accion_izquierda: 'notas',
        icono_accion_derecha: 'calendario',
        acciones_principales: [],
        articulos: [],
        mas_articulos: [],
        banners: [],
        contenedor_articulos: [],
        contenedor_productos: [],
        contenedor_tuguiavital: [],
        tuguiavital: [],
        login: [],
        productos: [],
        valores_cuota: [],
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const HOME_NULL_MOCKUP: Home = {
  id: 1,
  banners: [],
  productsContainer: {
    title: null,
    subtitle: null,
    products: [],
  },
  login: {
    title: null,
    rut: null,
    password: null,
    loginButton: null,
    forgotPasswordMessage: null,
  },
  mainActions: {
    leftIcon: 'notas',
    leftAction: null,
    leftActionButton: null,
    rightIcon: 'calendario',
    rightAction: null,
    rightActionButton: null,
  },
  articlesContainer: {
    title: null,
    subtitle: null,
    articles: [],
    moreInfoText: null,
    disclaimer: null,
    disclaimerButton: null,
    disclaimerUrl: 'educacion_financiera'
  },
  fundsContainer: {
    title: null,
    subtitle: null,
    fundName: null,
    disclaimer: null,
    disclaimerButton: null,
    disclaimerUrl: 'valores_cuota',
    chartTitle: null,
  },
  guiaVitalContainer: {
    title: null,
    subtitle: null,
    articles: [],
    moreInfoText: null,
  },
  moreArticlesContainer: {
    title: null,
    subtitle: null,
    articles: [],
    moreInfoText: null
  },
  faqContainer: {
    title: null,
    subTitle: null,
    faq: []
  }
};
