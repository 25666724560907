const pageKey = 'AFFILIATE.ASSESSMENT.CERTIFICATES.GET.MODAL';
const optionsKey = `${pageKey}.OPTIONS`;

export const MODAL_GET_CERTIFICATE_TEXT_CONTENT = {
  rutLabel: `${pageKey}.RUT_LABEL`,
  getCertificateButtonText: `${pageKey}.GET_CERTIFICATE_BUTTON_TEXT`,
  description: `${pageKey}.DESCRIPTION`,
  periodCodeLabel: `${pageKey}.PERIOD_LABEL`,
  productTypeLabel: `${pageKey}.PRODUCT_TYPE_LABEL`,
  accountTypeLabel: `${pageKey}.ACCOUNT_TYPE_LABEL`,
  fromDateLabel: `${pageKey}.FROM_DATE_LABEL`,
  toDateLabel: `${pageKey}.TO_DATE_LABEL`
};

export const PERIOD_CODE_OPTIONS = [
  { id: '12', name: `${optionsKey}.PERIOD.FIRST` },
  { id: 'H', name: `${optionsKey}.PERIOD.SIXTH` }
];

export const QUOTATIONS_PERIOD_CODE_OPTIONS = [
  { id: '12', name: `${optionsKey}.PERIOD.FIRST` },
  { id: '24', name: `${optionsKey}.PERIOD.SECOND` },
  { id: '36', name: `${optionsKey}.PERIOD.THIRD` },
  { id: '48', name: `${optionsKey}.PERIOD.FOURTH` },
  { id: '60', name: `${optionsKey}.PERIOD.FIFTH` },
  { id: 'H', name: `${optionsKey}.PERIOD.SEVENTH` }
];

export const PRODUCT_TYPE_OPTIONS = [
  { id: 'CCO', name: `${optionsKey}.PRODUCT_TYPE.FIRST` },
  { id: 'CCV', name: `${optionsKey}.PRODUCT_TYPE.SECOND` },
  { id: 'CAV', name: `${optionsKey}.PRODUCT_TYPE.THIRD` }
];

export const PERIOD_MIN_DATE = '1981-04-01';
