import { MultiFunds, ProfitabilityTable, RestrictionsTable, AssignationTable } from '@interfaces/multi-funds.interface';
import { environment } from '@environment';
import { Mockup } from '@interfaces/mockup.interface';

// tslint:disable:max-line-length
export const GET_MULTI_FUNDS_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        header: {
          id: 1,
          pagina: 'Multifondos',
          imagen: 42,
          imagen_mobile: 42,
          icono: 'analysis'
        },
        traducciones: [
          {
            rentabilidad: {
              titulos: {
                fondo: 'Fondo',
                mes: 'Oct-20',
                semestre: 'Ene 2020 - Oct 2020',
                añoPasado: '<p>Últimos 12 meses<\/p>\n<p>Nov 2019-Oct 2020<\/p>',
                ultimosAños: '<p>Promedio Anual<\/p>\n<p>Sep 2002 - Oct 2020<\/p>',
                historico: '<p>Promedio Anual<\/p>\n<p>Jun 1981 - Oct 2020<\/p>'
              },
              items: [
                {
                  fondo: 'A',
                  mes: '-1.10%',
                  semestre: '-5.05%',
                  añoPasado: '-1.09%',
                  ultimosAños: '5.55%',
                  historico: ''
                },
                {
                  fondo: 'B',
                  mes: '-0.37%',
                  semestre: '-2.17%',
                  añoPasado: '0.19%',
                  ultimosAños: '5.02%',
                  historico: ''
                },
                {
                  fondo: 'C',
                  mes: '0.21%',
                  semestre: '-0.17%',
                  añoPasado: '1.22%',
                  ultimosAños: '4.85%',
                  historico: '7.99%'
                },
                {
                  fondo: 'D',
                  mes: '0.49%',
                  semestre: '0.63%',
                  añoPasado: '0.65%',
                  ultimosAños: '4.22%',
                  historico: ''
                },
                {
                  fondo: 'E',
                  mes: '0.53%',
                  semestre: '2.12%',
                  añoPasado: '1.12%',
                  ultimosAños: '3.36%',
                  historico: '4.12%'
                }
              ]
            },
            languages_code: 'es'
          }
        ],
        titulo: 'Multifondos',
        descripcion: '<p>Son cinco alternativas (A, B, C, D y E) donde puedes invertir tus ahorros obligatorios y voluntarios.</p>\n<p><strong>&iquest;Cu&aacute;ntos multifondos puedo elegir?</strong></p>\n<p>Uno o dos de los cinco multifondos. Tu elecci&oacute;n depender&aacute; de la edad y del riesgo que est&eacute;s dispuesto a asumir.</p>',
        titulo_columna_1: '¿Fondos Riesgosos?',
        descripcion_columna_1: '<p>Se recomiendan&nbsp;<strong>para personas m&aacute;s j&oacute;venes</strong> ya que obtienen mayor rentabilidad en el largo plazo, y pueden recuperarse de las crisis del mercado,&nbsp;<strong>gracias a su mayor inversi&oacute;n en renta variable.</strong></p>',
        titulo_columna_2: '¿Fondos Conservadores?',
        descripcion_columna_2: '<p>La ley recomienda estos fondos a personas que est&aacute;n&nbsp;<strong>a 10 o menos a&ntilde;os de pensionarse,&nbsp;</strong>a fin de cuidar sus ahorros de los riesgos de mercado,&nbsp;<strong>por su menor inversi&oacute;n en renta variable.</strong></p>',
        disclaimer: '<p>Antes de hacer un cambio de fondo, considera lo siguiente:</p>\n<p><strong>Edad</strong><br>Cuando cotizas desde joven, tienes un horizonte m&aacute;s amplio de inversi&oacute;n y tus ahorros, al igual que el efecto de bola de nieve, van creciendo y son capaces de superar las p&eacute;rdidas que se presenten en algunos periodos.</p>\n<p><strong>Horizonte de tiempo</strong><br>Tus ahorros los utilizar&aacute;s reci&eacute;n en 30 o 40 a&ntilde;os m&aacute;s, por lo que puedes arriesgarte y distribuirlos en fondos como A o B que rentan mejor. A mayor edad, puedes elegir fondos que sean m&aacute;s estables como el C y conservadores, como el D o E.</p>\n<p><strong>Ahorro</strong><br>Debes considerar si tu pensi&oacute;n ser&aacute; el &uacute;nico ingreso a futuro o si la complementar&aacute;s con alguna Cuenta de Ahorro Voluntario.</p>',
        fondos: [
            {
                fondo: 'A',
                titulo_fondo: 'Más riesgoso',
                limite_maximo: '80%',
                limite_minimo: '40%',
                descripcion_fondo: 'Inversiones a largo plazo, conscientes de las ganancias y pérdidas que podrían ocurrir'
            },
            {
                fondo: 'B',
                titulo_fondo: 'Riesgoso',
                limite_maximo: '60%',
                limite_minimo: '25%',
                descripcion_fondo: 'Riesgoso aunque en menor medida que el fondo A.'
            },
            {
                fondo: 'C',
                titulo_fondo: 'Intermedio',
                limite_maximo: '40%',
                limite_minimo: '15%',
                descripcion_fondo: 'Opción equilibrada entre rentabilidad y riesgo.'
            },
            {
                fondo: 'D',
                titulo_fondo: 'Conservador',
                limite_maximo: '20%',
                limite_minimo: '5%',
                descripcion_fondo: 'Inversiones a corto plazo, menores pérdidas posibles.'
            },
            {
                fondo: 'E',
                titulo_fondo: 'Más conservador',
                limite_maximo: '5%',
                limite_minimo: '0%',
                descripcion_fondo: 'Inversiones a corto plazo, que quieran arriesgarse lo mínimo posible.'
            }
        ],
        cards: [
          {
              multifondos_cards_id: {
                  titulo: '¿Cualés son las restricciones para el cambio de fondo?',
                  alt_image: 'Multifondos restricciones',
                  title_image: 'Multifondos restricciones',
                  texto_boton: 'Más información',
                  descripcion: '<p>Existen algunas restricciones para el ahorro obligatorio de acuerdo a la tabla siguiente. Estas restricciones est&aacute;n establecidas por ley, para proteger los recursos previsionales de las fluctuaciones del mercado.</p>\n<table style=\'border-collapse: collapse; width: 100%;\' border=\'1\'><colgroup><col style=\'width: 33.244%;\'><col style=\'width: 33.244%;\'><col style=\'width: 33.244%;\'></colgroup>\n<thead>\n<tr>\n<td>Tipo de Afiliado</td>\n<td>Edad</td>\n<td>Fondos a optar</td>\n</tr>\n</thead>\n<tbody>\n<tr>\n<td>No pensionado</td>\n<td>Hombre hasta 55 a&ntilde;os. Mujer hasta 50 a&ntilde;os.</td>\n<td>A, B, C, D, E</td>\n</tr>\n<tr>\n<td>No pensionado</td>\n<td>Hombres desde 56 a&ntilde;os. Mujer desde los 51 a&ntilde;os.</td>\n<td>B, C, D, E</td>\n</tr>\n<tr>\n<td>Pensionado</td>\n<td>Cualquier edad</td>\n<td>C, D, E</td>\n</tr>\n</tbody>\n</table>',
                  imagen_card: {
                      filename_disk: '37f969a3-9eda-4199-beb4-15367b735443.jpg'
                  }
              }
          },
          {
              multifondos_cards_id: {
                  titulo: '¿Es bueno cambiarse de fondo regularmente?',
                  alt_image: 'Multifondos cambios',
                  title_image: 'Multifondos cambios',
                  texto_boton: 'Más información',
                  descripcion: '<p><strong>No es recomendable.</strong> Los cambios frecuentes podr&iacute;an afectar el monto final de tu pensi&oacute;n, ya que la rentabilidad obtenida no ser&iacute;a la misma que si hubieses permanecido en un mismo fondo.</p>\n<p><strong>&iquest;C&oacute;mo me puedo cambiar de fondo?</strong></p>\n<p>Para ellos debes tener activa tu Clave de Seguridad.<br>Considera lo siguiente:</p>\n<ul>\n<li>Las inversiones y rentabilidades se miden en el largo plazo.</li>\n<li>No es posible asegurar rentabilidades futuras.</li>\n</ul>\n<p><strong>&iquest;Cu&aacute;nto se demora un cambio de fondo?</strong></p>\n<p>Se concreta al quinto d&iacute;a h&aacute;bil. Si, por ejemplo, realizas un cambio el 06-04-2020, se concretar&iacute;a el 13-04-2020.</p>\n<p><strong>&iquest;No sabes qu&eacute; fondo elegir?</strong></p>\n<p>Te invitamos a revisar <a title=\'Perfil de inversionista\' href=\'/afiliado/asesoria/simuladores\' target=\'_blank\' rel=\'noopener\'>tu perfil de inversionista</a>.</p>',
                  imagen_card: {
                      filename_disk: 'cc3b685e-1ea8-4157-97fc-4f839ec12718.jpg'
                  }
              }
          },
          {
              multifondos_cards_id: {
                  titulo: '¿Qué ocurre si no elijo ningún fondo?',
                  alt_image: 'Mutifondos elección',
                  title_image: 'Multifondos elección',
                  texto_boton: 'Más información',
                  descripcion: '<p>Si no escoges ning&uacute;n fondo, asignaremos uno tomando en cuenta tu edad, sexo y el tiempo que falta para pensionarte. Si no eliges un fondo, la AFP distribuir&aacute; tus ahorros de la siguiente manera:</p>\n<table style=\'border-collapse: collapse; width: 100%;\' border=\'1\'><colgroup><col style=\'width: 50%;\'><col style=\'width: 50%;\'></colgroup>\n<tbody>\n<tr>\n<td>Edad</td>\n<td>Fondos a optar</td>\n</tr>\n<tr>\n<td>Hombres y mujeres hasta 35 a&ntilde;os.</td>\n<td>B</td>\n</tr>\n<tr>\n<td>Hombres desde 36 a 55 a&ntilde;os. Mujeres desde 36 a 50 a&ntilde;os.</td>\n<td>C</td>\n</tr>\n<tr>\n<td>Hombres desde 56 a&ntilde;os. Mujeres desde 51 a&ntilde;os. Pensionados.</td>\n<td>D</td>\n</tr>\n</tbody>\n</table>',
                  imagen_card: {
                      filename_disk: '8bfe9cdf-9313-4a58-b1f3-a4356a6c8c32.jpg'
                  }
              }
          }
      ]        
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

const pageKey = 'AFFILIATE.ASSESSMENT.MULTI_FUNDS';

export const MULTI_FUNDS_MOCKUP: MultiFunds = {
  header: {
    id: 1,
    image: {
      url: `${environment.cmsApiUrl}assets/16d3caad-dd9a-4a4b-93da-f17fc546c41a.png`,
      alt: 'alt',
      title: '',
    },
    image_mobile: {
      url: `${environment.cmsApiUrl}assets/16d3caad-dd9a-4a4b-93da-f17fc546c41a.png`,
      alt: 'alt',
      title: '',
    },
    icon: 'handshake',
    firstLine: '<p>Estado de tu&nbsp;<strong>traspaso a AFP PlanVital</strong></p>',
  },
  showImgCards: true,
  title: 'Multifondos',
  description: '<p>Son cinco alternativas (A, B, C, D y E) donde puedes invertir tus ahorros obligatorios y voluntarios.</p>\n<p><strong>&iquest;Cu&aacute;ntos multifondos puedo elegir?</strong></p>\n<p>Uno o dos de los cinco multifondos. Tu elecci&oacute;n depender&aacute; de la edad y del riesgo que est&eacute;s dispuesto a asumir.</p>',
  funds: [
    {
      name: 'A',
      risk: 'Más riesgoso',
      description: 'Inversiones a largo plazo, conscientes de las ganancias y pérdidas que podrían ocurrir',
      maxLimit: '80%',
      minLimit: '40%',
    },
    {
      name: 'B',
      risk: 'Riesgoso',
      description: 'Riesgoso aunque en menor medida que el fondo A.',
      maxLimit: '60%',
      minLimit: '25%',
    },
    {
      name: 'C',
      risk: 'Intermedio',
      description: 'Opción equilibrada entre rentabilidad y riesgo.',
      maxLimit: '40%',
      minLimit: '15%',
    },
    {
      name: 'D',
      risk: 'Conservador',
      description: 'Inversiones a corto plazo, menores pérdidas posibles.',
      maxLimit: '20%',
      minLimit: '5%',
    },
    {
      name: 'E',
      risk: 'Más conservador',
      description: 'Inversiones a corto plazo, que quieran arriesgarse lo mínimo posible.',
      maxLimit: '5%',
      minLimit: '0%',
    },
  ],
  helpSection: {
    risky: {
      title: '¿Fondos Riesgosos?',
      description: '<p>Se recomiendan&nbsp;<strong>para personas m&aacute;s j&oacute;venes</strong> ya que obtienen mayor rentabilidad en el largo plazo, y pueden recuperarse de las crisis del mercado,&nbsp;<strong>gracias a su mayor inversi&oacute;n en renta variable.</strong></p>',
    },
    safe: {
      title: '¿Fondos Conservadores?',
      description: '<p>La ley recomienda estos fondos a personas que est&aacute;n&nbsp;<strong>a 10 o menos a&ntilde;os de pensionarse,&nbsp;</strong>a fin de cuidar sus ahorros de los riesgos de mercado,&nbsp;<strong>por su menor inversi&oacute;n en renta variable.</strong></p>',
    }
  },
  fundChangeDisclaimer: '<p>Antes de hacer un cambio de fondo, considera lo siguiente:</p>\n<p><strong>Edad</strong><br>Cuando cotizas desde joven, tienes un horizonte m&aacute;s amplio de inversi&oacute;n y tus ahorros, al igual que el efecto de bola de nieve, van creciendo y son capaces de superar las p&eacute;rdidas que se presenten en algunos periodos.</p>\n<p><strong>Horizonte de tiempo</strong><br>Tus ahorros los utilizar&aacute;s reci&eacute;n en 30 o 40 a&ntilde;os m&aacute;s, por lo que puedes arriesgarte y distribuirlos en fondos como A o B que rentan mejor. A mayor edad, puedes elegir fondos que sean m&aacute;s estables como el C y conservadores, como el D o E.</p>\n<p><strong>Ahorro</strong><br>Debes considerar si tu pensi&oacute;n ser&aacute; el &uacute;nico ingreso a futuro o si la complementar&aacute;s con alguna Cuenta de Ahorro Voluntario.</p>',
  moreInfoCards: [
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_restricciones.jpg',
        alt: 'Restricciones'
      },
      title: `${pageKey}.CARDS.RESTRICTIONS.TITLE`,
      showMoreText: 'GENERAL.MORE_INFO',
      modal: {
        type: 'restrictions',
        title: `${pageKey}.CARDS.RESTRICTIONS.TITLE`,
        description: `${pageKey}.CARDS.RESTRICTIONS.MODAL_DESCRIPTION`,
        data: {
          header: {
            type: 'Tipo de Afiliado',
            age: 'Edad',
            funds: 'Fondos a optar',
          },
          items: [
            {
              type: 'No pensionado',
              age: '<p>Hombre hasta 55 años.</p>\n<p>Mujer hasta 50 años.</p>',
              funds: 'A, B, C, D, E',
            },
            {
              type: 'No pensionado',
              age: '<p>Hombres desde 56 años.</p>\n<p>Mujer desde los 51 años.</p>',
              funds: 'B, C, D, E',
            },
            {
              type: 'Pensionado',
              age: 'Cualquier edad',
              funds: 'C, D, E',
            },
          ]
        } as RestrictionsTable
      },
    },
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_cambios.jpg',
        alt: 'Cambio multifondos'
      },
      title: `${pageKey}.CARDS.REGULAR_CHANGE.TITLE`,
      showMoreText: 'GENERAL.MORE_INFO',
      modal: {
        title: `${pageKey}.CARDS.REGULAR_CHANGE.TITLE`,
        description: `${pageKey}.CARDS.REGULAR_CHANGE.MODAL_DESCRIPTION`,
      },
    },
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_eleccion.jpg',
        alt: 'Elección multifondos'
      },
      title: `${pageKey}.CARDS.ASSIGNATION.TITLE`,
      showMoreText: 'GENERAL.MORE_INFO',
      modal: {
        type: 'assignation',
        title: `${pageKey}.CARDS.ASSIGNATION.TITLE`,
        description: `${pageKey}.CARDS.ASSIGNATION.MODAL_DESCRIPTION`,
        data: {
          header: {
            age: 'Edad',
            fund: 'Fondos a optar',
          },
          items: [
            {
              age: 'Hombres y mujeres hasta 35 años.',
              fund: 'B',
            },
            {
              age: '<p>Hombres desde 36 a 55 años.</p>\n<p>Mujeres desde 36 hasta los 50 años.</p>',
              fund: 'C',
            },
            {
              age: '<p>Hombres desde 56 años.</p>\n<p>Mujeres desde 51 años.</p>\n<p>Pensionados.</p>',
              fund: 'D',
            },
          ]
        } as AssignationTable
      },
    },
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_rentabilidad.jpg',
        alt: 'Rentabilidad multifondos'
      },
      title: `${pageKey}.CARDS.PROFITABILITY.TITLE`,
      showMoreText: 'GENERAL.MORE_INFO',
      modal: {
        type: 'profitability',
        title: `${pageKey}.CARDS.PROFITABILITY.TITLE`,
        description: `${pageKey}.CARDS.PROFITABILITY.MODAL_DESCRIPTION`,
        data: {
          header: {
            fund: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.FUND`,
            month: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.MONTH`,
            semester: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.SEMESTER`,
            lastYear: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.LAST_YEAR`,
            lastYears: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.LAST_YEARS`,
            historic: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.HISTORIC`,
          },
          items: [
            {
              fund: 'A',
              month: '-0,35%',
              semester: '-5,98%',
              lastYear: '0,42%',
              lastYears: '5,58%',
              historic: '',
            },
            {
              fund: 'B',
              month: '-0,14%',
              semester: '-2,72%',
              lastYear: '1,09%',
              lastYears: '5,06%',
              historic: '',
            },
            {
              fund: 'C',
              month: '-0,41%',
              semester: '-0,57%',
              lastYear: '1,32%',
              lastYears: '4,89%',
              historic: '8,04%',
            },
            {
              fund: 'D',
              month: '-0,42%',
              semester: '1,04%',
              lastYear: '0,14%',
              lastYears: '4,30%',
              historic: '',
            },
            {
              fund: 'E',
              month: '-0,58%',
              semester: '2,41%',
              lastYear: '0,00%',
              lastYears: '3,43%',
              historic: '4,18%',
            },
          ]
        } as ProfitabilityTable,
      }
    },
  ]
};

export const GET_MULTI_FUNDS_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        header: {
          id: 1,
          pagina: 'Multifondos',
          imagen: 42,
          imagen_mobile: 42,
          icono: 'analysis'
        },
        traducciones: [],
        cards: [],
        funds: []
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const MULTI_FUNDS_NULL_MOCKUP: MultiFunds = {
  header: {
    id: 1,
    image: {
      url: null,
      alt: null,
      title: null,
    },
    image_mobile: {
      url: null,
      alt: null,
      title: null,
    },
    icon: 'handshake',
    firstLine: null,
  },
  showImgCards: true,
  title: undefined,
  description: undefined,
  funds: [],
  helpSection: {
    risky: {
      title: undefined,
      description: undefined,
    },
    safe: {
      title: undefined,
      description: undefined,
    }
  },
  fundChangeDisclaimer: undefined,
  moreInfoCards: [
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_restricciones.jpg',
        alt: 'Restricciones'
      },
      title: `${pageKey}.CARDS.RESTRICTIONS.TITLE`,
      showMoreText: 'GENERAL.MORE_INFO',
      modal: {
        type: 'restrictions',
        title: `${pageKey}.CARDS.RESTRICTIONS.TITLE`,
        description: `${pageKey}.CARDS.RESTRICTIONS.MODAL_DESCRIPTION`,
        data: {
          header: {
            type: 'Tipo de Afiliado',
            age: 'Edad',
            funds: 'Fondos a optar',
          },
          items: [
            {
              type: 'No pensionado',
              age: '<p>Hombre hasta 55 años.</p>\n<p>Mujer hasta 50 años.</p>',
              funds: 'A, B, C, D, E',
            },
            {
              type: 'No pensionado',
              age: '<p>Hombres desde 56 años.</p>\n<p>Mujer desde los 51 años.</p>',
              funds: 'B, C, D, E',
            },
            {
              type: 'Pensionado',
              age: 'Cualquier edad',
              funds: 'C, D, E',
            },
          ]
        } as RestrictionsTable
      },
    },
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_cambios.jpg',
        alt: 'Cambio multifondos'
      },
      title: `${pageKey}.CARDS.REGULAR_CHANGE.TITLE`,
      showMoreText: 'GENERAL.MORE_INFO',
      modal: {
        title: `${pageKey}.CARDS.REGULAR_CHANGE.TITLE`,
        description: `${pageKey}.CARDS.REGULAR_CHANGE.MODAL_DESCRIPTION`,
      },
    },
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_eleccion.jpg',
        alt: 'Elección multifondos'
      },
      title: `${pageKey}.CARDS.ASSIGNATION.TITLE`,
      showMoreText: 'GENERAL.MORE_INFO',
      modal: {
        type: 'assignation',
        title: `${pageKey}.CARDS.ASSIGNATION.TITLE`,
        description: `${pageKey}.CARDS.ASSIGNATION.MODAL_DESCRIPTION`,
        data: {
          header: {
            age: 'Edad',
            fund: 'Fondos a optar',
          },
          items: [
            {
              age: 'Hombres y mujeres hasta 35 años.',
              fund: 'B',
            },
            {
              age: '<p>Hombres desde 36 a 55 años.</p>\n<p>Mujeres desde 36 hasta los 50 años.</p>',
              fund: 'C',
            },
            {
              age: '<p>Hombres desde 56 años.</p>\n<p>Mujeres desde 51 años.</p>\n<p>Pensionados.</p>',
              fund: 'D',
            },
          ]
        } as AssignationTable
      },
    },
    {
      image: {
        url: 'assets/images/AFP_Planvital_multifondos_rentabilidad.jpg',
        alt: 'Rentabilidad multifondos'
      },
      title: `${pageKey}.CARDS.PROFITABILITY.TITLE`,
      showMoreText: 'GENERAL.MORE_INFO',
      modal: {
        type: 'profitability',
        title: `${pageKey}.CARDS.PROFITABILITY.TITLE`,
        description: `${pageKey}.CARDS.PROFITABILITY.MODAL_DESCRIPTION`,
        data: {
          header: {
            fund: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.FUND`,
            month: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.MONTH`,
            semester: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.SEMESTER`,
            lastYear: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.LAST_YEAR`,
            lastYears: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.LAST_YEARS`,
            historic: `${pageKey}.CARDS.PROFITABILITY.MODAL_HEADERS.HISTORIC`,
          },
          items: [
            {
              fund: 'A',
              month: '-0,35%',
              semester: '-5,98%',
              lastYear: '0,42%',
              lastYears: '5,58%',
              historic: '',
            },
            {
              fund: 'B',
              month: '-0,14%',
              semester: '-2,72%',
              lastYear: '1,09%',
              lastYears: '5,06%',
              historic: '',
            },
            {
              fund: 'C',
              month: '-0,41%',
              semester: '-0,57%',
              lastYear: '1,32%',
              lastYears: '4,89%',
              historic: '8,04%',
            },
            {
              fund: 'D',
              month: '-0,42%',
              semester: '1,04%',
              lastYear: '0,14%',
              lastYears: '4,30%',
              historic: '',
            },
            {
              fund: 'E',
              month: '-0,58%',
              semester: '2,41%',
              lastYear: '0,00%',
              lastYears: '3,43%',
              historic: '4,18%',
            },
          ]
        } as ProfitabilityTable,
      }
    },
  ]
};
