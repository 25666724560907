import { Component, Input } from '@angular/core';
import { FaqSection } from '@interfaces/faq-section.interface';
import { FontService } from '@providers/font/font.service';



@Component({
    selector: 'app-faq-section',
    templateUrl: './faq-section.component.html',
    styleUrls: ['./faq-section.component.scss']
})
export class FaqSectionComponent {
    @Input() loading;
    @Input() faqData: FaqSection;

    faqs = {
        faqs: [
            {
                title: 'Titulo',
                description: 'Descripcion'
            }
        ]
    };

    constructor(
        public font: FontService,
    ) {}
}
