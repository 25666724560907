import { Mockup } from '@interfaces/mockup.interface';

// tslint:disable:max-line-length
export const GET_ESSENTIAL_FACTS_LINKS: Mockup = {
  success: [{
    response: {
      data: {
         periodos: [
          {
              hechos_esenciales_seccion_id: {
                titulo: 'Periodo 2024',
                archivos: [
                    {
                      hechos_esenciales_archivo_id: {
                          titulo: 'Reparto de utilidades (pago de dividendos)',
                          fecha: '2024-11-21',
                          archivo: {
                            filename_disk: '93b5a588-d0cf-47cb-88e0-0b76a77fcc6c.pdf'
                          }
                      }
                    },
                    {
                      hechos_esenciales_archivo_id: {
                          titulo: 'Acuerdos Juntas de Accionistas',
                          fecha: '2024-10-15',
                          archivo: {
                            filename_disk: '03ef0da8-1b29-4f2a-9d8e-1451398ee376.pdf'
                          }
                      }
                    },
                    {
                      hechos_esenciales_archivo_id: {
                          titulo: 'Junta de Accionistas',
                          fecha: '2024-09-26',
                          archivo: {
                            filename_disk: 'e6709d9c-ea77-4de4-9b31-ae223fee6333.pdf'
                          }
                      }
                    },
                    {
                      hechos_esenciales_archivo_id: {
                          titulo: 'Cambios en el representante legal y cambios en la administración',
                          fecha: '2024-10-12',
                          archivo: {
                            filename_disk: '33e1692d-66a6-4687-bc7f-0be2a8f0b832.pdf'
                          }
                      }
                    },
                    {
                      hechos_esenciales_archivo_id: {
                          titulo: 'Reparto de utilidades (pago de dividendos)',
                          fecha: '2024-04-30',
                          archivo: {
                            filename_disk: 'b905f788-c930-4d47-8977-661c7f0332dd.pdf'
                          }
                      }
                    },
                    {
                      hechos_esenciales_archivo_id: {
                          titulo: 'Acuerdos Juntas de Accionistas',
                          fecha: '2024-04-30',
                          archivo: {
                            filename_disk: '4326eb05-f748-4b3c-ad44-2d5ddbf10294.pdf'
                          }
                      }
                    },
                    {
                      hechos_esenciales_archivo_id: {
                          titulo: 'Junta de Accionistas',
                          fecha: '2024-04-15',
                          archivo: {
                            filename_disk: 'bd8d31c5-20f2-43d9-9d40-e8eefaac5747.pdf'
                          }
                      }
                    }
                ]
              }
          }
        ]
      }
     }
  }],
  failures: [{
    response: {}
  }]
};
