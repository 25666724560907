import { Mockup } from '@interfaces/mockup.interface';


export const GET_CRIME_PREVENTION_MODEL_CONTENT_MOCKUP: Mockup = {
    success: [{
        response: {           
            data: {
                titulo: 'Certificado Modelo de Prevención de Delitos',
                archivo: {
                    filename_disk: '4aa5d61c-d7e5-46be-8f14-305a71800cce.pdf',
                    filename_download: 'Certificado Modelo de Prevención de Delitos AFP PlanVital 2024-2026.pdf',
                    title: 'Certificado Modelo De Prevención De Delitos Afp Plan Vital 2024 2026'
                }
            }
        }
    }],
    failures: []
};
