import { Mockup } from '@interfaces/mockup.interface';

export const GET_QUOTA_VALUES_RESPONSE_MOCKUP: Mockup = {
    success: [{
        response: {
            data: {
                id: 1,
                titulo: 'Valor Cuota AFPPP',
                subtitulo: 'Rentabilidad Real Anual del Fondo de PensionesPPP',
                descripcion_1: '<h2>&iquest;Qu&eacute; es la rentabilidad????</h2>\n<p>Es el resultado obtenido entre ganancia y p&eacute;rdida de los fondos de pensiones producto de sus inversiones en el mercado nacional e internacional.lll</p>',
                descripcion_2: '<h2>&iquest;Qu&eacute; otro tipo de rentabilidades existen???</h2>\n<p><strong>Rentabilidad real</strong>&nbsp;de la cuota del fondo, ganancia o p&eacute;rdida de las inversiones en un tiempo determinado.</p>\n<p><strong>La rentabilidad real de la Cuenta Obligatoria</strong>, es el resultado de rentabilidad real de la cuota del fondo despu&eacute;s de descontar la cotizaci&oacute;n adicional por comisi&oacute;n y el seguro de Invalidez y Sobrevivencia (SIS).</p>\n<p><strong>Rentabilidad M&iacute;nima</strong>, los fondos proporcionan una m&iacute;nima rentabilidad, que se calcula en base a la rentabilidad promedio de los &uacute;ltimos 12 meses.</p>\n<h2>&iquest;Qu&eacute; es el valor cuota?</h2>\n<p>Es una unidad en pesos con la que se miden tus ahorros en los fondos de pensiones (A, B, C, D y E). Los valores cuotas cambian diariamente y su aumento o disminuci&oacute;n determina la rentabilidad de tus ahorros.<br>Si has ahorrado, por ejemplo, $2.000.000 y el valor cuota es de $200.000, tu ahorro equivale a 10 cuotas.</p>',
                descripcion_3: '<h2>Comisiones vigentessss</h2>\n<p>Todos los trabajadores deben pagar un porcentaje de su renta imponible para sus cotizaciones, con el prop&oacute;sito de ahorrar y financiar su futura pensi&oacute;n. La AFP se encarga de recaudar y administrar estos montos mensualmente.<br>Inf&oacute;rmate cu&aacute;nto debes pagar por las cotizaciones previsionales de tus trabajadores.</p>\n<p><strong>&iquest;Cu&aacute;les son las comisiones que se cobran?</strong></p>\n<p>Cuenta de Capitalizaci&oacute;n Individual Obligatoria: el trabajador todos los meses entrega el 10% de su remuneraci&oacute;n imponible para el pago de sus cotizaciones. Adicionalmente a ese porcentaje se cobra tambi&eacute;n el 1,16%, que corresponde a la administraci&oacute;n de los ahorros.</p>\n<p>Cuenta de Ahorro Voluntario: el porcentaje depende de si tienes un Aporte Previsional Voluntario (APV) o Cuenta de Ahorro Voluntario (Cuenta 2).<br>La comisi&oacute;n del APV para afiliados a AFP PlanVital es de 0,16 y no afiliados 0,64%. En caso de la Cuenta 2, afiliados y no afiliados pagan 0,16%.</p>\n<p>Cuenta de Ahorro de Indemnizaci&oacute;n: los empleadores que contraten a un trabajador puertas adentro, deber&aacute;n pagar mensualmente el 4,11% de la remuneraci&oacute;n imponible a la AFP.</p>\n<p>Cuenta de Ahorro Previsional Voluntario colectivo: el empleador y la AFP firman un contrato donde se establece el monto de ahorro mensual al que se compromete &eacute;l y sus trabajadores.<br>En este sentido, la AFP cobra una comisi&oacute;n mensual por la administraci&oacute;n del saldo y una suma fija por la transferencia de los dep&oacute;sitos.</p>\n<p>A continuaci&oacute;n podr&aacute;s consultar el comportamiento de este valor para los diferentes Fondos, seleccionando el per&iacute;odo de tu inter&eacute;s. Para conocer el Valor Cuota de un determinado per&iacute;odo, selecci&oacute;nalo de la lista que aparece a continuaci&oacute;n:</p>',
                notas_columna_1: '<p>(1) Variaci&oacute;n Nominal deflactada por la variaci&oacute;n de la U.F. del per&iacute;odo. Esta rentabilidad es la que debe ser usada para las comparaciones que se realicen con el mercado financiero.</p>\n<p>(2) Corresponde a la rentabilidad obtenida por las cotizaciones que se mantuvieron en el Fondo respectivo o en el Sistema durante todo el per&iacute;odo considerado en la medici&oacute;n.</p>\n<p>(3) Per&iacute;odo Julio 1981 - Diciembre 1981</p>\n<p>(4) Informaci&oacute;n de los Fondos de Pensiones fusionados:</p>\n<p>(*) A contar del 01 de noviembre de 1996 el Fondo de Pensiones Valora cambia su nombre por el de Fondos de Pensiones Qualitas, distinto a aquel que desapareci&oacute; el 01 de junio de 1995.</p>\n<p>(**) En la fusi&oacute;n de los Fondos de Pensiones Magister y AFP Planvital, el Fondo continuador es Magister, el cual cambia de nombre a AFP Planvital.</p>\n<p>(5) El d&iacute;a 3 de agosto de 1995 se liquid&oacute; el Fondo de Pensiones Genera.</p>\n<p>(6) El d&iacute;a 22 de julio de 1994 se liquid&oacute; el Fondo de Pensiones Laboral.</p>\n<p>(7) Per&iacute;odo Noviembre 1981 - Diciembre 1981.</p>\n<p>(8) Per&iacute;odo Abril 1986 - Diciembre 1986.</p>\n<p>(9) Corresponde al promedio de la rentabilidad obtenida por los afiliados provenientes de A.F.P.</p>',
                notas_columna_2: '<p>(10) Variaci&oacute;n Nominal deflactada por la variaci&oacute;n de la U.F. del per&iacute;odo. Esta rentabilidad es la que Alameda y A.F.P. San Crist&oacute;bal.</p>\n<p>(11) Promedio ponderado por el valor del Fondo de Pensiones de cada A.F.P. al &uacute;ltimo d&iacute;a del mes anterior.</p>\n<p>(12) La Rentabilidad hasta el a&ntilde;o 2002 se calcul&oacute; en base al valor cuota promedio, mientras que a partir del a&ntilde;o 2003 el c&aacute;lculo se realiz&oacute; en base al valor cuota del &uacute;ltimo d&iacute;a del mes.</p>\n<p>(13) Considera como valor cuota inicial el correspondiente al 16 de junio de 1981 debido a que el d&iacute;a siguiente dicho Fondo recibi&oacute; sus primeros recursos.</p>\n<p>(14) Mediante resoluci&oacute;n N&deg; E-184-2005 de la Superintendencia de Administradoras de Fondos de Pensiones, publicada en el Diario Oficial el d&iacute;a 05 de marzo de 2005, se aprob&oacute; la reforma introducida a los estatutos de la A.F.P. Summa Bansander S.A., mediante la cual se establece el cambio de nombre de la A.F.P. Summa Bansander S.A. por A.F.P. Bansander S.A.</p>\n<p>(15) Mediante resoluci&oacute;n N&deg; E-196-2008 de fecha 17 de marzo de 2008, de la Superintendencia de Administradoras de Fondos de Pensiones, aprob&oacute; el cambio de nombre de AFP Santa Mar&iacute;a S.A.a AFP Capital S.A.</p>\n<p>Mediante resoluci&oacute;n N&deg; E-197-2008 de fecha 1&deg; de abril de 2008 se autoriza la fusi&oacute;n de AFP Capital S.A. con AFP Bansander S.A. conformando el Fondo de Pensiones Capital S.A.</p>',
                titulo_notas: 'Notassss',
                titulo_grafico: 'Rentabilidad de los fondos',
                rentabilidad: [
                    {
                        fondo_a: '1.6',
                        fondo_b: '-0.38',
                        fondo_c: '3.17',
                        fondo_d: '-0.84',
                        fondo_e: '8.58',
                        periodo: '2002'
                    },
                    {
                        fondo_a: '25.24',
                        fondo_b: '15.37',
                        fondo_c: '10.37',
                        fondo_d: '7.92',
                        fondo_e: '2.4',
                        periodo: '2003'
                    },
                    {
                        fondo_a: '11.94',
                        fondo_b: '9.68',
                        fondo_c: '8.99',
                        fondo_d: '6.91',
                        fondo_e: '5.02',
                        periodo: '2004'
                    },
                    {
                        fondo_a: '9.95',
                        fondo_b: '6.94',
                        fondo_c: '4.46',
                        fondo_d: '2.61',
                        fondo_e: '0.5',
                        periodo: '2005'
                    },
                    {
                        fondo_a: '23.13',
                        fondo_b: '19.7',
                        fondo_c: '16.02',
                        fondo_d: '11.77',
                        fondo_e: '7.14',
                        periodo: '2006'
                    },
                    {
                        fondo_a: '8.43',
                        fondo_b: '7.83',
                        fondo_c: '5.51',
                        fondo_d: '3.43',
                        fondo_e: '1.28',
                        periodo: '2007'
                    },
                    {
                        fondo_a: '-38.99',
                        fondo_b: '-28.83',
                        fondo_c: '-17.66',
                        fondo_d: '-8.88',
                        fondo_e: '-1.16',
                        periodo: '2008'
                    },
                    {
                        fondo_a: '44.19',
                        fondo_b: '32.6',
                        fondo_c: '20.89',
                        fondo_d: '13.62',
                        fondo_e: '6.57',
                        periodo: '2009'
                    },
                    {
                        fondo_a: '11.63',
                        fondo_b: '10.52',
                        fondo_c: '9.27',
                        fondo_d: '5.91',
                        fondo_e: '5.49',
                        periodo: '2010'
                    },
                    {
                        fondo_a: '-10.57',
                        fondo_b: '-7.25',
                        fondo_c: '-3.55',
                        fondo_d: '0.23',
                        fondo_e: '3.68',
                        periodo: '2011'
                    },
                    {
                        fondo_a: '6.84',
                        fondo_b: '5.61',
                        fondo_c: '5',
                        fondo_d: '3.96',
                        fondo_e: '2.95',
                        periodo: '2012'
                    },
                    {
                        fondo_a: '7.19',
                        fondo_b: '4.76',
                        fondo_c: '4.81',
                        fondo_d: '5.44',
                        fondo_e: '5.13',
                        periodo: '2013'
                    },
                    {
                        fondo_a: '8.3',
                        fondo_b: '7.5',
                        fondo_c: '7.48',
                        fondo_d: '6.72',
                        fondo_e: '5.74',
                        periodo: '2014'
                    },
                    {
                        fondo_a: '2.86',
                        fondo_b: '1.95',
                        fondo_c: '1.76',
                        fondo_d: '1.92',
                        fondo_e: '0.34',
                        periodo: '2015'
                    },
                    {
                        fondo_a: '-1.62',
                        fondo_b: '-0.03',
                        fondo_c: '1.2',
                        fondo_d: '2.3',
                        fondo_e: '3.55',
                        periodo: '2016'
                    },
                    {
                        fondo_a: '14.61',
                        fondo_b: '10.86',
                        fondo_c: '6.81',
                        fondo_d: '2.45',
                        fondo_e: '0.7',
                        periodo: '2017'
                    },
                    {
                        fondo_a: '-5.75',
                        fondo_b: '-3.78',
                        fondo_c: '-0.91',
                        fondo_d: '1.23',
                        fondo_e: '3.06',
                        periodo: '2018'
                    },
                    {
                        fondo_a: '17.14',
                        fondo_b: '14.29',
                        fondo_c: '14.224',
                        fondo_d: '12.13',
                        fondo_e: '8.16',
                        periodo: '2019'
                    },
                    {
                        fondo_a: '1.36',
                        fondo_b: '3.00',
                        fondo_c: '3.57',
                        fondo_d: '3.24',
                        fondo_e: '3.22',
                        periodo: '2020'
                    },
                    {
                        fondo_a: '13.22',
                        fondo_b: '7.47',
                        fondo_c: '-2.83',
                        fondo_d: '-9.85',
                        fondo_e: '-12.91',
                        periodo: '2021'
                    },
                    {
                        fondo_a: '-30.46',
                        fondo_b: '-15.15',
                        fondo_c: '-8.32',
                        fondo_d: '1.58',
                        fondo_e: '8.39',
                        periodo: '2022'
                    },
                    {
                        fondo_a: '7.11',
                        fondo_b: '6.05',
                        fondo_c: '3.01',
                        fondo_d: '-0.25',
                        fondo_e: '-1.60',
                        periodo: '2023'
                    }
                ],
                titulo_buscador: 'Buscador de valor cuotaaa',
                texto_desde: 'Desdeee',
                texto_hasta: 'Hastaa',
                boton_buscar: 'Buscarr',
                boton_descargar: 'Descargar Excell'
            }
        }
    }],
    failures: []
};
