<div class="modal modal-get-certificate">
  <div class="modal-header">
    <ion-button
      class="button transparent close-modal"
      [ngClass]="font?.size"
      (click)="closeModal()"
      (keydown)="handleKeyDownForClose($event)">
      <ion-icon class="icon" name="close"></ion-icon>
    </ion-button>
    <div class="title-container">
      <div class="title-icon i-certificates-red"></div>
      <div class="title center-title" [ngClass]="font?.size" [innerHTML]="title | translate"></div>
      <div class="description" [ngClass]="font?.size" [innerHTML]="textContent?.description | translate"></div>
    </div>
  </div>

  <form class="get-certificate-form" [formGroup]="certificateForm">
    <div class="item-form">
      <div class="options">
        <app-drop-down-period *ngIf="includeControl('periodCode')" (selectedPeriodCode)="selectedPeriod($event)"
          [fieldsInput]="this.fields" [type]="this.type"></app-drop-down-period>

        <mat-form-field [ngClass]="font?.size" *ngIf="includeControl('productType')">
          <mat-label class="label">{{ textContent?.productTypeLabel | translate }}</mat-label>
          <mat-select formControlName="productType" [ngClass]="font?.size">
            <mat-option [ngClass]="font?.size" *ngFor="let productTypeOption of productTypeOptions"
              [value]="productTypeOption.id">
              {{ productTypeOption.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="options" *ngIf="includeControl('periodCode') && customPeriodSelected">
        <mat-form-field [ngClass]="font?.size">
          <input disabled [ngClass]="font?.size" matInput [min]="minStartDate" [max]="maxStartDate"
            [matDatepicker]="fromDate" [placeholder]="textContent?.fromDateLabel | translate" [formControl]="startDateControl"
            disabled="true">
          <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
          <mat-datepicker #fromDate startView="multi-year" disabled="false"></mat-datepicker>
        </mat-form-field>
        <mat-form-field [ngClass]="font?.size">
          <input disabled [ngClass]="font?.size" matInput [min]="minEndDate" [max]="todayCalendar"
            [matDatepicker]="toDate" [placeholder]="textContent?.toDateLabel | translate" [formControl]="endDateControl"
            disabled="true">
          <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
          <mat-datepicker #toDate startView="multi-year" disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="item-form">
        <ion-item class="rut-box">
          <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ textContent?.rutLabel | translate }}
          </ion-label>
          <ion-input formControlName="rut" type="text" maxLength="12" [formatRut]="rutControl" required></ion-input>
        </ion-item>
      </div>
    </div>
  </form>

  <div class="centered-container">
    <ion-button
      class="button action primary"
      [ngClass]="font?.size"
      [disabled]="certificateForm.invalid"
      (keydown)="handleKeyDownForCertificate($event)"
      (click)="getCertificate()">{{ textContent?.getCertificateButtonText | translate }}</ion-button>
  </div>
</div>