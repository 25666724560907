import { RELEVANT_INFO_TITLES } from '@constants/titles.constant';
import { AnnualMemoryContent } from '@interfaces/annual-memory.interface';

const pageKey = 'CORPORATION.RELEVANT_INFO.ANNUAL_MEMORY';
export const ANNUAL_MEMORY_CONTENT: AnnualMemoryContent = {
  header: {
    firstLine: `${pageKey}.HEADER_TITLE`,
    icon: 'analysis',
    image: {
      alt: 'Memoria anual',
      url: 'assets/images/banner-general1.5x.webp',
    },
    image_mobile: {
      alt: 'Memoria anual',
      url: 'assets/images/banner-general1.5x.webp',
    },
  },
  title: RELEVANT_INFO_TITLES.content.annualMemory,
  description: ``,
  yearTitle: `${pageKey}.YEAR_TITLE`,
  memories: []
};

