import { RELEVANT_INFO_TITLES } from '@constants/titles.constant';
import { EssentialFactsContent } from '@interfaces/corporation.interface';

const pageKey = 'CORPORATION.RELEVANT_INFO.ESSENTIAL_FACTS';

export const ESSENTIAL_FACTS_CONTENT: EssentialFactsContent = {
  header: {
    firstLine: `${pageKey}.HEADER_TITLE`,
    icon: 'analysis',
    image: {
      alt: 'Hechos esenciales',
      url: 'assets/images/banner-general1.5x.webp',
    },
    image_mobile: {
      alt: 'Hechos esenciales',
      url: 'assets/images/banner-general1.5x.webp',
    },
  },
  title: RELEVANT_INFO_TITLES.content.essentialFacts,
  content: []
};
