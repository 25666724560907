import { Component } from '@angular/core';
import { PENSION_MODES_CONTENT } from '@constants/pages-content/pension-modes.constant';
import { PensionModes } from '@interfaces/future-pensioner.interface';
import { FontService } from '@providers/font/font.service';
import { LanguageProvider } from '@providers/language/language.service';
import { CmsService } from '@services/cms/cms.service';

@Component({
  selector: 'app-pension-modes-component',
  templateUrl: './pension-modes.component.html',
  styleUrls: ['./pension-modes.component.scss'],
})

export class PensionModesComponent  {
  public pensionMode: PensionModes = PENSION_MODES_CONTENT;
  public loading = false;

  constructor(
    protected cmsService: CmsService,
    public font: FontService,
    public languageProvider: LanguageProvider,
  ) {
    this.loadPensionModesContent();
   }

   private loadPensionModesContent(): void {
    this.loading = true;
    this.cmsService.loadPensionModesContent().toPromise().then(response => {
      this.pensionMode = response;
    }).finally(() => this.loading = false);
   }
}
