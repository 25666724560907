<ion-content class="content">
  <app-banner [banner]="pageContent.header" [loading]="loading"></app-banner>
  <app-breadcrumb class="breadcrumb" [pathNames]="breadcrumb"></app-breadcrumb>
  <div class="information-container general-container">
    <ion-skeleton-text *ngIf="loading" class="skeleton-title" animated></ion-skeleton-text>
    <ion-skeleton-text *ngIf="loading" class="skeleton-medium" animated></ion-skeleton-text>
    <ion-skeleton-text *ngIf="loading" class="skeleton-medium" animated></ion-skeleton-text>
    <h2 *ngIf="!loading" class="title" [ngClass]="font?.size">{{ pageContent.content.title }}</h2>
    <h3 *ngIf="!loading" class="subtitle" [ngClass]="font?.size"><strong>{{ pageContent.content.ordinary_title }}</strong></h3>
    <app-accordion-download-list [list]="pageContent.content.ordinary_section" [loading]="loading"></app-accordion-download-list>
    <h3 *ngIf="!loading" class="subtitle" [ngClass]="font?.size"><strong>{{ pageContent.content.extraordinary_title }}</strong></h3>
    <app-accordion-download-list [list]="pageContent.content.extraordinary_section" [loading]="loading"></app-accordion-download-list>
  </div>
  <app-footer></app-footer>
</ion-content>
