<ng-container [ngSwitch]="section">

  <ng-container *ngSwitchCase="'guiaVital'">

    <ion-card class="article-card button-center" [ngClass]="articleTypeClass">
      <div class="container-image" [ngClass]="font?.size">
        <img class="article-solo-img" src="{{ article?.image.url }}" alt="{{ article?.image.alt }}" title="{{article?.image.title}}">
        <div [ngClass]="{'container-main-article-text' : articleTypeClass !== 'article-secondary', 'container-article-text' : articleTypeClass === 'article-secondary'}">
          <p class="article-text" [innerHtml]="article?.title" ></p>
        </div>
      </div>
      <button class="button-guia-vital secondary button-redirect" (click)="openNewTabPage()">
        {{ type === 'primary' ? 'Ir a Tu Guía Vital' : 'Ver más'}}
      </button>
    </ion-card>

  </ng-container>
  <ng-container *ngSwitchCase="'homeArticles'">

    <ion-card class="article-card button-center" [ngClass]="articleTypeClass">
      <div class="container-image" [ngClass]="font?.size">
        <img class="article-solo-img" src="{{ article?.image.url }}" alt="{{ article?.image.alt }}" title="{{article?.image.title}}">
      </div>
      <button class="button-guia-vital secondary button-redirect" (click)="openNewTabPage()">
        Ver más
      </button>
    </ion-card>

  </ng-container>
  <ng-container *ngSwitchDefault>

    <ion-card class="article-card" [ngClass]="articleTypeClass">
      <img class="article-img" src="{{ article?.image.url }}" alt="{{ article?.image.alt }}" title="{{article?.image.title}}">
      <div class="article-more-info" [ngClass]="font?.size">
        <p *ngIf="!loading" class="title-article" [innerHTML]="article?.title | translate"></p>
        <ion-skeleton-text *ngIf="loading" class="skeleton-line title-article" animated></ion-skeleton-text>
        <div *ngIf="!loading" class="action-article" (click)="openPage()">
          <p class="more-information">{{ moreInfoText | translate }}</p>
          <div class="i-more-red"></div>
        </div>
      </div>
    </ion-card>

  </ng-container>
</ng-container>
