import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from '@environment';
import { RedirectGuard } from '@guards/redirect.guard';

const routes: Routes = [
  { path: '', redirectTo: 'afiliado', pathMatch: 'full' },
  { path: 'buscar', redirectTo: 'afiliado', pathMatch: 'full' },
  { path: 'preguntas-frecuentes/pensionado', redirectTo: 'preguntas_frecuentes', pathMatch: 'full' },
  { path: 'informacion-util/preguntas-frecuentes', redirectTo: 'preguntas_frecuentes', pathMatch: 'full' },
  { path: 'preguntas-frecuentes/empleador', redirectTo: 'preguntas_frecuentes', pathMatch: 'full' },
  { path: 'afiliado', loadChildren: () => import('./modules/affiliate/affiliate.module').then(m => m.AffiliateModule) },
  { path: 'pensionado', loadChildren: () => import('./modules/pensioner/pensioner.module').then(m => m.PensionerModule) },
  { path: 'empleador', loadChildren: () => import('./modules/employer/employer.module').then(m => m.EmployerPageModule) },
  { path: 'quienes_somos', loadChildren: () => import('./modules/corporation/about-us/about-us.module').then(m => m.AboutUsModule) },
  { path: 'inversionistas', loadChildren: () => import('./modules/corporation/investors/investors.module').then(m => m.InvestorsModule) },
  { path: 'informacion-util/preguntas-frecuentes', loadChildren: () => import('./modules/faqs/faqs.module').then(m => m.FaqsPageModule) },
  { path: 'buscar/:id', loadChildren: () => import('./modules/search/search.module').then(m => m.SearchPageModule) },
  { path: 'informacion-util', loadChildren: () => import('./modules/corporation/relevant-information/relevant-information.module').then(m => m.RelevantInformationModule) },
  {
    path: 'sucursales',
    canActivate: [RedirectGuard],
    loadChildren: () => import('./modules/branch-offices/branch-offices.module').then(m => m.BranchOfficesPageModule),
    data: { externalBranchUrl: environment.urlLocalizatorBranchStores }
  },
  {
    path: 'corporacion',
    loadChildren: () => import('./modules/corporation/corporation.module').then(m => m.CorporationModule)
  },
  { path: '**', redirectTo: 'afiliado/inicio', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollOffset: [0, 0], scrollPositionRestoration: 'top' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
