import { Translation } from './cms.interface';

export interface FinancialStatements {
  traducciones: Array<FinancialStatementsTranslate>;
}

export interface FinancialStatementsTranslate extends Translation {
  contenido: FinancialStatementsContent;
}

export interface FinancialStatementsContent {
  titulos: FinancialStatementTitles;
  estados_financieros: Array<FinancialStatement>;
}

export interface FinancialStatement {
  fecha: string;
  titulo: string;
  subtitulo: string;
  administradora: Array<Link>;
  fondo: Array<Link>;
}

export interface FinancialStatementTitles {
  titulo_administradora: string;
  titulo_fondos: string;
}

export interface EssentialFacts {
  traducciones: Array<EssentialFactsTranslate>;
}

export interface EssentialFactsTranslate extends Translation {
  contenido: EssentialFactsContent;
}

export interface EssentialFactsContent {
  hechos_esenciales: Array<LinkCollection>;
}

export interface InformationOfInterest {
  traducciones: Array<InformationOfInterestTranslate>;
}

export interface InformationOfInterestTranslate extends Translation {
  contenido: InformationOfInterestContent;
}
export interface InformationOfInterestContent {
  informacion_de_interes: Array<LinkCollection>;
}

export interface CorporateGovernance {
  traducciones: Array<CorporateGovernanceTraducciones>;
}

export interface CorporateGovernanceTraducciones extends Translation {
  contenido: CorporateGovernanceContent;
}

export interface CorporateGovernanceContent {
  certificado: LinkCollection;
  gobierno_corporativo: Array<LinkCollection>;
}

export interface AnnualMemory {
  memorias_anuales: Array<AnnualMemoryFilesId>;
}

export interface AnnualMemoryFilesId {
  memorias_archivos_id: AnnualMemoryFiles;
}

export interface AnnualMemoryFiles {
  titulo: string;
  archivo: Archive;
}

export interface Archive {
  filename_disk: string;
  filename_download: string;
}

export interface AnnualMemoryTranslate extends Translation {
  contenido: AnnualMemoryContent;
}

export interface AnnualMemoryContent {
  memoria_anual: Array<Link>;
}

export interface AfpPolicies {
  traducciones: Array<AfpPoliciesTranslate>;
}

export interface AfpPoliciesTranslate extends Translation {
  contenido: AfpPoliciesContent;
}

export interface AfpPoliciesContent {
  politicas_afp: Array<LinkCollection>;
}

export interface LinkCollection {
  año?: string;
  titulo: string;
  items: Array<Link>;
}

export interface Link {
  fecha?: string;
  titulo: string;
  url: string;
}

export interface AdministrationWorkers {
  traducciones: Array<AdministrationWorkersTranslate>;
}

export interface AdministrationWorkersTranslate extends Translation {
  contenido: AdministrationWorkersContent;
}

export interface AdministrationWorkersContent {
  directorio: Array<Workers>;
  ejecutivos: Array<Workers>;
}

export interface Workers {
  nombre: string;
  cargo: string;
  url: string;
}
