import { Injectable } from '@angular/core';
import { EMPLOYER_CURRENT_COMISSIONES_HEADER_ID, FOOTER_ADMINISTRATION_HEADER_ID, FOOTER_ESSENTIAL_FACTS_HEADER_ID, FOOTER_FINANTIAL_STATEMENTS_HEADER_ID, FOOTER_INFORMATION_OF_INTEREST_HEADER_ID } from '@constants/banners.constant';

import { FUNDS_WITHDRAWAL_CONTENT } from '@constants/pages-content/funds-withdrawal.constant';
import { FUTURE_PENSIONER_CONTENT } from '@constants/pages-content/future-pensioner.constant';
import { MULTI_FUNDS_CONTENT } from '@constants/pages-content/multi-funds.constant';
import { LANGUAGE_KEY } from '@constants/translate.constant';
import { environment } from '@environment';
import { AdditionalPensionerBenefitsData } from '@interfaces/additional-benefits-pensioner.interface';
import { AdministrationContentData, AdministrationWorkers, Directory, WorkerContent } from '@interfaces/administration.interface';
import {
  Banner,
  BannerTranslation,
  HeaderItem,
  HeaderTranslation,
} from '@interfaces/banner.interface';
import { AdditionalBenefits } from '@interfaces/benefits.interface';
import { CalendarContentData, CalendarItemCms, CalendarItems } from '@interfaces/calendar.interface';
import {
  AfpPolicies,
  AnnualMemory,
  AdministrationWorkers as CmsAdministrationWorkers,
  Link as CmsLink,
  EssentialFacts,
  LinkCollection,
  Workers
} from '@interfaces/cms-corporate.interface';
import {
  AlertBannerItem,
  AlertBannerTranslation,
  ArticleItem,
  GeneralInfoItem,
  ImageItem,
  LinkItem,
} from '@interfaces/cms.interface';
import { CommissionsDisclaimerData, CommissionsDisclaimerSection } from '@interfaces/commissions-disclaimer.interface';
import { CompliantsChannelContent, CompliantsChannelData } from '@interfaces/compliants-channel.interface';
import { AlertBanner, Article, GeneralInfo, GuiaVitalItem, Link } from '@interfaces/components.interface';
import { CorporateGovernanceData } from '@interfaces/corporate-governance.interface';
import { CrimePreventionModelContent, CrimePreventionModelData } from '@interfaces/crime-prevention-model.interface';
import { CurrentComissionsData, CurrentCommissionsContent, Secciones, SectionData } from '@interfaces/current-commissions.interface';
import {
  EmployerHome,
  FamilyAllowance,
  FamilyAllowanceItem,
  FamilyAllowanceTable,
  PasswordItem,
  PasswordMapped
} from '@interfaces/employer.interface';
import { EssentialFactsData } from '@interfaces/essential-facts.interface';
import { FaqsContent, FaqsItem, FaqsSectionContent, SectionsItem } from '@interfaces/faqs.interface';
import {
  EconomicIndicatorsInfo,
  EconomicIndicatorsItem,
  FinancialInformation,
  FinancialInformationItem,
  InvestmentPortfolioGraphics,
  InvestmentPortfolioInfo,
  InvestmentPortfolioItems,
  QuotaValueInformation,
} from '@interfaces/financial-information.interface';
import { FinancialStatementData } from '@interfaces/financial-statements.interace';
import { FooterColumn, FooterItem } from '@interfaces/footer.interface';
import { FundsWithdrawal, FundsWithdrawalItem } from '@interfaces/fund-withdrawal';
import { FuturePensioner, FuturePensionerItem, PensionModes, PensionOffers, PensionTypes } from '@interfaces/future-pensioner.interface';
import { DownloadContentItems, DownloadContent, DownloadSection, Section, UrlSection, UrlSectionContent, DownloadSectionContent } from '@interfaces/general.interface';
import { GeneraliGroupContent, GeneraliGroupData } from '@interfaces/generali-group.interface';
import { AffiliateHomeItem, EmployerHomeItem, Home } from '@interfaces/home.interface';
import { IconCardStyle } from '@interfaces/icon-card.interface';
import { Card, Modal } from '@interfaces/info-card.interface';
import { Infographic, InfographicItem } from '@interfaces/infographic.interface';
import { InformationOfInteresetData } from '@interfaces/information-of-interest.interface';
import { MortuaryQuotaRelationshipsItem } from '@interfaces/mortuary-quota-form.interface';
import {
  MultiFunds,
  MultiFundsItem,
  ProfitabilityTableItem,
  ProfitabilityTableItemJson
} from '@interfaces/multi-funds.interface';
import { OurCompanyContent, OurCompanyData } from '@interfaces/our-company.interface';
import { PensionModesData } from '@interfaces/pension-modes.interface';
import { Calendar, CalendarCms } from '@interfaces/pension-payment.interface';
import { PensionTypesContentData } from '@interfaces/pension-types.interface';
import { Product, ProductItem } from '@interfaces/product.interface';
import { QuotaValuesBanner, QuotaValuesBannerItem } from '@interfaces/quota-values-banner.interface';
import { QuotaValuesContentData } from '@interfaces/quota-values.interface';
import * as Saving from '@interfaces/savings.interface';
import { ScompData } from '@interfaces/scomp.interface';
import { ShareholdersMeetingContent, ShareHoldersMeetingData } from '@interfaces/shareholders-meeting.interface';
import { Simulators, SimulatorsData } from '@interfaces/simulators.interface';
import { SuperintendenceBanner, SuperintendenceBannerItem } from '@interfaces/superintendence-banner.interface';
import { TransferState, TransferStateItem } from '@interfaces/transfer-state.interface';
import { TransferItem, WhyTransfer } from '@interfaces/why-transfer.interface';
import { MANDATORY_SAVINGS_CONTENT } from '@pages-content/mandatory-savings.constant';
import { SECONDARY_ACCOUNT_CONTENT } from '@pages-content/secondary-account.constant';
import { VOLUNTARY_SAVINGS_CONTENT } from '@pages-content/voluntary-savings.constant';

@Injectable()
export class CmsUtils {

  public mapHome(
    homeItem: AffiliateHomeItem, articles: Array<Article>, banners: Array<Banner>, products: Array<Product>,
    guiaVitalItems: Array<GuiaVitalItem>, language: string): Home {
    const selectedBanners = homeItem.banners.map(banner => banner.banners_id);
    const login = homeItem.login.find(item => item.languages_code === language);
    const mainActions = homeItem.acciones_principales.find(item => item.languages_code === language);
    const selectedProducts = homeItem.productos.map(product => product.productos_id);
    const productsContainer = homeItem.contenedor_productos.find(item => item.languages_code === language);
    const selectedArticles = homeItem.articulos.map(article => article.articulos_id);
    const articlesContainer = homeItem.contenedor_articulos.find(item => item.languages_code === language);
    const selectedGuiaVital = homeItem.tuguiavital.map(item => item.tu_guia_vital_id);
    const guiaVitalContainer = homeItem.contenedor_tuguiavital.find(item => item.languages_code === language);
    const selectedMoreArticles = homeItem.mas_articulos.map(article => article.articulos_id);
    const fundsContainer = homeItem.valores_cuota.find(item => item.languages_code === language);

    return {
      id: homeItem.id,
      banners: banners.filter(banner => selectedBanners.includes(banner.id)),
      productsContainer: {
        title: productsContainer ? productsContainer.titulo : null,
        subtitle: productsContainer ? productsContainer.subtitulo : null,
        products: products.filter(product => selectedProducts.includes(product.id)),
      },
      login: {
        title: login ? login.titulo : null,
        rut: login ? login.rut : null,
        password: login ? login.clave : null,
        loginButton: login ? login.boton_ingresar : null,
        forgotPasswordMessage: login ? login.recuperar_clave : null,
      },
      mainActions: {
        leftIcon: homeItem.icono_accion_izquierda,
        leftAction: mainActions ? mainActions.mensaje_izquierda : null,
        leftActionButton: mainActions ? mainActions.boton_izquierda : null,
        rightIcon: homeItem.icono_accion_derecha,
        rightAction: mainActions ? mainActions.mensaje_derecha : null,
        rightActionButton: mainActions ? mainActions.boton_derecha : null,
      },
      articlesContainer: {
        title: articlesContainer ? articlesContainer.titulo : null,
        subtitle: articlesContainer ? articlesContainer.subtitulo : null,
        articles: articles.filter(article => selectedArticles.includes(article.id)),
        moreInfoText: articlesContainer ? articlesContainer.mas_informacion : null,
        disclaimer: articlesContainer ? articlesContainer.texto_inferior : null,
        disclaimerButton: articlesContainer ? articlesContainer.boton : null,
        disclaimerUrl: homeItem.url_boton_articulos,
      },
      moreArticlesContainer: {
        title: null,
        subtitle: null,
        articles: articles.filter(article => selectedMoreArticles.includes(article.id)),
        moreInfoText: articlesContainer ? articlesContainer.mas_informacion : null,
      },
      fundsContainer: {
        title: fundsContainer ? fundsContainer.titulo : null,
        subtitle: fundsContainer ? fundsContainer.texto_fecha : null,
        fundName: fundsContainer ? fundsContainer.fondo : null,
        chartTitle: fundsContainer ? fundsContainer.titulo_grafico : null,
        disclaimer: fundsContainer ? fundsContainer.texto_inferior : null,
        disclaimerButton: fundsContainer ? fundsContainer.boton : null,
        disclaimerUrl: homeItem.url_boton_valores_cuota,
      },
      guiaVitalContainer: {
        title: guiaVitalContainer ? guiaVitalContainer.titulo : null,
        subtitle: guiaVitalContainer ? guiaVitalContainer.subtitulo : null,
        articles: guiaVitalItems.filter(item => selectedGuiaVital.includes(item.id)),
        moreInfoText: guiaVitalContainer ? guiaVitalContainer.mas_informacion : null,
      },
      faqContainer: {
        title: homeItem.titulo_preguntas ? homeItem.titulo_preguntas : null,
        subTitle: homeItem.descripcion_preguntas ? homeItem.descripcion_preguntas : null,
        faq:homeItem.preguntas ? homeItem.preguntas.map(faq => {
          return { title: faq.faq_id.pregunta, description: faq.faq_id.respuesta };
        }) : []
      },
    };
  }

  public mapEmployerHome(homeItem: EmployerHomeItem, articles: Array<Article>, banners: Array<Banner>, language: string): EmployerHome {
    const selectedBanners = homeItem.banners.map(banner => banner.banners_id);
    const selectedArticles = homeItem.articulos.map(article => article.articulos_id);
    const articlesContainer = homeItem.contenedor_articulos.find(item => item.languages_code === language);
    return {
      banners: banners.filter(banner => selectedBanners.includes(banner.id)),
      articlesSection: {
        title: articlesContainer ? articlesContainer.titulo : null,
        subtitle: articlesContainer ? articlesContainer.subtitulo : null,
        articles: articles.filter(article => selectedArticles.includes(article.id)),
        moreInfoText: articlesContainer ? articlesContainer.mas_informacion : null,
      }
    };
  }

  public mapFamilyAllowance(familyAllowanceItem: FamilyAllowanceItem, banners: Array<Banner>, language: string): FamilyAllowance {
    const selectedBanner = familyAllowanceItem.banner;
    const familyAllowanceContainer = familyAllowanceItem.asignacion_familiar_seccion.find(item => item.languages_code === language);
    const monthlyCapsContainer = familyAllowanceItem.topes_mensuales.find(item => item.languages_code === language);
    return {
      banner: banners.find(banner => selectedBanner === banner.id),
      familyAllowanceSection: {
        title: familyAllowanceContainer ? familyAllowanceContainer.titulo : null,
        description: familyAllowanceContainer ? familyAllowanceContainer.descripcion : null,
        buttonLabel: familyAllowanceContainer ? familyAllowanceContainer.texto_boton : null,
        buttonUrl: familyAllowanceContainer ? familyAllowanceContainer.url_boton : null,
        table: familyAllowanceContainer ? this.tableFormat(familyAllowanceContainer.tabla) : null
      },
      monthlyCaps: {
        title: monthlyCapsContainer ? monthlyCapsContainer.titulo : null,
        description: monthlyCapsContainer ? monthlyCapsContainer.descripcion : null,
        buttonLabel: monthlyCapsContainer ? monthlyCapsContainer.texto_boton : null,
        buttonUrl: monthlyCapsContainer ? monthlyCapsContainer.url_boton : null,
        table: monthlyCapsContainer ? this.tableFormat(monthlyCapsContainer.tabla) : null
      }
    };
  }

  public mapCurrentCommissionsValues(currentCommissionsResponse: CurrentComissionsData, headers: Array<Banner>): CurrentCommissionsContent {
    const header = this.mapHeaderImages(headers, EMPLOYER_CURRENT_COMISSIONES_HEADER_ID);
    if (!currentCommissionsResponse) {
      return {
        header,
        disclaimerTitle: '',
        disclaimerContent: '',
        tableTitle: '',
        informationUpdateDate: '',
        linkDescription: '',
        linkUrl: '',
        sections: []
      };
    }
    return {
      header,
      disclaimerContent: currentCommissionsResponse.descripcion,
      disclaimerTitle: currentCommissionsResponse.titulo_descripcion,
      tableTitle: currentCommissionsResponse.titulo_cabecera,
      informationUpdateDate: currentCommissionsResponse.fecha_actualizacion,
      linkDescription: currentCommissionsResponse.link_descripcion,
      linkUrl: currentCommissionsResponse.link_url,
      sections: this.mapCommissionsSections(currentCommissionsResponse.secciones)
    };
  }

  private mapCommissionsSections(sections: Secciones[]): SectionData[] {
    return sections.map(section => ({
      sectionTitle: section.comisiones_vigentes_seccion_id.titulo,
      subsection: section.comisiones_vigentes_seccion_id.subseccion.map(subsection => ({
        disclaimerText: subsection.comisiones_vigentes_subseccion_id.texto_disclaimer,
        sectionTitle: subsection.comisiones_vigentes_subseccion_id.titulo,
        items: subsection.comisiones_vigentes_subseccion_id.subseccion_items.map(item => ({
          description: item.comisiones_vigentes_items_id.descripcion,
          value: item.comisiones_vigentes_items_id.valor
        }))
      }))
    }));
  }

  public mapFinancialStatements(financialStatementsItem: FinancialStatementData, headers: Array<Banner>, language = 'es'): DownloadContentItems {
    const header = headers.find(item => item.id === FOOTER_FINANTIAL_STATEMENTS_HEADER_ID);
    if (!financialStatementsItem) { return { downloadContent: [], header }; }
    const downloadContent: Array<DownloadContent> = [];
    const administrationTitle = financialStatementsItem.texto_administradora;
    const fundTitle = financialStatementsItem.texto_fondo;
    const financialStatements = financialStatementsItem.estados;

    financialStatements.forEach((statement) => {
      const downloadSectionList: Array<DownloadSection> = [];
      let urlSectionList = statement.estados_financieros_seccion_id.administradora.map(administration => {
        return {
          title: administration.estados_finacieros_archivo_id.titulo,
          url: this.getPDFUrl(administration.estados_finacieros_archivo_id.archivo.filename_disk)
        };
      });
      downloadSectionList.push({
        title: `<p><strong>${statement.estados_financieros_seccion_id.subtitulo}</strong></p><p>${administrationTitle}</p>`,
        urlSectionList
      });
      urlSectionList = statement.estados_financieros_seccion_id.fondo.map(fund => {
        return {
          title: fund.estados_finacieros_archivo_id.titulo,
          url: this.getPDFUrl(fund.estados_finacieros_archivo_id.archivo.filename_disk)
        };
      });
      downloadSectionList.push({ title: `<p>${fundTitle}</p>`, urlSectionList });
      downloadContent.push({ title: statement.estados_financieros_seccion_id.titulo, downloadSectionList });
    });
    return { downloadContent, header };
  }

  public mapEssentialFacts(essentialFactsItem: EssentialFactsData, headers: Array<Banner>, language = 'es'): DownloadContentItems {
    const header = headers.find(item => item.id === FOOTER_ESSENTIAL_FACTS_HEADER_ID);
    if (!essentialFactsItem) { return { downloadContent: [], header }; }
    const factsData: DownloadContentItems = {
      downloadContent: essentialFactsItem.periodos.map(period => {
        const downloadSectionList: Array<DownloadSection> = [];
        period.hechos_esenciales_seccion_id.archivos.forEach(file => {
          downloadSectionList.push({
            title: undefined,
            urlSectionList: [{
              title: file.hechos_esenciales_archivo_id.fecha + ' | ' + file.hechos_esenciales_archivo_id.titulo,
              url: this.getPDFUrl(file.hechos_esenciales_archivo_id.archivo.filename_disk)
            }]
          });
        });
        return {
          title: period.hechos_esenciales_seccion_id.titulo,
          downloadSectionList
        };
      }),
      header
    }; 
    return factsData;
  }

  public mapInformationOfInterest(informationOfInterestItem: InformationOfInteresetData, headers: Array<Banner>, language = 'es'):
    DownloadContentItems {
    const header = headers.find(item => item.id === FOOTER_INFORMATION_OF_INTEREST_HEADER_ID);
    if (!informationOfInterestItem) { return { downloadContent: [], header }; }
    const downloadContentData: DownloadContentItems = {
      downloadContent: informationOfInterestItem.secciones.map(section => {
        const downloadSectionList: Array<DownloadSection> = [];
        section.informacion_interes_secciones_id.archivos.forEach(file => {
          downloadSectionList.push({
            title: undefined,
            urlSectionList: [{
              title: file.informacion_interes_archivos_id.titulo,
              url: this.getPDFUrl(file.informacion_interes_archivos_id.archivo.filename_disk)
            }]
          });
        });
        return {
          title: section.informacion_interes_secciones_id.titulo,
          downloadSectionList
        };
      }),
      header
    };
    return downloadContentData;
  }

  public mapCorporateGovernance(corporateGovernanceItem: CorporateGovernanceData, language = 'es'): {
    topics: Array<DownloadSection>; certificate: DownloadSection;
  } {
    if (!corporateGovernanceItem) { return; }
    const downloadData: Array<DownloadSection> = corporateGovernanceItem.secciones.map(section => {
      return {
        title: section.gobierno_corporativo_secciones_id.titulo,
        urlSectionList: section.gobierno_corporativo_secciones_id.archivos.map(file => {
          return {
            title: file.gobierno_corporativo_archivos_id.titulo,
            url: this.getPDFUrl(file.gobierno_corporativo_archivos_id.archivo.filename_disk)
          };
        })
      };
    });
    return {
      topics: downloadData,
      certificate: null
    };
  }

  public mapAnnualMemoryValues(annualMemoryItem: AnnualMemory, header: Banner, language = 'es'): UrlSectionContent {
    if (!annualMemoryItem) { return { urlSection: [], header }; }
    const annualMemory = annualMemoryItem.memorias_anuales;
    const infoLinks = annualMemory.map(item => {
      return { titulo: item.memorias_archivos_id.titulo, url: item.memorias_archivos_id.archivo.filename_disk };
    });
    return { urlSection: this.parseToDownloadSectionCms(infoLinks).urlSectionList, header };
  }

  public mapAfpPoliciesValues(afpPoliciesItem: AfpPolicies, header: Banner, language = 'es'): DownloadSectionContent {
    if (!afpPoliciesItem) { return { downloadSection: [], header }; }
    const traducciones = afpPoliciesItem.traducciones.find(item => item.languages_code === language);
    const afpPoliciesContent = traducciones.contenido;
    const afpPolicies = afpPoliciesContent.politicas_afp;
    return { downloadSection: this.parseToDownloadSectionArray(afpPolicies), header };
  }

  public mapFaqs(faqs: FaqsItem, faqsSections: Array<FaqsSectionContent>, language: string): FaqsContent {
    const selectedFaqsSections = faqs.secciones_preguntas.map(seccion => seccion.secciones_preguntas_id);
    const traducciones = faqs.traducciones.find(item => item.languages_code === language);
    return {
      title: traducciones ? traducciones.titulo : null,
      sections: faqsSections.filter(section => selectedFaqsSections.includes(section.id as number))
    };
  }

  public mapFaqsSections(faqsSections: Array<SectionsItem>, language: string): Array<FaqsSectionContent> {
    return faqsSections.map(section => {
      const traducciones = section.traducciones.find(item => item.languages_code === language);
      return {
        id: section.id,
        name: traducciones ? traducciones.nombre : null,
        faqs: traducciones ? this.getFaqsFromSection(traducciones.preguntas) : null
      };
    });
  }

  private getFaqsFromSection(preguntas: { [key: string]: any; }): Array<Section> {
    if (preguntas.data) {
      const result = preguntas.data.reduce((acc, item) => {
        const tempKey = Object.keys(item)[0];
        acc[tempKey] = item[tempKey];
        return acc;
      }, {});
      preguntas = result;
    }
    return Object.keys(preguntas).map((key) => {
      return { title: key, description: preguntas[key] };
    });
  }

  public mapWhyTransfer(
    transferItem: TransferItem, articles: Array<Article>, infographics: Array<Infographic>, language: string): WhyTransfer {
    const selectedArticles = transferItem.articulos.map(article => article.articulos_id);
    const selectedInfographics = transferItem.carrusel.map(item => item.infografias_id);
    const traducciones = transferItem.traducciones.find(item => item.languages_code === language);
    return {
      id: transferItem.id,
      header: {
        image: {
          url: transferItem.imagen_header ? this.getImageUrl(transferItem.imagen_header.filename_disk) : null,
          alt: traducciones ? traducciones.alt_imagen_header : null,
          title: traducciones ? traducciones.title_image : null,
        },
        image_mobile: {
          url: transferItem.imagen_header_mobile ? this.getImageUrl(transferItem.imagen_header_mobile.filename_disk) : null,
          alt: traducciones ? traducciones.alt_imagen_header : null,
          title: traducciones ? traducciones.title_image : null,
        },
        icon: transferItem.icono_header,
        firstLine: traducciones ? traducciones.header : null,
      },
      mainAction: {
        disclaimer: traducciones ? traducciones.texto_traspaso_header : null,
        disclaimerButton: traducciones ? traducciones.boton_traspaso : null,
        disclaimerUrl: transferItem.link_traspaso.url,
      },
      carouselTitle: traducciones ? traducciones.titulo_carrusel : null,
      carousel: infographics.filter(item => selectedInfographics.includes(item.id)),
      promoteApp: {
        body: traducciones ? traducciones.promover_aplicacion_movil : null,
        titleStores: traducciones ? traducciones.titulo_tiendas_aplicacion_movil : null
      },
      instructions: {
        body: traducciones ? traducciones.traspaso : null,
        disclaimer: traducciones ? traducciones.ir_a_traspaso : null,
        disclaimerButton: traducciones ? traducciones.boton_traspaso : null,
        disclaimerUrl: transferItem.link_traspaso.url,
      },
      articlesSection: {
        title: traducciones ? traducciones.titulo_articulos : null,
        articles: articles.filter(article => selectedArticles.includes(article.id)),
        disclaimer: traducciones ? traducciones.disclaimer_articulos : null,
        disclaimerButton: traducciones ? traducciones.boton_articulos : null,
        disclaimerUrl: transferItem.link_seccion_articulos.url,
      }
    };
  }

  public mapTransferState(transferItem: TransferStateItem, articles: Array<Article>, headers: Array<Banner>, language: string
  ): TransferState {
    const selectedArticles = transferItem.articulos.map(article => article.articulo_id);
    const header = headers.find(item => item.id === transferItem.header.id);
    const traducciones = transferItem.traducciones.find(item => item.languages_code === language);
    return {
      id: transferItem.id,
      header: {
        image: {
          url: header.image.url,
          alt: header.image.alt,
          title: null
        },
        image_mobile: {
          url: header.image_mobile.url,
          alt: header.image.alt,
          title: header.image.title
        },
        icon: header.icon,
        firstLine: header.firstLine,
      },
      statusInformation: {
        title: traducciones ? traducciones.titulo : null,
        subtitle: traducciones ? traducciones.subtitulo : null,
        body: traducciones ? traducciones.body : null,
      },
      transferStatusBox: {
        title: traducciones ? traducciones.titulo_formulario : null,
        rut: traducciones ? traducciones.rut : null,
        date: traducciones ? traducciones.fecha : null,
        button: traducciones ? traducciones.boton_formulario : null,
      },
      transferBanner: {
        text: traducciones ? traducciones.traspaso_texto : null,
        button: traducciones ? traducciones.traspaso_boton : null,
      },
      articlesSection: {
        title: traducciones ? traducciones.articulos_titulo : null,
        articles: articles.filter(article => selectedArticles.includes(article.id)),
        disclaimer: traducciones ? traducciones.articulos_texto : null,
        disclaimerButton: traducciones ? traducciones.articulos_boton : null,
        disclaimerUrl: transferItem.link_seccion_articulos ? transferItem.link_seccion_articulos.url : null,
      }
    };
  }

  public mapFooter(footerItem: FooterItem, links: Array<Link>, language: string): Array<FooterColumn> {
    const columnsLinks: Array<string> = Object.keys(footerItem).filter((element) => element.includes('links'));
    return columnsLinks.map((id, index) => this.createColumn(footerItem, links, language, id, index));
  }

  public mapSuperintendenceBanner(bannerItem: SuperintendenceBannerItem, language: string): SuperintendenceBanner {
    const traducciones = bannerItem.traducciones.find(traduccion => traduccion.languages_code === language);
    return {
      text: traducciones ? traducciones.texto : null,
      button: traducciones ? traducciones.texto_boton : null,
      url: bannerItem.link_superintendencia.url
    };
  }

  public mapQuotaValuesBanner(quotaValuesItem: QuotaValuesBannerItem, language: string): QuotaValuesBanner {
    const traducciones = quotaValuesItem.traducciones.find(traduccion => traduccion.languages_code === language);
    return {
      quotaValue: traducciones ? traducciones.valores_cuota : null,
      fund: traducciones ? traducciones.fondo : null,
    };
  }

  public mapArticles(articleItems: Array<ArticleItem>, language: string): Array<Article> {
    return articleItems.map(articleItem => {
      const articleTranslation = articleItem.traducciones.find(item => item.languages_code === language);
      const { id, identificador, imagen, url } = articleItem;
      return {
        id,
        url,
        identifier: identificador,
        image: {
          url: imagen ? this.getImageUrl(imagen.filename_disk) : null,
          alt: articleTranslation ? articleTranslation.alt_imagen : null,
          title: articleTranslation ? articleTranslation.title_image : null,
        },
        title: articleTranslation ? articleTranslation.titulo : null,
      };
    });
  }

  public mapGuiaVitalItems(articleItems: Array<ArticleItem>, language: string): Array<GuiaVitalItem> {
    const articles: Array<GuiaVitalItem> = [];
    articleItems.forEach(articleItem => {
      const articleTranslation = articleItem.traducciones.find(item => item.languages_code === language);
      const { id, identificador, imagen, url } = articleItem;
      const article: GuiaVitalItem = {
        id,
        url,
        identifier: identificador,
        image: {
          url: imagen.filename_disk ? this.getImageUrl(imagen.filename_disk) : null,
          alt: articleTranslation ? articleTranslation.alt_imagen : null,
          title: articleTranslation ? articleTranslation.title_image : null,
        },
        title: articleTranslation ? articleTranslation.titulo : null,
      };
      articles.push(article);
    });
    return articles;
  }

  public mapBanners(bannerItems: Array<HeaderItem<BannerTranslation>>, language: string): Array<Banner> {
    const banners: Array<Banner> = [];
    bannerItems.forEach(bannerItem => {
      const bannerTranslation = bannerItem.traducciones.find(item => item.languages_code === language);
      const banner: Banner = {
        id: bannerItem.id,
        image: {
          url: bannerItem.imagen ? this.getImageUrl(bannerItem.imagen.filename_disk) : null,
          alt: bannerTranslation ? bannerTranslation.alt_imagen : null,
          title: bannerTranslation ? bannerTranslation.title_image : null,
        },
        image_mobile: {
          url: bannerItem.imagen_mobile ? this.getImageUrl(bannerItem.imagen_mobile.filename_disk) : null,
          alt: bannerTranslation ? bannerTranslation.alt_imagen : null,
          title: bannerTranslation ? bannerTranslation.title_image : null,
        },
        icon: bannerItem.icono,
        firstLine: bannerTranslation ? bannerTranslation.primera_linea : null,
        secondLine: bannerTranslation ? bannerTranslation.segunda_linea : null,
        button: bannerTranslation ? bannerTranslation.boton : null,
        url: bannerItem.url,
      };
      banners.push(banner);
    });
    return banners;
  }

  public mapPaymentCenters(paymentCenterItems: Array<HeaderItem<BannerTranslation>>, language: string): Array<Card<any>> {
    return paymentCenterItems.map(paymentCenterItem => {
      const paymentCenterTranslation = paymentCenterItem.traducciones.find(item => item.languages_code === language);
      return {
        image: {
          url: paymentCenterItem.imagen ? this.getImageUrl(paymentCenterItem.imagen.filename_disk) : null,
          alt: paymentCenterTranslation ? paymentCenterTranslation.alt_imagen : null,
        },
        title: paymentCenterTranslation ? paymentCenterTranslation.primera_linea : null,
        requestText: paymentCenterTranslation ? paymentCenterTranslation.boton : null,
        requestUrl: paymentCenterItem.url,
      } as Card<any>;
    });
  }

  public mapCalendarItems(calendarItems: Array<CalendarItemCms>): CalendarItems {
    const calendars: CalendarItems = { servipagCalendar: [], bankCalendar: [], enrollmentCalendar: [] };
    calendarItems.forEach(calendarItem => {
      const sortedDates = Object.entries(calendarItem.fechas)
        .sort(([a], [b]) => this.getMonthIndex(a) - this.getMonthIndex(b))
        .map(([, value]) => value);

      calendars[calendarItem.titulo] = sortedDates;
    });
    return calendars;
  }

  private getMonthIndex(month: string): number {
    const monthOrder = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october',
      'november', 'december'];
    return monthOrder.indexOf(month.toLowerCase());
  }

  public mapCalendarCms(paymentCenters: Array<Card<any>>, calendars: CalendarItems): CalendarCms {
    return { calendars, paymentCenters };
  }

  public mapLinks(linkItems: Array<LinkItem>, language: string): Array<Link> {
    const links: Array<Link> = [];
    linkItems.forEach(linkItem => {
      const linkTranslation = linkItem.traducciones.find(item => item.languages_code === language);
      const link: Link = {
        id: linkItem.id,
        title: linkTranslation ? linkTranslation.titulo : null,
        url: linkItem.url,
        externo: linkItem.url.startsWith('http'),
      };
      links.push(link);
    });
    return links;
  }

  public mapInfographics(infographicItems: Array<InfographicItem>, language: string): Array<Infographic> {
    const infographics: Array<Infographic> = [];
    infographicItems.forEach(infographicItem => {
      const infographicTranslation = infographicItem.traducciones.find(item => item.languages_code === language);
      const infographic: Infographic = {
        id: infographicItem.id,
        icon: infographicItem.icono,
        title: infographicTranslation ? infographicTranslation.titulo : null,
        description: infographicTranslation ? infographicTranslation.descripcion : null,
        showMoreButton: infographicTranslation ? infographicTranslation.mostrar_mas : null,
        modal: {
          title: infographicTranslation ? infographicTranslation.titulo_modal : null,
          description: infographicTranslation ? infographicTranslation.descripcion_modal : null,
          image: {
            url: infographicItem.imagen_modal ? this.getImageUrl(infographicItem.imagen_modal.filename_disk) : null,
            alt: infographicTranslation ? infographicTranslation.alt_imagen_modal : null,
            title: null,
          },
          disclaimer: infographicTranslation ? infographicTranslation.texto_inferior_modal : null,
          disclaimerButton: infographicTranslation ? infographicTranslation.boton_modal : null,
          disclaimerUrl: infographicItem.url_modal,
        }
      };
      infographics.push(infographic);
    });
    return infographics;
  }

  public mapProducts(productsItems: Array<ProductItem>, language: string): Array<Product> {
    const products: Array<Product> = [];
    productsItems.forEach((productsItem) => {
      const productTranslation = productsItem.traducciones.find(item => item.languages_code === language);
      const product: Product = {
        id: productsItem.id,
        identifier: productsItem.identificador,
        icon: productsItem.icono,
        title: productTranslation ? productTranslation.titulo : null,
        description: productTranslation ? productTranslation.descripcion : null,
        productUrl: productsItem.url_producto,
        textButton: productTranslation ? productTranslation.texto_boton : null,
      };
      products.push(product);
    });
    return products;
  }

  public mapHeaders(headerItems: Array<HeaderItem<HeaderTranslation>>, language: string): Array<Banner> {
    const banners: Array<Banner> = [];
    headerItems.forEach(bannerItem => {
      const bannerTranslation = bannerItem.traducciones.find(item => item.languages_code === language);
      const banner: Banner = {
        id: bannerItem.id,
        image: {
          url: bannerItem.imagen ? this.getImageUrl(bannerItem.imagen.filename_disk) : null,
          alt: bannerTranslation ? bannerTranslation.alt_imagen : null,
          title: bannerTranslation ? bannerTranslation.title_image : null,
        },
        image_mobile: {
          url: bannerItem.imagen_mobile ? this.getImageUrl(bannerItem.imagen_mobile.filename_disk) : null,
          alt: bannerTranslation ? bannerTranslation.alt_imagen : null,
          title: bannerTranslation ? bannerTranslation.title_image : null,
        },
        icon: bannerItem.icono,
        firstLine: bannerTranslation ? bannerTranslation.titulo : null,
      };
      banners.push(banner);
    });
    return banners;
  }

  public mapMandatorySavings(mandatorySavingsItem: Saving.MandatorySavingsItem, infographics: Array<Infographic>)
    : Saving.MandatorySavings {
    const language = this.getLanguage();
    const mandatorySavings: Saving.MandatorySavings = MANDATORY_SAVINGS_CONTENT;
    const selectedInfographics = mandatorySavingsItem.carrusel.map(item => item.infografias_id);
    mandatorySavings.header.image = {
      url: mandatorySavingsItem.imagen_banner ? this.getImageUrl(mandatorySavingsItem.imagen_banner.filename_disk) : null,
      alt: mandatorySavingsItem ? mandatorySavingsItem.alt_image : null,
      title: mandatorySavingsItem ? mandatorySavingsItem.title_image : null,
    };
    mandatorySavings.header.image_mobile = {
      url: mandatorySavingsItem.imagen_banner_mobile ? this.getImageUrl(mandatorySavingsItem.imagen_banner_mobile.filename_disk) : null,
      alt: mandatorySavingsItem ? mandatorySavingsItem.alt_image : null,
      title: mandatorySavingsItem ? mandatorySavingsItem.title_image : null
    };
    mandatorySavings.carousel.benefits = infographics.filter(item => selectedInfographics.includes(item.id));
    mandatorySavings.adviceSection.body = mandatorySavingsItem.traducciones.find(item => item.languages_code === language).consejos;
    mandatorySavings.adviceSection.disclaimerButton = mandatorySavingsItem.texto_boton_app;
    mandatorySavings.adviceSection.disclaimer = mandatorySavingsItem.texto_app;
    mandatorySavings.adviceSection.title = mandatorySavingsItem.titulo_disclaimer;
    mandatorySavings.carousel.title = mandatorySavingsItem.titulo_carrusel;
    mandatorySavings.carousel.subtitle = mandatorySavingsItem.descripcion_carrusel;
    mandatorySavings.carousel.button = mandatorySavingsItem.boton_carrusel;
    mandatorySavings.savingCardsSection.title = mandatorySavingsItem.titulo_cards;
    mandatorySavings.savingCardsSection.subtitle = mandatorySavingsItem.descripcion_cards;
    mandatorySavings.adviceSection.showCardImage = mandatorySavingsItem.mostrar_imagen_card;
    mandatorySavingsItem.tarjetas?.forEach((card, index) => {
      mandatorySavings.savingCardsSection.cards[index].image.alt = card.tarjetas_imagenes_id.alt_image;
      mandatorySavings.savingCardsSection.cards[index].image.title = card.tarjetas_imagenes_id.title_image;
      mandatorySavings.savingCardsSection.cards[index].title = card.tarjetas_imagenes_id.titulo;
      mandatorySavings.savingCardsSection.cards[index].image.url = this.getImageUrl(card.tarjetas_imagenes_id.imagen.filename_disk);
      mandatorySavings.savingCardsSection.cards[index].description = card.tarjetas_imagenes_id.descripcion;
    });
    mandatorySavings.faqSection.title = mandatorySavingsItem.titulo_preguntas;
    mandatorySavings.faqSection.subTitle = mandatorySavingsItem.descripcion_preguntas;
    mandatorySavingsItem.preguntas?.forEach((question) => {
      mandatorySavings.faqSection.faq.push({ title: question.faq_id.pregunta, description: question.faq_id.respuesta});
    });
    return mandatorySavings;
  }

  public mapVoluntarySavings(
    voluntarySavingsItem: Saving.VoluntarySavingsItem, infographics: Array<Infographic>): Saving.VoluntarySavings {
    const language = this.getLanguage();
    const voluntarySavings: Saving.VoluntarySavings = VOLUNTARY_SAVINGS_CONTENT;
    const selectedInfographics = voluntarySavingsItem.carrusel.map(item => item.infografias_id);
    voluntarySavings.header.image = {
      url: voluntarySavingsItem.imagen_banner ? this.getImageUrl(voluntarySavingsItem.imagen_banner.filename_disk) : null,
      alt: voluntarySavingsItem ? voluntarySavingsItem.alt_image : null,
      title: voluntarySavingsItem ? voluntarySavingsItem.title_image : null,
    };
    voluntarySavings.header.image_mobile = {
      url: voluntarySavingsItem.imagen_banner_mobile ? this.getImageUrl(voluntarySavingsItem.imagen_banner_mobile.filename_disk) : null,
      alt: voluntarySavingsItem ? voluntarySavingsItem.alt_image : null,
      title: voluntarySavingsItem ? voluntarySavingsItem.title_image : null
    };
    voluntarySavings.carousel.benefits = infographics.filter(item => selectedInfographics.includes(item.id));
    voluntarySavings.questionsSection.body = voluntarySavingsItem.apv_preguntas_traducciones
      .find(item => item.languages_code === language).preguntas;
    voluntarySavings.carousel.title = voluntarySavingsItem.titulo_carrusel;
    voluntarySavings.carousel.subtitle = voluntarySavingsItem.descripcion_carrusel;
    voluntarySavings.button = voluntarySavingsItem.boton_carrusel;
    voluntarySavings.showCardImage = voluntarySavingsItem.mostrar_imagen_card;
    voluntarySavings.savingCardsSection.title = voluntarySavingsItem.titulo_cards;
    voluntarySavings.savingCardsSection.subtitle = voluntarySavingsItem.descripcion_cards;
    voluntarySavingsItem.tarjetas?.forEach((card, index) => {
      voluntarySavings.savingCardsSection.cards[index].image.alt = card.tarjetas_imagenes_id.alt_image;
      voluntarySavings.savingCardsSection.cards[index].image.title = card.tarjetas_imagenes_id.title_image;
      voluntarySavings.savingCardsSection.cards[index].title = card.tarjetas_imagenes_id.titulo;
      voluntarySavings.savingCardsSection.cards[index].image.url = this.getImageUrl(card.tarjetas_imagenes_id.imagen.filename_disk);
      voluntarySavings.savingCardsSection.cards[index].description = card.tarjetas_imagenes_id.descripcion;
    });
    voluntarySavings.questionsSection.title = voluntarySavingsItem.titulo_disclaimer;
    voluntarySavings.commissionDisclaimer.title = voluntarySavingsItem.titulo_comision;
    voluntarySavings.commissionDisclaimer.description = voluntarySavingsItem.descripcion_comision;
    return voluntarySavings;
  }

  public mapSecondaryAccount(
    secondaryAccountItem: Saving.SecondaryAccountItem, infographics: Array<Infographic>): Saving.SecondaryAccount {
    const lang = this.getLanguage();
    const secondaryAccount: Saving.SecondaryAccount = SECONDARY_ACCOUNT_CONTENT;
    const selectedInfographics = secondaryAccountItem.carrusel.map(item => item.infografias_id);
    secondaryAccount.header.image = {
      url: secondaryAccountItem.imagen_banner ? this.getImageUrl(secondaryAccountItem.imagen_banner.filename_disk) : null,
      alt: secondaryAccountItem ? secondaryAccountItem.alt_image : null,
      title: secondaryAccountItem ? secondaryAccountItem.title_image : null,
    };
    secondaryAccount.header.image_mobile = {
      url: secondaryAccountItem.imagen_banner_mobile ? this.getImageUrl(secondaryAccountItem.imagen_banner_mobile.filename_disk) : null,
      alt: secondaryAccountItem ? secondaryAccountItem.alt_image : null,
      title: secondaryAccountItem ? secondaryAccountItem.title_image : null,
    };
    secondaryAccount.carousel.benefits = infographics.filter(item => selectedInfographics.includes(item.id));
    const translations = secondaryAccountItem.traducciones.find(item => item.languages_code === lang);
    secondaryAccount.withdrawalsSection.body = translations.dudas;
    secondaryAccount.restrictionsSection.body = translations.diferencias;
    secondaryAccount.carousel.title = secondaryAccountItem.titulo_carrusel;
    secondaryAccount.carousel.subtitle = secondaryAccountItem.descripcion_carrusel;
    secondaryAccount.button = secondaryAccountItem.boton_carrusel;
    secondaryAccount.savingCardsSection.title = secondaryAccountItem.titulo_cards;
    secondaryAccount.savingCardsSection.subtitle = secondaryAccountItem.descripcion_cards;
    secondaryAccount.showCardImage = secondaryAccountItem.mostrar_imagen_card;
    secondaryAccountItem.tarjetas?.forEach((card, index) => {
      secondaryAccount.savingCardsSection.cards[index].image.alt = card.tarjetas_imagenes_id.alt_image;
      secondaryAccount.savingCardsSection.cards[index].image.title = card.tarjetas_imagenes_id.title_image;
      secondaryAccount.savingCardsSection.cards[index].title = card.tarjetas_imagenes_id.titulo;
      secondaryAccount.savingCardsSection.cards[index].image.url = this.getImageUrl(card.tarjetas_imagenes_id.imagen.filename_disk);
      secondaryAccount.savingCardsSection.cards[index].description = card.tarjetas_imagenes_id.descripcion;
    });
    secondaryAccount.depositsSection.title = secondaryAccountItem.titulo_depositos;
    secondaryAccount.depositsSection.body = secondaryAccountItem.descripcion_depositos;
    secondaryAccount.withdrawalsSection.title = secondaryAccountItem.dudas_titulo;
    secondaryAccount.withdrawalsSection.subtitle = secondaryAccountItem.dudas_descripcion;
    secondaryAccount.restrictionsSection.title = secondaryAccountItem.diferencias_titulo;
    secondaryAccount.commissionDisclaimer.title = secondaryAccountItem.titulo_comision;
    secondaryAccount.commissionDisclaimer.description = secondaryAccountItem.descripcion_comision;
    secondaryAccount.faqSection.title = secondaryAccountItem.titulo_preguntas;
    secondaryAccount.faqSection.subTitle = secondaryAccountItem.descripcion_preguntas;
    secondaryAccount.faqSection.faq = [];
    secondaryAccountItem.preguntas?.forEach((question) => {
      secondaryAccount.faqSection.faq.push({ title: question.faq_id.pregunta, description: question.faq_id.respuesta});
    });
    return secondaryAccount;
  }

  public mapFinancialInformation(financialInformationItem: FinancialInformationItem, headers: Array<Banner>): FinancialInformation {
    const language = this.getLanguage();
    const header = headers.find(item => item.id === financialInformationItem.header.id);
    const {
      titulo: title,
      cuerpo: description,
      titulo_tabla: titleTable,
      tabla: table,
      fecha_actualizacion: informationUpdateDate
    } = financialInformationItem.traducciones.find(item => item.languages_code === language);
    return {
      header,
      profitabilityVariation: { title, description, titleTable, table, informationUpdateDate },
    };
  }

  public mapInvestmentPortfolio(investmentPortfolioItems: InvestmentPortfolioItems): InvestmentPortfolioInfo {
    const {
      fecha_actualizacion: informationUpdateDate,
      titulo: title,
      contenido: description,
      titulo_grafico_zona: chartZoneDescription,
      titulo_grafico_cartera: chartDistributionDescription

    } = investmentPortfolioItems.traducciones.find(item => item.languages_code === this.getLanguage());
    const charts = investmentPortfolioItems.graficos.map((graphic: InvestmentPortfolioGraphics) => {
      return {
        alt_distribution: graphic.cartera_inversiones_graficos_id.alt_cartera,
        alt_zone: graphic.cartera_inversiones_graficos_id.alt_zona,
        distribution_chart: this.getImageUrl(graphic.cartera_inversiones_graficos_id.grafico_cartera.filename_disk),
        zone_chart: this.getImageUrl(graphic.cartera_inversiones_graficos_id.grafico_zona.filename_disk),
        fund_title: graphic.cartera_inversiones_graficos_id.titulo_fondo,
      };
    });
    return { informationUpdateDate, title, description, chartZoneDescription, chartDistributionDescription, charts };
  }

  public mapEconomicIndicators(economicIndicatorItems: EconomicIndicatorsItem): EconomicIndicatorsInfo {
    const { id, filename_download: fileName } = economicIndicatorItems.reporte;
    const reportFileUrl = this.getPDFUrl(id);
    const {
      titulo: title,
      texto_boton: textButton,

    } = economicIndicatorItems.traducciones.find(item => item.languages_code === this.getLanguage());
    return { reportFileUrl, fileName, title, textButton };
  }

  public mapMultiFunds(multiFundsItem: MultiFundsItem, headers: Array<Banner>, language: string): MultiFunds {
    const multiFunds: MultiFunds = MULTI_FUNDS_CONTENT;
    multiFunds.header = headers.find(item => item.id === multiFundsItem.header.id);
    multiFunds.description = multiFundsItem.descripcion;
    multiFunds.title = multiFundsItem.titulo;
    multiFunds.funds = [];
    multiFundsItem.fondos?.forEach(fondo => {
      multiFunds.funds.push({
        name: fondo.fondo,
        risk: fondo.titulo_fondo,
        description: fondo.descripcion_fondo,
        maxLimit: fondo.limite_maximo,
        minLimit: fondo.limite_minimo
      });
    });
    multiFunds.helpSection.risky.title = multiFundsItem.titulo_columna_1;
    multiFunds.helpSection.risky.description = multiFundsItem.descripcion_columna_1;
    multiFunds.helpSection.safe.title = multiFundsItem.titulo_columna_2;
    multiFunds.helpSection.safe.description = multiFundsItem.descripcion_columna_2;
    multiFunds.fundChangeDisclaimer = multiFundsItem.disclaimer;
    const { rentabilidad = null } = multiFundsItem.traducciones.find(item => item.languages_code === language) || {};
    if (!rentabilidad) { return multiFunds; }
    const profitabilityCard = multiFunds.moreInfoCards.find(item => item.modal && item.modal.type === 'profitability');
    profitabilityCard.modal.data.header = this.assignProfitabilityItem(rentabilidad.titulos);
    profitabilityCard.modal.data.items = rentabilidad.items.map(item => this.assignProfitabilityItem(item));
    multiFunds.showImgCards = multiFundsItem.mostrar_imagenes_tarjetas;
    multiFunds.moreInfoCards = [];
    multiFundsItem.cards?.forEach(card => {
      multiFunds.moreInfoCards.push({
        title: card.multifondos_cards_id.titulo,
        showMoreText: card.multifondos_cards_id.texto_boton,
        image: {
          url: this.getImageUrl(card.multifondos_cards_id.imagen_card.filename_disk),
          alt: card.multifondos_cards_id.alt_image,
          title: card.multifondos_cards_id.title_image
        },
        modal: {
          title: card.multifondos_cards_id.titulo,
          description: card.multifondos_cards_id.descripcion
        }
      });
    });
    return multiFunds;
  }

  public mapFuturePensioner(futurePensionerItem: FuturePensionerItem, banners: Array<Banner>, headers: Array<Banner>): FuturePensioner {
    const futurePensioner: FuturePensioner = FUTURE_PENSIONER_CONTENT;
    const selectedBanners = futurePensionerItem.banners.map(banner => banner.banners_id);
    futurePensioner.banners = banners.filter(banner => selectedBanners.includes(banner.id));
    const calendarHeader = headers.find(header => header.id === 4);
    futurePensioner.header = {
      id: calendarHeader.id,
      image: {
        url: calendarHeader.image.url,
        alt: calendarHeader.image.alt,
        title: calendarHeader.image.title
      },
      image_mobile: {
        url: calendarHeader.image_mobile.url,
        alt: calendarHeader.image.alt,
        title: calendarHeader.image.title
      },
      icon: calendarHeader.icon,
      firstLine: calendarHeader.firstLine,
    };
    return futurePensioner;
  }

  public mapHeaderImages(headers: Array<Banner>, headerId: number): Banner {
    const headerItem = headers.find(header => header.id === headerId);
    const banner: Banner = {
      id: headerItem.id,
      image: {
        url: headerItem.image.url,
        alt: headerItem.image.alt,
        title: headerItem.image.title
      },
      image_mobile: {
        url: headerItem.image_mobile.url,
        alt: headerItem.image.alt,
        title: headerItem.image.title
      },
      icon: headerItem.icon,
      firstLine: headerItem.firstLine
    };
    return banner;
  }

  public mapAlertBanner(alertBannerItems: Array<AlertBannerItem>, language: string): AlertBanner {
    if (!alertBannerItems.length) { return { title: '', message: '', active: false }; }
    return this.getBannerDetail(alertBannerItems[0], language);
  }

  public mapGeneralInfo(generalInfoItem: GeneralInfoItem, language: string): GeneralInfo {
    const { contact_center_habilitado = false } = generalInfoItem;
    const { horarios_call_center = null } = generalInfoItem.traducciones.find(item => item.languages_code === language) || {};
    const {
      titulo = null,
      bajada = null,
      boton = null
    } = generalInfoItem.flotante.find(item => item.languages_code === language) || {};
    return {
      callCenterAvailable: contact_center_habilitado,
      callCenterHours: horarios_call_center,
      loginModalActive: generalInfoItem.modal_login,
      floatingButton: {
        show: generalInfoItem.mostrar_flotante,
        title: titulo,
        description: bajada,
        button: {
          name: boton,
          url: generalInfoItem.flotante_url,
        },
      },
    };
  }

  public mapFundsWithdrawal(fundWithdrawalItem: FundsWithdrawalItem, language: string): FundsWithdrawal {
    const fundWithdrawal = FUNDS_WITHDRAWAL_CONTENT;
    fundWithdrawal.description = fundWithdrawalItem.contenido;
    return fundWithdrawal;
  }

  public mapAdministrationWorkers(
    administrationWorkersItem: AdministrationContentData, headers: Array<Banner>, language = 'es'): AdministrationWorkers {
    const directoryContent = this.parseWorkers(administrationWorkersItem.directorio);
    const executiveContent = this.parseWorkers(administrationWorkersItem.ejecutivos);
    const headerContent = headers.find(header => header.id === FOOTER_ADMINISTRATION_HEADER_ID);
    const { titulo: pageTitle, descripcion: pageDescription, titulo_directorio: directoryTitle, titulo_ejecutivos: executiveTitle } = administrationWorkersItem;
    return { directoryContent, executiveContent, headerContent, pageTitle, pageDescription, directoryTitle, executiveTitle };
  }

  public mapMortuaryQuotaRelationships(mortuaryQuotaRelationshipsItem: MortuaryQuotaRelationshipsItem, language = 'es') {
    const traducciones = mortuaryQuotaRelationshipsItem.traducciones.find(item => item.languages_code === language);
    return traducciones.cuota_mortuoria.contenido;
  }

  public mapGeneraliGroup(generaliGroupData: GeneraliGroupData, header: Banner): GeneraliGroupContent {
    const generaliGroup: GeneraliGroupContent = {
      header: header,
      content: {
        introTitle: generaliGroupData.titulo_intro,
        introDescription: generaliGroupData.descripcion_intro,
        ammountsTitle: generaliGroupData.titulo_cifras,
        ammountsSubtitle: generaliGroupData.subtitulo_cifras,
        ammountsDescription: generaliGroupData.descripcion_cifras,
        businessTitle: generaliGroupData.titulo_negocio,
        businessSubtitle: generaliGroupData.subtitulo_negocio,
        businessDescription: generaliGroupData.descripcion_negocio,
        planTitle: generaliGroupData.titulo_plan,
        videoLink: generaliGroupData.link_video,
        planDescription: generaliGroupData.descripcion_plan,
        ammounts: generaliGroupData.cifras.map(ammount => {
          return {
            altImage: ammount.tarjetas_cifras_id.alt_image,
            titleImage: ammount.tarjetas_cifras_id.title_image,
            upperText: ammount.tarjetas_cifras_id.texto_superior,
            middleText: ammount.tarjetas_cifras_id.texto_medio,
            lowerText: ammount.tarjetas_cifras_id.texto_inferior,
            icon: ammount.tarjetas_cifras_id.icono.filename_disk ? this.getImageUrl(ammount.tarjetas_cifras_id.icono.filename_disk) : null,
          };
        }),
        roles: generaliGroupData.roles.map(role => {
          return {
            altImage: role.tarjetas_negocio_responsable_id.alt_image,
            titleImage: role.tarjetas_negocio_responsable_id.title_image,
            title: role.tarjetas_negocio_responsable_id.titulo,
            description: role.tarjetas_negocio_responsable_id.descripcion,
            icon: role.tarjetas_negocio_responsable_id.icono.filename_disk 
                  ? this.getImageUrl(role.tarjetas_negocio_responsable_id.icono.filename_disk) : null,
          };
        }),
      }
    };
    return generaliGroup;
  }

  public getLanguage() {
    if (localStorage.getItem(LANGUAGE_KEY) !== null) { return localStorage.getItem(LANGUAGE_KEY); }
    return 'es';
  }

  private createColumn(footerItem: FooterItem, links: Array<Link>, language: string, id: string, index: number) {
    const titles = footerItem.titulos.find(titulo => titulo.languages_code === language);
    return {
      title: titles ? titles[`titulo_${index + 1}`] : null,
      links: this.getLinksColumn(footerItem, id, links),
    };
  }

  private getLinksColumn(footerItem: FooterItem, name: string, links: Array<Link>): Array<Link> {
    const linksIds: Array<number> = footerItem[name].map(link => link.links_id);
    const finalLinks: Array<Link> = linksIds.map(id => links.find(link => link.id === id));
    return finalLinks;
  }

  private assignProfitabilityItem(item: ProfitabilityTableItemJson): ProfitabilityTableItem {
    const {
      fondo: fund,
      mes: month,
      semestre: semester,
      añoPasado: lastYear,
      ultimosTresAños: lastThreeYears,
      ultimosAños: lastYears,
      historico: historic
    } = item;
    return { fund, month, semester, lastYear, lastThreeYears, lastYears, historic };
  }

  private getBannerDetail(alertBannerItem: AlertBannerItem, language: string) {
    const bannerInformation = alertBannerItem.traducciones.find(traduccion => traduccion.languages_code === language);
    const { activo: active } = alertBannerItem;
    const { titulo: title, mensaje: message } = bannerInformation;
    const modal = alertBannerItem.modal ? this.getModalDetail(bannerInformation, alertBannerItem.imagen_modal) : null;
    return { title, message, active, modal } as AlertBanner;
  }

  private getModalDetail(alertBannerTranslation: AlertBannerTranslation, imageItem: ImageItem) {
    const { modal_titulo: title, modal_descripcion: description, alt_imagen: alt } = alertBannerTranslation;
    const filename = imageItem ? imageItem.filename_disk : '';
    const image = { url: this.getImageUrl(filename), alt };
    return { title, description, image } as Modal<any>;
  }

  private getImageUrl(id: string): string {
    const url = id.length ? `${environment.cmsApiUrl}assets/${id}` : null;
    return url;
  }

  private getPDFUrl(id: string): string {
    const url = id.length ? `${environment.cmsApiUrl}assets/${id}` : null;
    return url;
  }

  private tableFormat(jsonTable: { [key: string]: any; }): Array<FamilyAllowanceTable> {

    if (jsonTable.data) {
      const result = jsonTable.data.reduce((acc, item) => {
        const tempKey = Object.keys(item)[0];
        acc[tempKey] = item[tempKey];
        return acc;
      }, {});
      jsonTable = result;
    }
    return Object.keys(jsonTable).map((key) => {
      return { title: key, value: jsonTable[key] };
    });
  }

  private parseToDownloadSection(links: Array<CmsLink>, title?: string): DownloadSection {
    const urlSectionList: Array<UrlSection> = [];
    links.forEach((item) => {
      const url = `${environment.publicFilesUrl}${item.url}`;
      urlSectionList.push({ title: item.titulo, date: item.fecha, url });
    });
    return { title, urlSectionList };
  }

  private parseToDownloadSectionCms(links: Array<CmsLink>, title?: string): DownloadSection {
    const urlSectionList: Array<UrlSection> = [];
    links.forEach((item) => {
      const url = this.getPDFUrl(item.url);
      urlSectionList.push({ title: item.titulo, date: item.fecha, url });
    });
    return { title, urlSectionList };
  }

  private parseToDownloadContent(collection: Array<LinkCollection>): Array<DownloadContent> {
    const downloadContent: Array<DownloadContent> = [];
    collection.forEach((linksItem) => {
      const downloadSection = this.parseToDownloadSection(linksItem.items);
      const downloadSectionList: Array<DownloadSection> = [downloadSection];
      downloadContent.push({ title: linksItem.titulo, year: linksItem.año, downloadSectionList });
    });
    return downloadContent;
  }

  private parseToDownloadSectionArray(collection: Array<LinkCollection>): Array<DownloadSection> {
    const downloadSectionList: Array<DownloadSection> = [];
    collection.forEach((linksItem) => {
      const downloadSection = this.parseToDownloadSection(linksItem.items, linksItem.titulo);
      downloadSectionList.push(downloadSection);
    });
    return downloadSectionList;
  }

  private parseWorkers(cmsWorkers: Directory[]): Array<WorkerContent> {
    const workers: Array<WorkerContent> = cmsWorkers.map((worker) => ({
      name: worker.administracion_imagenes_id.nombre,
      position: worker.administracion_imagenes_id.cargo,
      portrait: {
        url: worker.administracion_imagenes_id.imagen.filename_disk && this.getImageUrl(worker.administracion_imagenes_id.imagen.filename_disk),
        alt: worker.administracion_imagenes_id.nombre,
        title: null,
      }
    }));
    return workers;
  }

  public mapCompliantsChannelContent(header: Banner, contentResponse: CompliantsChannelData): CompliantsChannelContent {
    const complianceChannel: CompliantsChannelContent = {
      header,
      title: contentResponse.titulo,
      faqs: contentResponse.preguntas.map((faq) => {
        return { title: faq.preguntas_canal_denuncias_id.pregunta, description: faq.preguntas_canal_denuncias_id.respuesta };
      })
    };
    return complianceChannel;
  }

  public mapCrimePreventionModelContent(header: Banner, contentResponse: CrimePreventionModelData): CrimePreventionModelContent {
    const crimePreventionModel: CrimePreventionModelContent = {
      header,
      title: contentResponse.titulo,
      file: [{
        title: contentResponse.archivo.title,
        url: this.getPDFUrl(contentResponse.archivo.filename_disk)
      }]
    };
    return crimePreventionModel;
  }

  public mapShareholdersMeetingContent(header: Banner, contentResponse: ShareHoldersMeetingData): ShareholdersMeetingContent {
    const shareHoldersContent: ShareholdersMeetingContent = {
      header,
      content: {
        title: contentResponse.titulo,
        ordinary_title: contentResponse.titulo_ordinarias,
        extraordinary_title: contentResponse.titulo_extraordinarias,
        ordinary_section: contentResponse.seccion_ordinarias.map(section => {
          return {
            title: section.juntas_seccion_id.titulo + ' - ' + this.formatDate(section.juntas_seccion_id.fecha),
            downloadSectionList: [{
              title: undefined,
              urlSectionList: section.juntas_seccion_id.archivos_junta.map(file => {
                return { title: file.juntas_archivos_id.titulo, url: this.getPDFUrl(file.juntas_archivos_id.archivo.filename_disk)};
              })
            }]
          };
        }),
        extraordinary_section: contentResponse.seccion_extraordinarias.map(section => {
          return {
            title: section.juntas_seccion_id.titulo + ' - ' + this.formatDate(section.juntas_seccion_id.fecha),
            downloadSectionList: [{
              title: undefined,
              urlSectionList: section.juntas_seccion_id.archivos_junta.map(file => {
                return { title: file.juntas_archivos_id.titulo, url: this.getPDFUrl(file.juntas_archivos_id.archivo.filename_disk)};
              })
            }]
          };
        }),
      }
    };
    return shareHoldersContent;
  }

  private formatDate(fecha: string): string {
    return fecha.split('-')[2] + '-' + fecha.split('-')[1] + '-' + fecha.split('-')[0];
  }

  public mapPasswordAndCertificatesContent(header: Banner, contentResponse: PasswordItem): PasswordMapped {
    const passwordAndCertificatesMapped: PasswordMapped = {
      banner: header,
      faqSection: {
        title: contentResponse.titulo_preguntas,
        subTitle: contentResponse.descripcion_preguntas,
        faq: contentResponse.preguntas.map((question) => {
          return { title: question.faq_id.pregunta, description: question.faq_id.respuesta };
        })
      }
    };
    return passwordAndCertificatesMapped;
  }

  public mapCertificatesContent(header: Banner, contentResponse: any): any {
    const certificatesMapped: any = {
      banner: header,
      faqSection: {
        title: contentResponse.titulo_preguntas,
        subTitle: contentResponse.descripcion_preguntas,
        faq: contentResponse.preguntas.map((question) => {
          return { title: question.faq_id.pregunta, description: question.faq_id.respuesta };
        })
      }
    };
    return certificatesMapped;
  }

  public mapAdditionalPensionerBenefits(contentData: AdditionalPensionerBenefitsData): AdditionalBenefits {
    const additionalBenefitsContent: AdditionalBenefits = {
      title: contentData.titulo,
      showImageCards: contentData.mostrar_imagenes_cards,
      cards: contentData.tarjetas.map(card => {
        const title = card.beneficios_pensionados_cards_id.titulo_modal;
        const description = card.beneficios_pensionados_cards_id.texto_modal;
        return {
          image: {
            url: card.beneficios_pensionados_cards_id.imagen_card ? this.getImageUrl(card.beneficios_pensionados_cards_id.imagen_card.filename_disk) : null,
            alt: card.beneficios_pensionados_cards_id.alt_image,
            title: card.beneficios_pensionados_cards_id.title_image,
          },
          title: card.beneficios_pensionados_cards_id.titulo_card,
          description: card.beneficios_pensionados_cards_id.descripcion_card,
          showMoreText: card.beneficios_pensionados_cards_id.text_boton,
          modal: (title && description) ? { title, description } : null,
          showMoreUrl: card.beneficios_pensionados_cards_id.link_externo ? card.beneficios_pensionados_cards_id.link_externo : null,
        };
      })
    };
    return additionalBenefitsContent;
  }

  public mapOurCompanyContent(ourCompanyItem: OurCompanyData, header: Banner): OurCompanyContent {
    const ourCompanyContent: OurCompanyContent = {
      header,
      title: ourCompanyItem.titulo,
      description: ourCompanyItem.descripcion
    };

    return ourCompanyContent;
  }
  
  public mapQuotaValuesPage(quotaValuesItem: QuotaValuesContentData): QuotaValueInformation {
    const quotaValues: QuotaValueInformation = {
      definitionsSection: {
        mainTitle: quotaValuesItem.titulo,
        title: quotaValuesItem.subtitulo,
        subtitle: quotaValuesItem.descripcion_1,
        body: quotaValuesItem.descripcion_2,
      },
      quotaValueSearcherBox: {
        title: quotaValuesItem.titulo_buscador,
        startDate: quotaValuesItem.texto_desde,
        endDate: quotaValuesItem.texto_hasta,
        button: quotaValuesItem.boton_buscar,
        excelButton: quotaValuesItem.boton_descargar,
        modalTitle: 'Valores cuota'
      },
      profitabilitySection: {
        fund: 'Fondo',
        chartTitle: quotaValuesItem.titulo_grafico
      },
      profitabilityDataFunds: quotaValuesItem.rentabilidad.map((item) => {
        return {
          fundA: Number(item.fondo_a),
          fundB: Number(item.fondo_b),
          fundC: Number(item.fondo_c),
          fundD: Number(item.fondo_d),
          fundE: Number(item.fondo_e),
          year: Number(item.periodo)
        };
      }),
      commissionSection: {
        text: quotaValuesItem.descripcion_3
      },
      notesSection: {
        notesTitle: quotaValuesItem.titulo_notas,
        notesLeft: quotaValuesItem.notas_columna_1,
        notesRight: quotaValuesItem.notas_columna_2
      }
    };
    return quotaValues;
  }
  
  public mapSimulatorsContent(header: Banner, simulatorsItem: SimulatorsData): Simulators {
    const simulatorsData: Simulators = {
      header,
      moreInfoCards: simulatorsItem.cards.map(card => {
        return {
          image: {
            url: card.simuladores_cards_id.imagen ? this.getImageUrl(card.simuladores_cards_id.imagen.filename_disk) : null,
            alt: card.simuladores_cards_id.alt_imagen,
            title: card.simuladores_cards_id.title_image
          },
          title: card.simuladores_cards_id.titulo,
          description: card.simuladores_cards_id.descripcion,
          requestText: card.simuladores_cards_id.texto_boton,
          requestUrl: card.simuladores_cards_id.url
        };
      }),
      showImageCards: simulatorsItem.mostrar_imagen_card,
    };

    return simulatorsData;
  }
  
  public mapCalendarContent(calendarItem: CalendarContentData): Calendar {
    const calendar: Calendar = {
      pensionSettlement: {
        buttonText: calendarItem.texto_boton_liquidacion_pago,
        buttonUrl: 'pensionSettlement',
        title: calendarItem.titulo_liquidacion_pago,
        description: calendarItem.texto_liquidacion_pago
      },
      registerAccountBox: {
        buttonText: calendarItem.texto_boton_inscripcion,
        buttonUrl: 'pago-pension/inscribir-cuenta',
        title: calendarItem.titulo_cuadro,
        description: calendarItem.descripcion_cuadro
      },
      paymentCenter: {
        cards: [],
        title: calendarItem.titulo_centros_pago,
        description: ''
      },
      servipagCalendar: undefined,
      bankCalendar: {
        title: calendarItem.titulo_calendarios
      },
      subTitle: calendarItem.titulo,
      title: '',
      description: calendarItem.subtitulo
    };
    return calendar;
  }

  public mapPensionTypesContent(pensionTypesItem: PensionTypesContentData): PensionTypes {
    const TERMINAL_ILLNESS_ROUTE = 'afiliado/asesoria/centro-tramites/enfermos-terminales/inicio';
    const pensionTypes: PensionTypes = {
      moreInfoCards: pensionTypesItem.tarjetas.map(card => {
        if (card.tipos_de_pension_cards_id.modal !== null) {
          return {
            image: {
              url: card.tipos_de_pension_cards_id.imagen_card ? this.getImageUrl(card.tipos_de_pension_cards_id.imagen_card.filename_disk) : null,
              alt: card.tipos_de_pension_cards_id.alt_image,
              title: card.tipos_de_pension_cards_id.title_image,
            },
            redirectionButtons: [
              {
                requestText: card.tipos_de_pension_cards_id.texto_boton_solicitud,
                redirectType: card.tipos_de_pension_cards_id.tipo_redireccionamiento
              }
            ],
            title: card.tipos_de_pension_cards_id.titulo,
            description: card.tipos_de_pension_cards_id.descripcion,
            showMoreText: card.tipos_de_pension_cards_id.texto_boton_informacion,
            modal: {
              title: card.tipos_de_pension_cards_id.modal.traducciones[0].titulo,
              description: card.tipos_de_pension_cards_id.modal.traducciones[0].cuerpo
            }
          };
        } else {
          return {
            image: {
              url: card.tipos_de_pension_cards_id.imagen_card ? this.getImageUrl(card.tipos_de_pension_cards_id.imagen_card.filename_disk) : null,
              alt: card.tipos_de_pension_cards_id.alt_image,
              title: card.tipos_de_pension_cards_id.title_image,
            },
            title: card.tipos_de_pension_cards_id.titulo,
            description: card.tipos_de_pension_cards_id.descripcion,
            showMoreText: card.tipos_de_pension_cards_id.texto_boton_informacion,
            showMoreUrl: TERMINAL_ILLNESS_ROUTE,
            requestText: card.tipos_de_pension_cards_id.texto_boton_solicitud,
            requestUrl: environment.paetUrl,
            showMoreSecondaryText: card.tipos_de_pension_cards_id.texto_boton_solicitud
          };
        }
      }),
      title: pensionTypesItem.titulo,
      description: pensionTypesItem.descripcion,
      goTo: pensionTypesItem.texto_boton_consulta
    };

    return pensionTypes;
  }

  public mapCommissionsDisclaimerContent(commissionsItem: CommissionsDisclaimerData): CommissionsDisclaimerSection {
    const section: CommissionsDisclaimerSection = {
      title: commissionsItem.titulo,
      description: commissionsItem.descripcion
    };
    return section;
  }

  public mapPensionModesContent(modesItem: PensionModesData): PensionModes {
    const pensionModes: PensionModes = {
      modeCards: modesItem.tarjetas.map(card => {
        return {
          icon: card.icono,
          style: card.estilo as IconCardStyle,
          title: card.titulo,
          description: card.descripcion,
          textButton: card.texto_boton,
          modal: {
            title: card.titulo,
            description: card.texto_modal
          }
        };
      }),
      title: modesItem.titulo,
      description: modesItem.descripcion
    };
    return pensionModes;
  }

  public mapScompContent(scompItem: ScompData): PensionOffers {
    const pensionOffers: PensionOffers = {
      title: scompItem.titulo,
      description: scompItem.descripcion,
      processTimeline: {
        title: scompItem.titulo_cronograma,
        steps: scompItem.cronograma.map(step => {
          return {
            title: step.titulo,
            moreInformation: step.link_info,
            label: step.tiempo_proceso,
            description: step.descripcion
          };
        })
      },
      disclaimers: {
        title: scompItem.titulo_disclaimers,
        sections: scompItem.disclaimers.map(disclaimer => {
          return {
            title: disclaimer.titulo,
            description: disclaimer.descripcion
          };
        })
      }
    };
    return pensionOffers;
  }
}
