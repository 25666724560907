import { POLICIES_TITLES } from '@constants/titles.constant';
import { CorporateGovernanceContent } from '@interfaces/corporation.interface';
import { DownloadSection } from '@interfaces/general.interface';

const pageKey = 'CORPORATION.POLICIES.GOVERNMENT';
export const CORPORATE_GOVERNANCE_CONTENT: CorporateGovernanceContent = {
  header: {
    firstLine: `${pageKey}.HEADER_TITLE`,
    icon: 'analysis',
    image: {
      alt: 'Gobierno corporativo',
      url: 'assets/images/banner-general1.5x.webp',
    },
    image_mobile: {
      alt: 'Gobierno corporativo',
      url: 'assets/images/banner-general1.5x.webp',
    },
  },
  title: POLICIES_TITLES.content.government,
  description: ``,
  topics: [],
  faqTitle: `${pageKey}.FAQS_TITLE`,
  faq: [],
  certificate: {} as DownloadSection
};
