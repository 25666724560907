import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { FORM_TYPES } from '@constants/forms.constant';
import { Commune, DefaultResponseItem, Region } from '@interfaces/general.interface';
import { PersonBackgroundsField } from '@interfaces/person-backgrounds.interface';
import { RadioButtonInputValues } from '@interfaces/radio-button-input-list-element.interface';
import { Backgrounds } from '@interfaces/survivor-pension-form.interface';
import {
  EmailDomainValidator
} from '@providers/email-domain-validator/email-domain-validator.service';
import { Utils } from '@utils/utils';

@Injectable({
  providedIn: 'root'
})
export class FormUtils {
  constructor(
    private emailDomainValidator: EmailDomainValidator,
    private utils: Utils,
  ) { }

  public requiredError(form: UntypedFormGroup, formControlName: string): boolean {
    return form.get(formControlName).hasError('required');
  }

  public patternError(form: UntypedFormGroup, formControlName: string): boolean {
    return form.get(formControlName).hasError('pattern');
  }

  public cellphoneError(form: UntypedFormGroup, formControlName: string): boolean {
    return form.get(formControlName).hasError('min') || form.get(formControlName).hasError('max');
  }

  public mustMatchError(form: UntypedFormGroup, formControlName: string): boolean {
    return form.get(formControlName).hasError('mustMatch');
  }

  public async validateEmailDomain(email: AbstractControl) {
    const validDomain = await this.emailDomainValidator.validateEmailDomain(email.value);
    if (!validDomain) { return email.setErrors({ ...email.errors, invalidDomain: true }); }
  }

  public setValidators(form: UntypedFormGroup, formControlNames: Array<string>, validators: ValidatorFn | Array<ValidatorFn>) {
    formControlNames.forEach((formControlName) => form.get(formControlName).setValidators(validators));
  }

  public setDateSelected(form: UntypedFormGroup, formControlName: string) {
    const formattedDate = this.utils.getFormattedDate(form.get(`${formControlName}Input`).value);
    form.get(formControlName).setValue(formattedDate);
  }

  public getCommunes(
    communeControl: AbstractControl,
    regionControl: AbstractControl,
    regions: Array<Region>
  ): Array<Commune> {
    communeControl.setValue('');
    const selectedRegion = regions.find(region => region.code === regionControl.value);
    const communes = selectedRegion ? selectedRegion.communes : [];
    selectedRegion ? communeControl.enable() : communeControl.disable();
    return communes;
  }

  public getFormType(url: string): string {
    const splitUrl = url.split('/');
    return FORM_TYPES[splitUrl.reverse()[0]];
  }

  public disableFields(form: UntypedFormGroup, fieldNames: Array<string>, reset: boolean = true): void {
    fieldNames.forEach((field) => {
      const control = form.get(field);
      if (reset) { control.reset(); }
      control.disable();
    });
  }

  public enableFields(form: UntypedFormGroup, fieldNames: Array<string>): void {
    fieldNames.forEach((field) => {
      const control = form.get(field);
      control.enable();
    });
  }

  public getBackgroundsControlsValues(backgrounds: Array<Backgrounds>, backgroundsId: string[]): RadioButtonInputValues[] {
    if (!backgrounds) { return null; }
    return backgroundsId.map((clientOptionId) => {
      const clientOption = backgrounds.find((option) => option.id === clientOptionId);
      return {
        selected: Boolean(clientOption),
        value: clientOption ? clientOption.description : null,
      } as RadioButtonInputValues;
    });
  }

  public getDefaultControlsValues(
    values: any, defaultControls: Array<PersonBackgroundsField>, defaultRelationship = false, relationships?: DefaultResponseItem[]
  ): Array<PersonBackgroundsField> {
    const controls = _.cloneDeep(defaultControls);
    if (!values) { return controls; }
    Object.keys(values).forEach((key) => {
      const valueObject = this.getKeyAndValue(values, key, defaultRelationship, relationships);
      const defaultControl = controls.find((obj) => obj.key === valueObject.key);
      if (defaultControl) { defaultControl.value = valueObject.value; }
    });
    return controls;
  }

  private getKeyAndValue(values: any, key: string, defaultRelationship = false, relationships?: DefaultResponseItem[]): any {
    if (key === 'name') { return { key: 'names', value: values[key] }; }
    if (key === 'relationshipId') {
      return { key: 'relationship', value: this.getRelationshipId(values[key], defaultRelationship, relationships) };
    }
    if (key === 'maritalStatusId') { return { key: 'maritalStatus', value: values[key] }; }
    if (key === 'clientSituation') { return { key: 'workSituation', value: values[key] }; }
    if (key === 'communeId') { return { key: 'commune', value: values[key] }; }
    return { key, value: values[key] };
  }

  private getRelationshipId(relationShipDescription: string, defaultRelationship: boolean, relationships: DefaultResponseItem[]): string {
    if (defaultRelationship) { return relationShipDescription; }
    const relation = relationships.find((relationship) => relationship.description === relationShipDescription);
    if (relation) { return relation.id; }
    return null;
  }

  public rutNotSameAsAffiliate(form: UntypedFormGroup, formControlName: string): boolean {
    return form.get(formControlName).hasError('notMustMatch');
  }

  public firstMonthDiscountError(form: UntypedFormGroup, formControlName: string): boolean {
    return form.get(formControlName).hasError('matDatepickerParse');
  }

  public minRimPercentageError(form: UntypedFormGroup, formControlName: string): boolean {
    return form.get(formControlName).hasError('minRimPercentage');
  }

  public minRimSalaryError(form: UntypedFormGroup, formControlName: string): boolean {
    return form.get(formControlName).hasError('minRimSalary');
  }
}
