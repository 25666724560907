import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@components/components.module';
import { IonicModule } from '@ionic/angular';
import { FinancialStatementsPage } from './financial-statements/financial-statements.page';
import { InvestorsRoutingModule } from './investors-routing.module';
import { AnnualMemoryPage } from './annual-memory/annual-memory.page';
import { CorporateGovernancePage } from './corporate-governance/corporate-governance.page';
import { EssentialFactsPage } from './essential-facts/essential-facts.page';
import { InformationOfInterestPage } from './information-of-interest/information-of-interest.page';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    FinancialStatementsPage,
    AnnualMemoryPage,
    CorporateGovernancePage,
    EssentialFactsPage,
    FinancialStatementsPage,
    InformationOfInterestPage
  ],
  imports: [
    CommonModule,
    IonicModule,
    InvestorsRoutingModule,
    RouterModule,
    TranslateModule,
    ComponentsModule,
  ],
})
export class InvestorsModule { }
