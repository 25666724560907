import { Component, OnInit } from '@angular/core';
import { ANNUAL_MEMORY_CONTENT } from '@constants/pages-content/annual-memory.constant';
import { INVESTORS_BREADCRUMBS } from '@constants/pages-content/corporation.constant';
import { RELEVANT_INFO_TITLES } from '@constants/titles.constant';
import { FontService } from '@providers/font/font.service';
import { LanguageProvider } from '@providers/language/language.service';
import { CmsService } from '@services/cms/cms.service';

@Component({
  selector: 'app-annual-memory',
  templateUrl: './annual-memory.page.html',
  styleUrls: ['./annual-memory.page.scss'],
})
export class AnnualMemoryPage implements OnInit {
  public pageContent = ANNUAL_MEMORY_CONTENT;
  public breadcrumb = [...INVESTORS_BREADCRUMBS, RELEVANT_INFO_TITLES.content.annualMemory];
  public loading = false;

  constructor(
    public font: FontService,
    public cmsService: CmsService,
    private languageProvider: LanguageProvider,
  ) { }

  public ngOnInit() {
    this.loadData();
    this.languageSubscription();
  }

  private loadData() {
    this.loading = true;
    this.cmsService.loadAnnualMemoryValues().toPromise()
      .then((response) => {
        this.pageContent.memories = response.urlSection;
        this.pageContent.header = response.header;
      })
      .finally(() => this.loading = false);
  }

  private languageSubscription() {
    this.languageProvider.language.subscribe(() => this.loadData());
  }
}
