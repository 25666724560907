import { Mockup } from '@interfaces/mockup.interface';

export const SHAREHOLDERS_MEETING_RESPONSE_MOCK: Mockup = {
    success: [{
        response: {
            data: {
                titulo: 'Junta de Accionistas',
                titulo_ordinarias: 'Juntas Ordinarias de Accionistas',
                titulo_extraordinarias: 'Juntas Extraordinarias de Accionistas',
                seccion_ordinarias: [
                    {
                        juntas_seccion_id: {
                            titulo: 'Cuadragésima Segunda Junta de Accionistas',
                            fecha: '2024-04-30',
                            archivos_junta: [
                                {
                                    juntas_archivos_id: {
                                        titulo: 'Acta Cuadragésima Segunda Junta Ordinaria de Accionistas',
                                        archivo: {
                                            filename_disk: 'e4913ab8-1e53-4323-91b4-47df64be5e93.pdf'
                                        }
                                    }
                                },
                                {
                                    juntas_archivos_id: {
                                        titulo: 'Citación',
                                        archivo: {
                                            filename_disk: 'e4913ab8-1e53-4323-91b4-47df64be5e93.pdf'
                                        }
                                    }
                                },
                                {
                                    juntas_archivos_id: {
                                        titulo: 'Poder',
                                        archivo: {
                                            filename_disk: 'e4913ab8-1e53-4323-91b4-47df64be5e93.pdf'
                                        }
                                    }
                                },
                                {
                                    juntas_archivos_id: {
                                        titulo: 'Antecedentes',
                                        archivo: {
                                            filename_disk: 'e4913ab8-1e53-4323-91b4-47df64be5e93.pdf'
                                        }
                                    }
                                },
                                {
                                    juntas_archivos_id: {
                                        titulo: 'Procedimiento para participación a distancia a Junta',
                                        archivo: {
                                            filename_disk: 'e4913ab8-1e53-4323-91b4-47df64be5e93.pdf'
                                        }
                                    }
                                }
                            ]
                        }
                    }
                ],
                seccion_extraordinarias: [
                    {
                        juntas_seccion_id: {
                            titulo: 'Trigésima Junta Extraordinaria de Accionistas',
                            fecha: '2024-10-15',
                            archivos_junta: [
                                {
                                    juntas_archivos_id: {
                                        titulo: 'Primer documento',
                                        archivo: {
                                            filename_disk: '6193046f-a8dc-4322-a22a-2848c8a1cbfa.pdf'
                                        }
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    }],
    failures: []
};
