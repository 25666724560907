import { Component, OnInit } from '@angular/core';
import { INVESTORS_BREADCRUMBS } from '@constants/pages-content/corporation.constant';
import { ESSENTIAL_FACTS_CONTENT } from '@constants/pages-content/essential-facts.constant';
import { RELEVANT_INFO_TITLES } from '@constants/titles.constant';
import { FontService } from '@providers/font/font.service';
import { LanguageProvider } from '@providers/language/language.service';
import { CmsService } from '@services/cms/cms.service';

@Component({
  selector: 'app-essential-facts',
  templateUrl: './essential-facts.page.html',
  styleUrls: ['./essential-facts.page.scss'],
})
export class EssentialFactsPage implements OnInit {
  public pageContent = ESSENTIAL_FACTS_CONTENT;
  public breadcrumb = [...INVESTORS_BREADCRUMBS, RELEVANT_INFO_TITLES.content.essentialFacts];
  public loading = false;

  constructor(
    public font: FontService,
    private cmsService: CmsService,
    private languageProvider: LanguageProvider,
  ) { }

  public ngOnInit() {
    this.loadData();
    this.languageSubscription();
  }

  private loadData() {
    this.loading = true;
    this.cmsService.loadEssentialFactsValues().toPromise()
      .then((response) => {
        this.pageContent.content  = response.downloadContent;
        this.pageContent.header = response.header;
      })
      .finally(() => this.loading = false);
  }

  private languageSubscription() {
    this.languageProvider.language.subscribe(() => this.loadData());
  }
}
