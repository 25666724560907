import { Mockup } from '@interfaces/mockup.interface';
import { Simulators } from '@interfaces/simulators.interface';


export const GET_SIMULATORS_CONTENT_MOCK: Mockup = {
    success: [{
        response: {
            data: {
               mostrar_imagen_card: true,
               cards: [
                  {
                     simuladores_cards_id: {
                        id: 1,
                        alt_imagen: 'Simulador Pension',
                        title_image: 'Simulador Pension',
                        titulo: '¿Sábes cuánto podrías recibir de pensión?',
                        descripcion: 'Ingresa tus datos y descubre todo sobre tu futuro.',
                        texto_boton: 'Simula aquí',
                        url: 'https://www.spensiones.cl/apps/simuladorPensiones/',
                        imagen: {
                           filename_disk: 'f2d29493-e10d-4d4e-a5f7-125fdf6f23df.webp'
                        }
                     }
                  },
                  {
                     simuladores_cards_id: {
                        id: 2,
                        alt_imagen: 'Simuladores Fondos',
                        title_image: 'Simuladores Fondos',
                        titulo: '¿Qué tipo de fondo es el más adecuado para mi?',
                        descripcion: 'Ingresa y descubre el fondo que se ajusta a tus características.',
                        texto_boton: 'Simula aquí',
                        url: '/afiliado/asesoria/simuladores/fondo-adecuado',
                        imagen: {
                           filename_disk: '88375885-e5b4-49af-8c87-f6fffcedd859.webp'
                        }
                     }
                  },
                  {
                     simuladores_cards_id: {
                        id: 3,
                        alt_imagen: 'Simulador Planes',
                        title_image: 'Simulador Planes',
                        titulo: 'Hacedor de Planes: tu plan de ahorro personalizado',
                        descripcion: 'Simula cuánto y cómo debes ahorrar en tu Cuenta 2 para lograr tus metas.',
                        texto_boton: 'Simula aquí',
                        url: 'https://simuladordeahorro.planvital.cl/main/start',
                        imagen: {
                           filename_disk: 'd00937b8-b3df-4dde-a07b-cfce64de7e4b.webp'
                        }
                     }
                  }
               ]
            }
         }
    }],
    failures: []
};

export const SIMULATORS_CONTENT: Simulators = {
    header: {
        image: {
            url: 'Test',
            alt: 'Test'
        },
        firstLine: 'Test'
    },
    moreInfoCards: [
        {
            title: 'Test',
            description: 'Test',
            requestText: 'Test',
            requestUrl: 'Test',
            image: {
                url: 'Test',
                alt: 'Test'
            }
        }
    ],
    showImageCards: true
};
