import { Mockup } from '@interfaces/mockup.interface';


export const PAPERWORK_CENTER_AFFILIATE_RESPONSE: Mockup = {
    success: [{
        response: {
            data: [
                {
                    id: 1,
                    sort: null,
                    titulo: 'Mandato de pago de pensión a terceros',
                    descripcion: '<p>Permite registrar a alguien de confianza como mandatario o renovar el mandato de pago actual para delegar el cobro de pensi&oacute;n.</p>'
                },
                {
                    id: 2,
                    sort: null,
                    titulo: 'Solicitud de devolución de pago en exceso',
                    descripcion: '<p>Completa este formulario en l&iacute;nea desde tu cuenta en el sitio privado, a trav&eacute;s del siguiente enlace.</p>'
                },
                {
                    id: 3,
                    sort: null,
                    titulo: 'Pensión por Sobrevivencia',
                    descripcion: '<p>Es un beneficio que otorga la AFP a los beneficiarios o herederos directos tras el fallecimiento del afiliado (a).</p>'
                },
                {
                    id: 4,
                    sort: null,
                    titulo: 'Solicitud Herencia: Posesión Efectiva',
                    descripcion: '<p>Completa este formulario en l&iacute;nea, a trav&eacute;s del siguiente enlace.</p>'
                },
                {
                    id: 5,
                    sort: null,
                    titulo: 'Retiro técnico extranjero',
                    descripcion: '<p>Los trabajadores extranjeros con t&iacute;tulo t&eacute;cnico previo al inicio de su relaci&oacute;n laboral en Chile, seg&uacute;n la Ley N&ordm; 18.156, pueden retirar sus fondos.</p>\n<p>Antes de iniciar el tr&aacute;mite, aseg&uacute;rese de cumplir con las condiciones requeridas. Complete este formulario en l&iacute;nea y adjunte los siguientes documentos:</p>'
                },
                {
                    id: 6,
                    sort: null,
                    titulo: 'Retiro Cuenta de Ahorro de Indemnización',
                    descripcion: '<p>Ingresa a tu cuenta desde la Sucursal en L&iacute;nea, completa el formulario y adjunta&nbsp;<strong>al menos dos</strong>&nbsp;de los siguientes documentos:</p>\n<ul>\n<li>Finiquito</li>\n<li>Acta Inspeccion del Trabajo</li>\n<li>Acta de Conciliacion o Advenimiento</li>\n<li>Sentencia Judicial Ejecutada</li>\n</ul>'
                },
                {
                    id: 7,
                    sort: null,
                    titulo: 'Aumento de la cotización obligatoria al 11%',
                    descripcion: '<p>Descarga, completa y env&iacute;a este formulario al correo&nbsp;<strong>infovital@planvital.cl</strong>&nbsp;con los siguientes documentos:</p>\n<ul>\n<li>Foto C&eacute;dula de Identidad</li>\n</ul>\n<p>Importante: s&oacute;lo para&nbsp;<strong>afiliados que realizaron el tercer retiro.</strong></p>'
                },
                {
                    id: 8,
                    sort: null,
                    titulo: 'Revocación del Aumento de la cotización obligatoria al 11%',
                    descripcion: '<p>Descarga, completa y env&iacute;a este formulario al correo&nbsp;<strong>infovital@planvital.cl</strong>&nbsp;con los siguientes documentos:</p>\n<ul>\n<li>Foto C&eacute;dula de Identidad</li>\n</ul>'
                },
                {
                    id: 9,
                    sort: null,
                    titulo: 'Ley de Pensiones de Alimentos',
                    descripcion: '<p>Si deseas informaci&oacute;n sobre la Ley de Pensiones de Alimentos (Ley 21.484) y/o tienes consultas sobre &oacute;rdenes de pago, haz clic en el bot&oacute;n.</p>'
                },
                {
                    id: 10,
                    sort: null,
                    titulo: 'Cobro de Honorarios de Asesor Previsional',
                    descripcion: '<p>Completa este formulario en l&iacute;nea, a trav&eacute;s del siguiente enlace.</p>'
                },
                {
                    id: 11,
                    sort: null,
                    titulo: 'Descuento empleador para afiliado voluntario',
                    descripcion: '<p>Completa este formulario para generar un abono a la cuenta individual de tu c&oacute;nyuge o conviviente civil.</p>'
                }
            ]
        }
    }],
    failures: []
};
