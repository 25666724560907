import { Mockup } from '@interfaces/mockup.interface';

export const GET_SEARCH_ENGINE_MOCK: Mockup = {
    success: [{
        response: {
            data: [
                {
                    titulo: 'Calendario y Fechas Pago',
                    link: 'pensionado/pago-pension/calendario',
                    descripcion: 'Información sobre fechas de pago e inscripción de cuenta para pagos'
                },
                {
                    titulo: 'Tipos de Pensión',
                    link: 'pensionado/pago-pension/tipos-pension',
                    descripcion: 'Información sobre Pensión de Vejez, Invalidez y Sobrevivencia'
                }
            ]
        }
    }],
    failures: []
};
