<div class="disclaimer-container general-container">
  <div class="detail-container">
    <div *ngIf="!loading" class="title tertiary" [ngClass]="font?.size" [innerHTML]="commissionsDisclaimer?.title | translate"></div>
    <div *ngIf="loading" class="title tertiary" [ngClass]="font?.size">
      <ion-skeleton-text animated class="skeleton-line title large" *ngIf="loading"></ion-skeleton-text>
    </div>
    <div *ngIf="!loading" class="description dynamic-text tertiary" [ngClass]="font?.size" [innerHTML]="commissionsDisclaimer?.description | translate"></div>
    <div *ngIf="loading" class="description dynamic-text tertiary" [ngClass]="font?.size">
      <ion-skeleton-text animated class="skeleton-line large" *ngIf="loading"></ion-skeleton-text>
      <ion-skeleton-text animated class="skeleton-line large" *ngIf="loading"></ion-skeleton-text>
      <ion-skeleton-text animated class="skeleton-line large" *ngIf="loading"></ion-skeleton-text>
      <ion-skeleton-text animated class="skeleton-line large" *ngIf="loading"></ion-skeleton-text>
    </div>
  </div>
</div>
